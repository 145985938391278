import { Route, Routes } from 'react-router-dom'
import { TableSalesPreorders } from './table'
import { AddSalesPreorders } from './add'
import { ViewSalesPreorders } from './view'

export const BrowserSalesPreorders = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableSalesPreorders />} />
      <Route path={"/add"} element={<AddSalesPreorders />} />
      <Route path={"/view"} element={<ViewSalesPreorders />} />
    </Routes>
  )
}