import React, { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next'
import { TransportationDocumentLocation } from '../../../../interfaces/Sales/Catalogs/TransportationDocuments/transportationDocument';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { CustomerLocation } from '../../../../interfaces/Sales/Catalogs/Customers/customers';
import { CompanyLocation } from '../../../../interfaces/Security/companies';
import GenericPromises from '../../../../api/GenericPromises';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { Menuitem } from '../../../../interfaces/Security/menu';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { myStylePickers } from '../../../../theme/buttons';

type LocationProps = {
  setCheckDate?: React.Dispatch<React.SetStateAction<boolean>>;
  isEditIndex: boolean;
  title: string;
  isSource?: boolean;
  defaultCompanyLocation?: CompanyLocation;
  defaultCustomerLocation?: CustomerLocation;
  transportationDocumentLocations: TransportationDocumentLocation[];
  setTransportationDocumentLocations: React.Dispatch<React.SetStateAction<TransportationDocumentLocation[]>>
  comboCompanyLocations?: CompanyLocation[];
  comboCustomerLocations?: CustomerLocation[];
  transportation_document_id: number;
  rfc: string;
  sourceDate?: Date,
  setSourceDate: React.Dispatch<React.SetStateAction<Date | undefined>>,
  row_number: number,
  setNewLocation: React.Dispatch<React.SetStateAction<boolean>>,
  resourceScreenLocations: Menuitem | undefined,
  ChangeTotalKm: () => void,
  setIsOriginExists: React.Dispatch<React.SetStateAction<boolean>>,
}

export const Locations = ({
  setCheckDate,
  isEditIndex,
  title,
  isSource,
  defaultCompanyLocation,
  defaultCustomerLocation,
  transportationDocumentLocations,
  setTransportationDocumentLocations,
  comboCompanyLocations,
  comboCustomerLocations,
  transportation_document_id,
  rfc,
  sourceDate,
  setSourceDate,
  row_number,
  setNewLocation,
  resourceScreenLocations,
  ChangeTotalKm,
  setIsOriginExists,
}: LocationProps) => {
  const [t] = useTranslation("global");
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const { GetError } = FormsErrors();
  const { GenericPostResource, GenericPutResource, GenericDeleteResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isAlreadyPromise, setIsAlreadyPromise] = useState(false);
  const [defaultLocalCustomerLocation, setDefaultLocalCustomerLocation] = useState<CustomerLocation>();
  const [defaultLocalCompanyLocation, setDefaultLocalCompanyLocation] = useState<CompanyLocation>();
  const isEdit = (resourceScreenLocations?.update) ? isEditIndex : true;
  const isDelete = (resourceScreenLocations?.delete) ? isEditIndex : true;

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    reset,
    trigger,
  } = useForm<TransportationDocumentLocation>({
    defaultValues: {
      distance: undefined
    }
  });

  const onSubmit: SubmitHandler<TransportationDocumentLocation> = (data) => onUpdateLocation(data);

  const onUpdateLocation = (data: TransportationDocumentLocation) => {
    if (isSource) {
      let myCompanyLocation = (data.company_location_id !== 0) ? comboCompanyLocations?.find((location: CompanyLocation) => location.company_location_id == data.company_location_id) : undefined;
      let myData: TransportationDocumentLocation = {
        transportation_document_location_id: data.transportation_document_location_id ?? null,
        transportation_document_id: transportation_document_id,
        location_type: "Origen",
        rfc: rfc,
        date_hour_departure_arrival: data.date_hour_departure_arrival,
        distance: data.distance ?? 0,
        country_id: myCompanyLocation?.country_id ?? (data.country_id ?? 0),
        country_name: myCompanyLocation?.country_name ?? data.country_name,
        country_code: myCompanyLocation?.country_code ?? (data.country_code ?? null),
        state_id: myCompanyLocation?.state_id ?? (data.state_id ?? 0),
        state_name: myCompanyLocation?.state_name ?? data.state_name,
        state_abbr: myCompanyLocation?.state_abbr ?? (data.state_abbr ?? null),
        city_id: myCompanyLocation?.city_id ?? (data.city_id ?? 0),
        city_name: myCompanyLocation?.city_name ?? data.city_name,
        postal_code: myCompanyLocation?.postal_code ?? (data.postal_code ?? null),
        street: myCompanyLocation?.street ?? data.street,
        row_number: row_number,
      }
      onPostLocation(myData);
    }
    else {
      let myCustomerLocation = comboCustomerLocations?.find((location) => location.customer_location_id === data.customer_location_id);
      let myData: TransportationDocumentLocation = {
        transportation_document_location_id: data.transportation_document_location_id ?? null,
        transportation_document_id: transportation_document_id,
        location_type: "Destino",
        rfc: myCustomerLocation?.customer_rfc ?? (data.rfc ?? null),
        date_hour_departure_arrival: data.date_hour_departure_arrival,
        distance: data.distance ?? 0,
        country_id: myCustomerLocation?.country_id ?? (data.country_id ?? 0),
        country_name: myCustomerLocation?.country_name ?? data.country_name,
        country_code: myCustomerLocation?.country_code ?? (data.country_code ?? null),
        state_id: myCustomerLocation?.state_id ?? (data.state_id ?? 0),
        state_name: myCustomerLocation?.state_name ?? data.state_name,
        state_abbr: myCustomerLocation?.state_abbr ?? (data.state_abbr ?? null),
        city_id: myCustomerLocation?.city_id ?? (data.city_id ?? 0),
        city_name: myCustomerLocation?.city_name ?? data.city_name,
        postal_code: myCustomerLocation?.postal_code ?? (data.postal_code ?? null),
        street: myCustomerLocation?.street ?? data.street,
        row_number: row_number,
      }
      onPostLocation(myData);
    }
  }

  const onPostLocation = (myData: any) => {
    if (!myData.transportation_document_location_id) {
      delete myData.transportation_document_location_id;
      GenericPostResource(`/transportationdocumentlocations`, myData)
        .then((response) => {
          reset({ ...response.data });
          if (isSource) { setIsOriginExists((prev) => true); }
          setTransportationDocumentLocations((prev) => [...prev, response.data]);
          ChangeTotalKm();
          showSnackBar(t("generic.snackbar.add"), "success");
          setIsAlreadyPromise(false);
        })
        .catch(async (error: any) => {
          // reset({ date_hour_departure_arrival: undefined }, { keepValues: true });
          // setValue("date_hour_departure_arrival", undefined);
          setIsAlreadyPromise(false);
          if (error.response.data.errorMessage) {
            await showSnackBar(t(error.response.data.errorMessage), "error");
            if (setCheckDate) setCheckDate(() => true);
          }
          else {
            showSnackBar(error.message, "error");
          }
        });
    }
    else {
      let myId = myData.transportation_document_location_id;
      delete myData.transportation_document_location_id;
      GenericPutResource(`/transportationdocumentlocations/${myId}`, myData)
        .then((response) => {
          reset({ ...response.data });
          ChangeTotalKm();
          showSnackBar(t("generic.snackbar.update"), "success");
          setIsAlreadyPromise(false);
        })
        .catch(async (error) => {
          setIsAlreadyPromise(false);
          if (error.response.data.errorMessage) {
            await showSnackBar(t(error.response.data.errorMessage), "error");
            if (setCheckDate) setCheckDate(() => true);
          }
          else {
            showSnackBar(error.message, "error");
          }
        });
    }
  }

  const onChangeLocations = () => {
    trigger()
      .then((isTrueForm) => {
        if (isTrueForm) {
          if (!isAlreadyPromise) {
            setIsAlreadyPromise(true);
            setTimeout(() => {
              onUpdateLocation(getValues());
            }, 1000);
          }
        }
      });
  };

  const onDeleteLocation = async () => {
    let mySourceDate = sourceDate;
    setSourceDate((prev) => undefined);
    setIsOriginExists((prev) => false);
    let myLocation = transportationDocumentLocations.find((item) => item.row_number === row_number);
    await GenericDeleteResource(`/transportationdocumentlocations/${myLocation?.transportation_document_location_id}`)
      .then(() => {
        ChangeTotalKm();
        if (row_number === 2) {
          setNewLocation((prev) => true);
          setTransportationDocumentLocations((prev) => transportationDocumentLocations.filter((e) => e.row_number !== row_number));
          setNewLocation((prev) => false);
          setSourceDate((prev) => mySourceDate);
          setIsOriginExists((prev) => true);
        }
        if (row_number === 1) {
          if (transportationDocumentLocations.length > 2) {
            let myLocation = {
              transportation_document_id: transportationDocumentLocations[2].transportation_document_id,
              location_type: transportationDocumentLocations[2].location_type,
              rfc: transportationDocumentLocations[2].rfc,
              date_hour_departure_arrival: transportationDocumentLocations[2].date_hour_departure_arrival,
              distance: transportationDocumentLocations[2].distance,
              country_id: transportationDocumentLocations[2].country_id,
              country_name: transportationDocumentLocations[2].country_name,
              country_code: transportationDocumentLocations[2].country_code,
              state_id: transportationDocumentLocations[2].state_id,
              state_name: transportationDocumentLocations[2].state_name,
              state_abbr: transportationDocumentLocations[2].state_abbr,
              city_id: transportationDocumentLocations[2].city_id,
              city_name: transportationDocumentLocations[2].city_name,
              postal_code: transportationDocumentLocations[2].postal_code,
              street: transportationDocumentLocations[2].street,
              row_number: 1,
            }
            GenericPutResource(`/transportationdocumentlocations/${transportationDocumentLocations[2].transportation_document_location_id}`, myLocation)
              .then((responseUpdate) => {
                let myNewLocations = [transportationDocumentLocations[0], responseUpdate.data];
                setTransportationDocumentLocations((prev) => myNewLocations);
                setNewLocation((prev) => false);
                setSourceDate((prev) => mySourceDate);
                setIsOriginExists((prev) => true);
              })
              .catch((error) => {
                showSnackBar(error.message, "error");
              });
          }
          else if (transportationDocumentLocations.length === 2) {
            setTransportationDocumentLocations((prev) => transportationDocumentLocations.filter((e) => e.row_number !== row_number));
            setNewLocation((prev) => false);
            setSourceDate((prev) => mySourceDate);
            setIsOriginExists((prev) => true);
          }
        }
      })
      .catch(() => {
        setSourceDate((prev) => mySourceDate);
        setIsOriginExists((prev) => false);
      });
  }

  useEffect(() => {
    let myLocation = transportationDocumentLocations.find((item) => item.row_number === row_number);
    if (isSource) {
      if (myLocation?.transportation_document_location_id) {
        setIsOriginExists((prev) => true);
        let myDefault = {
          company_location_id: myLocation?.company_location_id ?? 0,
          city_id: myLocation?.city_id ?? (defaultCompanyLocation?.city_id),
          city_name: myLocation?.city_name ?? (defaultCompanyLocation?.city_name),
          country_id: myLocation?.country_id ?? (defaultCompanyLocation?.country_id),
          country_name: myLocation?.country_name ?? (defaultCompanyLocation?.country_name),
          country_code: myLocation?.country_code ?? (defaultCompanyLocation?.country_code),
          state_id: myLocation?.state_id ?? (defaultCompanyLocation?.state_id),
          state_name: myLocation?.state_name ?? (defaultCompanyLocation?.state_name),
          state_abbr: myLocation?.state_abbr ?? (defaultCompanyLocation?.state_abbr),
          street: myLocation?.street ?? (defaultCompanyLocation?.street),
          postal_code: myLocation?.postal_code ?? (defaultCompanyLocation?.postal_code),
          rfc: myLocation?.rfc ?? (defaultCustomerLocation?.customer_rfc),
        }
        setDefaultLocalCompanyLocation((prev) => myDefault);
      }
      else {
        setDefaultLocalCompanyLocation((prev) => defaultCompanyLocation);
      }
      reset({
        transportation_document_location_id: myLocation?.transportation_document_location_id ?? undefined,
        company_location_id: myLocation?.company_location_id ?? 0,
        city_id: myLocation?.city_id ?? (defaultCompanyLocation?.city_id),
        city_name: myLocation?.city_name ?? (defaultCompanyLocation?.city_name),
        country_id: myLocation?.country_id ?? (defaultCompanyLocation?.country_id),
        country_name: myLocation?.country_name ?? (defaultCompanyLocation?.country_name),
        country_code: myLocation?.country_code ?? (defaultCompanyLocation?.country_code),
        state_id: myLocation?.state_id ?? (defaultCompanyLocation?.state_id),
        state_name: myLocation?.state_name ?? (defaultCompanyLocation?.state_name),
        state_abbr: myLocation?.state_abbr ?? (defaultCompanyLocation?.state_abbr),
        street: myLocation?.street ?? (defaultCompanyLocation?.street),
        postal_code: myLocation?.postal_code ?? (defaultCompanyLocation?.postal_code),
        rfc: myLocation?.rfc ?? (defaultCustomerLocation?.customer_rfc),
        date_hour_departure_arrival: myLocation?.date_hour_departure_arrival ? myLocation?.date_hour_departure_arrival : undefined,
        row_number: row_number,
      });
      if (myLocation?.date_hour_departure_arrival !== null) {
        setSourceDate((prev) => myLocation?.date_hour_departure_arrival ?? undefined);
      }
    }
    else {
      if (myLocation?.transportation_document_location_id) {
        let myDefault = {
          customer_location_id: myLocation?.customer_location_id ?? 0,
          city_id: myLocation?.city_id ?? (defaultCustomerLocation?.city_id),
          city_name: myLocation?.city_name ?? (defaultCustomerLocation?.city_name),
          country_id: myLocation?.country_id ?? (defaultCustomerLocation?.country_id),
          country_name: myLocation?.country_name ?? (defaultCustomerLocation?.country_name),
          country_code: myLocation?.country_code ?? (defaultCustomerLocation?.country_code),
          state_id: myLocation?.state_id ?? (defaultCustomerLocation?.state_id),
          state_name: myLocation?.state_name ?? (defaultCustomerLocation?.state_name),
          state_abbr: myLocation?.state_abbr ?? (defaultCustomerLocation?.state_abbr),
          street: myLocation?.street ?? (defaultCustomerLocation?.street),
          postal_code: myLocation?.postal_code ?? (defaultCustomerLocation?.postal_code),
          rfc: myLocation?.rfc ?? (defaultCustomerLocation?.customer_rfc),
        }
        setDefaultLocalCustomerLocation((prev) => myDefault);
      }
      else {
        setDefaultLocalCustomerLocation((prev) => defaultCustomerLocation);
      }
      reset({
        transportation_document_location_id: myLocation?.transportation_document_location_id ?? undefined,
        customer_location_id: myLocation?.transportation_document_location_id ? 0 : (defaultCustomerLocation ? 0 : undefined),
        city_id: myLocation?.city_id ?? (defaultCustomerLocation?.city_id),
        city_name: myLocation?.city_name ?? (defaultCustomerLocation?.city_name),
        country_id: myLocation?.country_id ?? (defaultCustomerLocation?.country_id),
        country_name: myLocation?.country_name ?? (defaultCustomerLocation?.country_name),
        country_code: myLocation?.country_code ?? (defaultCustomerLocation?.country_code),
        state_id: myLocation?.state_id ?? (defaultCustomerLocation?.state_id),
        state_name: myLocation?.state_name ?? (defaultCustomerLocation?.state_name),
        state_abbr: myLocation?.state_abbr ?? (defaultCustomerLocation?.state_abbr),
        street: myLocation?.street ?? (defaultCustomerLocation?.street),
        postal_code: myLocation?.postal_code ?? (defaultCustomerLocation?.postal_code),
        date_hour_departure_arrival: (sourceDate !== undefined) ? myLocation?.date_hour_departure_arrival : (sourceDate ?? undefined),
        distance: myLocation?.distance ?? 0,
        rfc: myLocation?.rfc ?? (defaultCustomerLocation?.customer_rfc),
      });
    }
    return setDataLoaded(true);
  }, []);

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  }

  useEffect(() => { }, [sourceDate]);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <div className='d-flex flex-column'>
          <div className='mb-1'>
            {title}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='d-flex justify-content-between mx-2'>
              <div className='w-25 d-flex'>
                <Controller
                  name="date_hour_departure_arrival"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <DatePicker
                      sx={{ paddingRight: /* (isSource && sourceDate) ? 2 : */ 2 }}
                      open={openDatePicker}
                      onClose={() => setOpenDatePicker(false)}
                      ref={field.ref}
                      value={getValues("date_hour_departure_arrival") ? dayjs(field.value) : undefined}
                      label={isSource ? `${t("transportationdocumentlocations.fields.date_hour_departure")}` : `${t("transportationdocumentlocations.fields.date_hour_arrival")}`}
                      onChange={async (values: any) => {
                        field.onChange(values?.toDate());
                        await onChangeLocations();
                        if (isSource) {
                          setSourceDate((prev) => values?.toDate());
                        }
                      }}
                      slotProps={{
                        popper: {
                          sx: myStylePickers
                        },
                        textField: {
                          variant: "filled",
                          size: "small",
                          fullWidth: true,
                          onClick: () => setOpenDatePicker(true),
                          onBeforeInput: disableKeyboardEntry,
                          error: !!errors.date_hour_departure_arrival,
                          helperText: GetError(errors.date_hour_departure_arrival?.type),
                        }
                      }}
                      minDate={!isSource ? dayjs(sourceDate) : undefined}
                      format="DD/MM/YYYY"
                      disabled={/* isSource ? (sourceDate !== undefined || isEdit) : */ isEdit}
                    />
                  }
                />
                {/*  {isSource && sourceDate &&
                  <IconButton
                    disabled={isEdit || isDelete}
                    sx={{ paddingRight: 2 }}
                    onClick={() => { onCancelSource() }}>
                    <CloseIcon />
                  </IconButton>
                } */}
              </div>
              <div className='w-50'>
                {isSource ?
                  <Controller
                    name="company_location_id"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        ref={field.ref}
                        size="small"
                        sx={{ maxWidth: "100%", minWidth: "80%", paddingRight: 2 }}
                        options={comboCompanyLocations ?? []}
                        defaultValue={defaultLocalCompanyLocation}
                        getOptionLabel={(option) => `${option.street}, ${option.city_name}, ${option.state_name}, ${option.country_name}`}
                        renderOption={(props, option: CompanyLocation) => (
                          <div key={option.company_location_id}>
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                              {option.street}, {option.city_name}, {option.state_name}, {option.country_name}
                            </Box>
                          </div>
                        )}
                        isOptionEqualToValue={(option, value) => option.company_location_id === value.company_location_id}
                        onChange={(_, values) => {
                          field.onChange(values?.company_location_id || null);
                          onChangeLocations();
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${t("transportationdocumentlocations.fields.location")}`}
                            variant="filled"
                            value={field.value}
                            error={!!errors.company_location_id}
                            helperText={GetError(errors.company_location_id?.type)}
                          />
                        )}
                        disabled={isEdit}
                      />
                    )}
                  />
                  :
                  <Controller
                    name="customer_location_id"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        ref={field.ref}
                        size="small"
                        sx={{ maxWidth: "100%", minWidth: "80%", paddingRight: 2 }}
                        options={comboCustomerLocations ?? []}
                        defaultValue={defaultLocalCustomerLocation}
                        getOptionLabel={(option) => `${option.street}, ${option.city_name}, ${option.state_name}, ${option.country_name}`}
                        renderOption={(props, option: CustomerLocation) => (
                          <div key={option.customer_location_id}>
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                              {option.street}, {option.city_name}, {option.state_name}, {option.country_name}
                            </Box>
                          </div>
                        )}
                        isOptionEqualToValue={(option, value) => option.customer_location_id === value.customer_location_id}
                        onChange={(_, values) => {
                          field.onChange(values?.customer_location_id || null);
                          onChangeLocations();
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${t("transportationdocumentlocations.fields.location")}`}
                            variant="filled"
                            value={field.value}
                            error={!!errors.customer_location_id}
                            helperText={GetError(errors.customer_location_id?.type)}
                          />
                        )}
                        disabled={isEdit}
                      />
                    )}
                  />
                }

              </div>
              {!isSource ?
                <div className='w-25'>
                  <Controller
                    name="distance"
                    control={control}
                    rules={{ required: true, min: 1 }}
                    render={({ field }) => (
                      <TextField
                        variant="filled"
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                          "& input[type=number]": { MozAppearance: "textfield", },
                        }}
                        type="number"
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          onChangeLocations();
                        }}
                        label={t("transportationdocumentlocations.fields.distance")}
                        error={!!errors.distance}
                        helperText={GetError(errors.distance?.type)}
                        size="small"
                        style={{ width: "100%" }}
                        disabled={isEdit}
                      />
                    )}
                  />
                </div>
                : <div className='w-25'></div>
              }
            </div>
          </form>
          {!isSource && getValues("transportation_document_location_id") && !isEditIndex &&
            <div className='d-flex mt-2'>
              <Button disabled={isDelete} onClick={() => onDeleteLocation()}>{t("transportationdocuments.locations.deleteLocation")}</Button>
            </div>
          }
        </div>
      }
      <SnackbarComponent />
    </>
  )
}
