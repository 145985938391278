import { Dispatch, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { SalesInvoice, SalesInvoiceDetail } from '../../../interfaces/Sales/Invoices/salesinvoices';
import { Control, UseFormGetValues, UseFormReset, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { Grid } from '@mui/material';
import { InvoiceBody } from './invoiceBody';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import { ButtonLoading, PrimaryButton } from '../../../theme/buttons';
import { Menuitem } from '../../../interfaces/Security/menu';

type UpdateSalesInvoiceProps = {
  control: Control<SalesInvoice>,
  reset: UseFormReset<SalesInvoice>,
  salesInvoicePayload: SalesInvoice | undefined,
  resourceSalesInvoiceDetails: Menuitem | undefined,
  isAccounted: boolean,
  onPut: (data: SalesInvoice) => Promise<void>,
  setIsChangedTotalValue: Dispatch<SetStateAction<boolean>>,
  setValue: UseFormSetValue<SalesInvoice>,
  trigger: UseFormTrigger<SalesInvoice>,
  getValues: UseFormGetValues<SalesInvoice>,
  dataLoaded: boolean
  includesForeignTradeComplement: boolean,
  myLocalInvoiceDocType: number,
  rows: SalesInvoiceDetail[],
  setRows: Dispatch<SetStateAction<SalesInvoiceDetail[]>>,
  loadingPut: boolean
}

export const UpdateSalesInvoices = ({
  reset,
  salesInvoicePayload,
  isAccounted,
  resourceSalesInvoiceDetails,
  onPut,
  setIsChangedTotalValue,
  setValue,
  trigger,
  getValues,
  dataLoaded,
  includesForeignTradeComplement,
  myLocalInvoiceDocType,
  rows,
  setRows,
  loadingPut,
}: UpdateSalesInvoiceProps) => {
  const [t] = useTranslation("global");

  const onSave = () => {
    trigger().then(async (response) => {
      if (response) {
        await onPut(getValues());
      }
    });
  }

  return (
    <>
      {!dataLoaded && <Spinner />}
      {dataLoaded &&
        <>
          <Grid container display={'flex'} flexDirection={'column'}>
            <Grid item sx={{ height: 'auto', marginTop: 1 }}>
              {(resourceSalesInvoiceDetails?.read) &&
                <InvoiceBody
                  rows={rows}
                  setRows={setRows}
                  salesInvoicePayload={salesInvoicePayload}
                  setValue={setValue}
                  getValues={getValues}
                  setIsChangedTotalValue={setIsChangedTotalValue}
                  isAccounted={isAccounted}
                  reset={reset}
                  onPut={onPut}
                  includesForeignTradeComplement={includesForeignTradeComplement}
                  myLocalInvoiceDocType={myLocalInvoiceDocType}
                  loadingPut={loadingPut}
                  resourceSalesInvoiceDetails={resourceSalesInvoiceDetails}
                />
              }
            </Grid>
            <div className='d-flex flex-row-reverse pt-3'>
              {resourceSalesInvoiceDetails?.update &&
                <ButtonLoading isLoading={loadingPut} onClick={onSave} className="d-flex align-self-end w-25" variant="outlined">{t("generic.buttons.save")}</ButtonLoading>
              }
            </div>
          </Grid>
        </>
      }
    </>
  )
}
