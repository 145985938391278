import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import GenericPromises from "../../../../api/GenericPromises";
import { PrimaryButton } from "../../../../theme/buttons";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { CompanyConfiguration } from "../../../../interfaces/Security/companies";
import { Autocomplete, Box, IconButton, Switch, TextField, Typography } from "@mui/material";
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors";
import { Divider } from "../../../../components/Commons/Divider";
import { usePermissions } from "../../../../hooks/usePermissions";
import { Currency } from "../../../../interfaces/Commons/currencies";

export const BrowserCompanyConfigurations = ({ ...props }) => {
    const [t] = useTranslation("global");
    const { GetError } = FormsErrors();
    const { GenericPutResource, GenericGetResource } = GenericPromises();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [CompanyConfiguration, setCompanyConfiguration] = useState<CompanyConfiguration>();
    const { showSnackBar, SnackbarComponent } = useSnackBar();
    const { GetResourceByUrl } = usePermissions();
    const [defaultTimeZone, setDefaultTimeZone] = useState<{ id: string; description: string; }>();
    const [comboCurrencies, setComboCurrencies] = useState<Currency[]>([]);
    const [defaultCurrency, setDefaultCurrency] = useState<Currency>();
    const { companyPayload } = props;
    const [hiddenPassword, setHiddenPassword] = useState(false);
    const comboTimeZones = [
        { id: 'UTC-8', description: "Hora Estándar del Pacífico (PST)" },
        { id: 'UTC-7', description: "Hora Estándar de la Montaña (MST)" },
        { id: 'UTC-6', description: "Hora Estándar Central (CST)" },
        { id: 'UTC-5', description: "Hora Estándar del Este (EST)" },
        { id: 'UTC+0', description: "Hora de Greenwich (GMT)" },
        { id: 'UTC+1', description: "Hora Central Europea (CET)" },
        { id: 'UTC+2', description: "Hora de Europa del Este (EET)" },
        { id: 'UTC+3', description: "Hora de Moscú (MSK)" },
        { id: 'UTC+5:30', description: "Hora Estándar de la India (IST)" },
        { id: 'UTC+8', description: "Hora Estándar de China (CST)" },
        { id: 'UTC+9', description: "Hora Estándar de Japón (JST)" },
    ];

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<CompanyConfiguration>({
        defaultValues: {
            host_mail: undefined,
            port_mail: undefined,
            username_mail: undefined,
            password_mail: undefined,
            time_zone: undefined,
            is_ssl_mail: undefined,
            is_non_fiscal: undefined
        }
    });

    const onSubmit: SubmitHandler<CompanyConfiguration> = (data) => onPut(data);

    const onPut = (data: CompanyConfiguration) => {
        let mydata = {
            host_mail: data.host_mail,
            port_mail: data.port_mail,
            username_mail: data.username_mail,
            password_mail: data.password_mail,
            time_zone: data.time_zone,
            company_currency_id: data.company_currency_id,
            can_create_project_from_sales_order: data.can_create_project_from_sales_order ?? false,
            is_ssl_mail: data.is_ssl_mail,
            is_non_fiscal: data.is_non_fiscal
        }
        GenericPutResource(`/companyconfigurations/${CompanyConfiguration?.company_configuration_id}`, mydata)
            .then((response) => {
                showSnackBar(t("generic.snackbar.update"), "success");
            }).catch((error) => {
                showSnackBar(error.message, "error");
            });
    }

    useEffect(() => {
        GenericGetResource(`/companyconfigurations/bycompanyid/${companyPayload.company_id}`)
            .then(async (response) => {
                setCompanyConfiguration(response.data.data[0]);
                await reset({
                    host_mail: response.data.data[0].host_mail,
                    port_mail: response.data.data[0].port_mail,
                    username_mail: response.data.data[0].username_mail,
                    password_mail: response.data.data[0].password_mail,
                    time_zone: response.data.data[0].time_zone,
                    company_currency_id: response.data.data[0].company_currency_id,
                    can_create_project_from_sales_order: response.data.data[0].can_create_project_from_sales_order,
                    is_ssl_mail: response.data.data[0].is_ssl_mail
                });
                setDefaultTimeZone(comboTimeZones.find((element) => element.id == response.data.data[0].time_zone));

                await GenericGetResource(`/currencies`)
                    .then((responseCurrencies) => {
                        setComboCurrencies(responseCurrencies.data.data);
                        setDefaultCurrency(responseCurrencies.data.data.find((element: any) => element.currency_id === response.data.data[0].company_currency_id));
                        setDataLoaded(true);
                    })
                    .catch((error) => {
                        showSnackBar(error.message, 'error');
                    });
            }).catch((error) => {
                showSnackBar(error.message, "error");
            });
    }, []);

    return (
        <>
            {!dataLoaded && <Spinner />}
            {dataLoaded &&
                <div className='d-flex justify-content-center'>
                    <div className='custom-card-view w-75'>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="w-100">
                                <Typography variant="h6">
                                    {t("companies.configuration.time_zone")}
                                </Typography>
                                <Divider />
                                <div className="w-50 pt-2">
                                    <Controller
                                        name="time_zone"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                ref={field.ref}
                                                size="small"
                                                sx={{ width: "100%", paddingRight: 2 }}
                                                options={comboTimeZones}
                                                defaultValue={defaultTimeZone}
                                                getOptionLabel={(option) => `${option.description} ${option.id}`}
                                                renderOption={(props, option) => (
                                                    <div key={option.id}>
                                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                            {option.description} - {option.id}
                                                        </Box>
                                                    </div>
                                                )}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                onChange={(_, values) => {
                                                    field.onChange(values?.id || null)
                                                }}
                                                disabled={!(props.isEdit)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={`${t("companies.configuration.fields.time_zone")}`}
                                                        variant="filled"
                                                        value={field.value}
                                                        error={!!errors.time_zone}
                                                        helperText={GetError(errors.time_zone?.type)}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <Typography variant="h6" className="pt-3">
                                {t("companies.configuration.email")}
                            </Typography>
                            <Divider />
                            <div className="d-flex w-100 pt-2">
                                <div className='w-50'>
                                    <Controller
                                        name="host_mail"
                                        control={control}
                                        rules={{ maxLength: 1000 }}
                                        render={({ field }) =>
                                            <TextField
                                                variant="filled"
                                                label={`${t("companies.configuration.fields.host_mail")}`}
                                                ref={field.ref}
                                                value={field.value}
                                                onChange={(event) => { field.onChange(event.target.value) }}
                                                error={!!errors.host_mail}
                                                helperText={GetError(errors.host_mail?.type)}
                                                size="small"
                                                style={{ width: "100%" }}
                                                sx={{ paddingRight: 2 }}
                                                disabled={!(props.isEdit)}
                                            />
                                        }
                                    />
                                </div>
                                <div className='w-50'>
                                    <Controller
                                        name="port_mail"
                                        control={control}
                                        rules={{ maxLength: 5 }}
                                        render={({ field }) =>
                                            <TextField
                                                variant="filled"
                                                label={`${t("companies.configuration.fields.port_mail")}`}
                                                ref={field.ref}
                                                value={field.value}
                                                onChange={(event) => { field.onChange(event.target.value) }}
                                                error={!!errors.port_mail}
                                                helperText={GetError(errors.port_mail?.type)}
                                                size="small"
                                                style={{ width: "100%" }}
                                                disabled={!(props.isEdit)}
                                            />
                                        }
                                    />
                                </div>
                            </div>
                            <div className="d-flex w-100 pt-2">
                                <div className='w-50'>
                                    <Controller
                                        name="username_mail"
                                        control={control}
                                        rules={{ maxLength: 100 }}
                                        render={({ field }) =>
                                            <TextField
                                                variant="filled"
                                                label={`${t("companies.configuration.fields.username_mail")}`}
                                                ref={field.ref}
                                                value={field.value}
                                                onChange={(event) => { field.onChange(event.target.value) }}
                                                error={!!errors.username_mail}
                                                helperText={GetError(errors.username_mail?.type)}
                                                size="small"
                                                style={{ width: "100%" }}
                                                sx={{ paddingRight: 2 }}
                                                disabled={!(props.isEdit)}
                                            />
                                        }
                                    />
                                </div>
                                <div className='w-50'>
                                    <Controller
                                        name="password_mail"
                                        control={control}
                                        rules={{ maxLength: 100 }}
                                        render={({ field }) =>
                                            <TextField
                                                InputProps={{
                                                    endAdornment: <IconButton disabled={!(props.isEdit)} children={hiddenPassword ? <VisibilityOffIcon /> : <VisibilityOffIcon />} onClick={() => { setHiddenPassword((prev) => (!hiddenPassword)) }} />
                                                }}
                                                ref={field.ref}
                                                size="small"
                                                type={`${hiddenPassword ? 'text' : 'password'}`}
                                                value={field.value}
                                                variant='filled'
                                                label={t("companies.configuration.fields.password_mail")}
                                                onChange={field.onChange}
                                                error={!!errors.password_mail}
                                                helperText={GetError(errors.password_mail?.type)}
                                                sx={{ width: "100%" }}
                                                disabled={!(props.isEdit)}
                                            />
                                        }
                                    />
                                </div>
                            </div>
                            <div className="d-flex w-100 pt-2">
                                <div className='w-50'>
                                    <Controller
                                        name="is_ssl_mail"
                                        control={control}
                                        render={({ field }) =>
                                            <Box sx={{ display: 'flex', alignItems: 'center', width: '85%', paddingLeft: 1 }}>
                                                <Typography sx={{ mr: 1 }}>{t("companies.configuration.fields.is_ssl_mail")}</Typography>
                                                <Switch disabled={!(props.isEdit)} ref={field.ref} onChange={field.onChange} value={field.value} defaultChecked={CompanyConfiguration?.is_ssl_mail} />
                                            </Box>
                                        }
                                    />
                                </div>
                            </div>
                            <Typography variant="h6" className="pt-3">
                                {t("companies.configuration.finance")}
                            </Typography>
                            <Divider />
                            <div className="d-flex w-100 pt-2">
                                <div className='w-50'>
                                    <Controller
                                        name="company_currency_id"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                ref={field.ref}
                                                size="small"
                                                sx={{ width: "100%", paddingRight: 2 }}
                                                options={comboCurrencies}
                                                defaultValue={defaultCurrency}
                                                getOptionLabel={(option) => `${option.currency_code} ${option.currency_description}`}
                                                renderOption={(props, option) => (
                                                    <div key={option.currency_id}>
                                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                            {option.currency_code} - {option.currency_description}
                                                        </Box>
                                                    </div>
                                                )}
                                                isOptionEqualToValue={(option, value) => option.currency_id === value.currency_id}
                                                onChange={(_, values) => {
                                                    field.onChange(values?.currency_id || null)
                                                }}
                                                disabled={!(props.isEdit)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={`${t("currencies.title-view")}`}
                                                        variant="filled"
                                                        value={field.value}
                                                        error={!!errors.company_currency_id}
                                                        helperText={GetError(errors.company_currency_id?.type)}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <Typography variant="h6" className="pt-3">
                                {t("companies.configuration.projects")}
                            </Typography>
                            <Divider />
                            <div className="d-flex w-100 pt-2">
                                <div className='w-50'>
                                    <Controller
                                        name="can_create_project_from_sales_order"
                                        control={control}
                                        render={({ field }) =>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", paddingRight: 2 }}>
                                                <Typography>{t("companies.configuration.fields.can_create_project_from_sales_order")}</Typography>
                                                <Switch disabled={!(props.isEdit)} ref={field.ref} onChange={field.onChange} value={field.value} defaultChecked={CompanyConfiguration?.can_create_project_from_sales_order} />
                                            </Box>
                                        }
                                    />
                                </div>
                            </div>
                            <Typography variant="h6" className="pt-3">
                                {t("companies.configuration.fiscal")}
                            </Typography>
                            <Divider />
                            <div className="d-flex w-100 pt-2">
                                <div className='w-50'>
                                    <Controller
                                        name="is_non_fiscal"
                                        control={control}
                                        render={({ field }) =>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", paddingRight: 2 }}>
                                                <Typography>{t("companies.configuration.fields.is_non_fiscal")}</Typography>
                                                <Switch disabled={!(props.isEdit)} ref={field.ref} onChange={field.onChange} value={field.value} defaultChecked={CompanyConfiguration?.is_non_fiscal} />
                                            </Box>
                                        }
                                    />
                                </div>
                            </div>

                            {props.isEdit &&
                                <div className='d-flex flex-row-reverse mt-4'>
                                    <PrimaryButton type='submit' variant='outlined' className='m-1'>{t("generic.buttons.update")}</PrimaryButton>
                                </div>
                            }
                        </form>

                    </div>
                </div>
            }
            <SnackbarComponent />
        </>
    )
}