import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { Menuitem } from '../../../../interfaces/Security/menu';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import GenericPromises from '../../../../api/GenericPromises';
import { Header } from '../../../../components/Header';
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography, useTheme } from '@mui/material';
import DialogReceiptDetail from './tabsReceiptDetail';
import { TabRelatedDocuments } from './tabRelatedReceiptsDetails';
import { useFormatNumber } from '../../../../hooks/useFormatNumber';
import { useDates } from '../../../../hooks/useDates';
import { usePermissions } from '../../../../hooks/usePermissions';
import { Divider } from '../../../../components/Commons/Divider';
import { CancelledStamping } from './components/stamping';
import { CancelledReceipt, CancelledReceiptDetail } from '../../../../interfaces/Sales/CancelledReceipts/receipts';

type ViewCancelledReceiptDetailsProps = {
  cancelledReceiptDetailPayload: CancelledReceiptDetail;
  setOpenDialogView: Dispatch<SetStateAction<boolean>>;
  permissionsCancelledReceiptsDetails?: Menuitem;
  permissionsCancelledReceipts?: Menuitem;
  setClickedButtonGoBack: Dispatch<SetStateAction<boolean>>;
}

export const ViewReceiptDetails = ({
  cancelledReceiptDetailPayload,
  setOpenDialogView,
  permissionsCancelledReceiptsDetails,
  permissionsCancelledReceipts,
  setClickedButtonGoBack,
}: ViewCancelledReceiptDetailsProps) => {
  const [t] = useTranslation("global");
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GenericGetResource, GenericPatchResource, GenericGetReport, GenericPutResource } = GenericPromises();
  const { TimeConverter } = useDates();
  const { setFormatNumber } = useFormatNumber();
  const { GetResourceByUrl } = usePermissions();
  const theme = useTheme();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [anchorEl1, setAnchorEl1] = useState<HTMLButtonElement | null>(null);
  const [anchorEl2, setAnchorEl2] = useState<HTMLButtonElement | null>(null);
  const [cancelledReceiptLocal, setCancelledReceiptLocal] = useState<CancelledReceipt>();
  const [cancelledReceiptsDetailsData, setCancelledReceiptsDetailsData] = useState<CancelledReceiptDetail[]>([]);
  const [isNotSelectedUuid, setIsNotSelectedUuid] = useState(false);

  useEffect(() => {
    let myPromises = [
      GenericGetResource(`/cancelledreceipts/${cancelledReceiptDetailPayload.cancelled_receipt_id ?? 0}`),
      GenericGetResource(`/cancelledreceiptsdetails/bycancelledreceiptid/${cancelledReceiptDetailPayload.cancelled_receipt_id}`),
    ]
    Promise.all(myPromises)
      .then(async (responses) => {
        responses[0] && setCancelledReceiptLocal(responses[0].data);
        responses[1] && setCancelledReceiptsDetailsData(responses[1].data.data);

        if (responses[1].data.data.some(
          (element: CancelledReceiptDetail) => element.uuid_related === null ||
            element.uuid_related === undefined
        )) {
          setIsNotSelectedUuid(true);
        }
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      })
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <>
          <Header
            title={t("cancelledreceiptsdetails.title-view")}
            child={
              <div className='d-flex flex-row-reverse'>
                <div className='pt-1'>
                </div>
                <div className='pt-1'>
                </div>
                <Button variant='outlined' onClick={() => { setOpenDialogView(false); setClickedButtonGoBack((prev) => !prev) }}>{t("generic.buttons.goback")}</Button>
              </div>
            }
          />

          {permissionsCancelledReceipts?.read &&
            <>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column w-50">
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Box>
                      <Typography style={{ fontWeight: "bold" }}>{t("customers.title-view")}:</Typography>
                      <Typography>{cancelledReceiptLocal?.customer_business_name}</Typography>
                      <Typography>{cancelledReceiptLocal?.customer_rfc}</Typography>
                      <Typography>{cancelledReceiptLocal?.customer_tax_regime_code} - {cancelledReceiptLocal?.customer_tax_regime_description}</Typography>
                    </Box>
                    <Box display={"flex"} flexDirection={"row"} sx={{ paddingRight: 1 }}>
                      <>
                        <Box>
                          {isNotSelectedUuid &&
                            <FormControlLabel
                              checked={true}
                              control={<Checkbox />}
                              disabled={true}
                              label={t("receipts.info.sales-invoice-nouuid")}
                            />
                          }
                        </Box>
                      </>
                    </Box>
                  </Box>
                  <Box sx={{ paddingRight: 1, marginBottom: 0.5 }}>
                    <Divider />
                  </Box>
                  <div className="d-flex w-100">
                    <TextField
                      variant="filled"
                      label={`${t("receipts.fields.receipt_name")}`}
                      value={cancelledReceiptLocal?.receipt_name}
                      size="small"
                      sx={{ width: "100%", paddingRight: 1 }}
                      InputProps={{ readOnly: true, disableUnderline: true }}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column-reverse w-50">
                  <div className="d-flex">
                    <TextField
                      sx={{ width: "60%", paddingRight: 1 }}
                      variant="filled"
                      label={`${t("receipts.fields.total_amount")}`}
                      value={setFormatNumber({ value: cancelledReceiptLocal?.total_amount ?? 0 })}
                      size="small"
                      InputProps={{ readOnly: true, disableUnderline: true }}
                    />
                    <TextField
                      sx={{ width: "40%" }}
                      label={`${t("currencies.title-view")}`}
                      variant="filled"
                      value={`${cancelledReceiptLocal?.currency_code ?? ''} - ${cancelledReceiptLocal?.currency_description ?? ''}`}
                      size="small"
                      InputProps={{ readOnly: true, disableUnderline: true }}
                    />
                  </div>
                </div>
              </div>

              <Divider />

              <div className="d-flex">
                <TextField
                  label={`${t("paymentmethods.title-view")}`}
                  variant="filled"
                  value={`${cancelledReceiptLocal?.payment_method_code ?? ''} - ${cancelledReceiptLocal?.payment_method_name ?? ''}`}
                  sx={{ width: "50%", paddingRight: 1 }}
                  InputProps={{ readOnly: true, disableUnderline: true }}
                  size='small'
                />
                <TextField
                  label={`${t("receipts.fields.receipt_date")}`}
                  variant="filled"
                  value={TimeConverter(cancelledReceiptLocal?.receipt_date?.toString())}
                  sx={{ width: "50%" }}
                  InputProps={{ readOnly: true, disableUnderline: true }}
                  size='small'
                />
              </div>
              <Divider />
              <Box className="d-flex w-100">
                <Box sx={{ width: "50%" }} className="d-flex custom-card-view">
                  <Box className="w-100 d-flex flex-column">
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                        <Typography fontWeight={"bold"} marginRight={1}>
                          {t("bankaccounts.fields.account_alias")}:
                        </Typography>
                        {cancelledReceiptLocal?.customer_account_alias}
                      </Typography>
                      <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                        <Typography fontWeight={"bold"} marginRight={1}>
                          {t("bankaccounts.fields.bank_references")}:
                        </Typography>
                        {cancelledReceiptLocal?.customer_bank_references}
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} width={"50%"} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.bank_name")}:
                          </Typography>
                          {cancelledReceiptLocal?.customer_bank_name}
                        </Typography>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("currencies.title-view")}:
                          </Typography>
                          {cancelledReceiptLocal?.customer_bank_currency_description} - {cancelledReceiptLocal?.customer_bank_currency_code}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} width={"50%"} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.card_number")}:
                          </Typography>
                          {cancelledReceiptLocal?.customer_bank_card_number}
                        </Typography>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.account_number")}:
                          </Typography>
                          {cancelledReceiptLocal?.customer_account_number}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} width={"55%"} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.interbank_key")}:
                          </Typography>
                          {cancelledReceiptLocal?.customer_bank_interbank_key}
                        </Typography>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.swift_code")}:
                          </Typography>
                          {cancelledReceiptLocal?.customer_bank_swift_code}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ width: "1%" }} display={"flex"} justifyContent={"center"}>
                  <Divider />
                </Box>
                <Box sx={{ width: "50%" }} className="d-flex custom-card-view">
                  <Box className="w-100">
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                        <Typography fontWeight={"bold"} marginRight={1}>
                          {t("bankaccounts.fields.account_alias")}:
                        </Typography>
                        {cancelledReceiptLocal?.company_account_alias}
                      </Typography>
                      <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                        <Typography fontWeight={"bold"} marginRight={1}>
                          {t("bankaccounts.fields.bank_references")}:
                        </Typography>
                        {cancelledReceiptLocal?.company_bank_references}
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} width={"50%"} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.bank_name")}:
                          </Typography>
                          {cancelledReceiptLocal?.company_bank_name}
                        </Typography>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("currencies.title-view")}:
                          </Typography>
                          {cancelledReceiptLocal?.company_bank_currency_description} - {cancelledReceiptLocal?.company_bank_currency_code}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} width={"50%"} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.card_number")}:
                          </Typography>
                          {cancelledReceiptLocal?.company_bank_card_number}
                        </Typography>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.account_number")}:
                          </Typography>
                          {cancelledReceiptLocal?.company_account_number}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} width={"55%"} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.interbank_key")}:
                          </Typography>
                          {cancelledReceiptLocal?.company_bank_interbank_key}
                        </Typography>
                        <Typography fontFamily={"sans-serif"} sx={{ display: "flex" }} paddingRight={1}>
                          <Typography fontWeight={"bold"} marginRight={1}>
                            {t("bankaccounts.fields.swift_code")}:
                          </Typography>
                          {cancelledReceiptLocal?.company_bank_swift_code}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Divider />
            </>
          }

          <DialogReceiptDetail
            Receipt={
              permissionsCancelledReceiptsDetails?.read &&
              <TabRelatedDocuments
                cancelledReceiptsDetailsData={cancelledReceiptsDetailsData}
              />
            }
            Stamping={
              permissionsCancelledReceipts?.read &&
              <CancelledStamping
                hasFiscalSignature={isNotSelectedUuid}
                cancelledReceiptLocal={cancelledReceiptLocal}
              />
            }
          />
        </>
      }
      <SnackbarComponent />
    </>
  )
}
