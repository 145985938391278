import { Route, Routes } from 'react-router-dom'
import { TableCancelledReceiptsDetails } from './table'

export const BrowserCancelledReceiptDetails = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableCancelledReceiptsDetails />} />
    </Routes>
  )
}
