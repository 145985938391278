import React, { useCallback, useEffect, useState } from 'react'
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { CheckCircle, CircleOutlined } from '@mui/icons-material';
import GenericPromises from '../../../../api/GenericPromises';
import { TaskState } from '../../../../interfaces/Projects/Catalogs/taskStates';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { useTranslation } from 'react-i18next';
import { ProjectStage } from '../../../../interfaces/Projects/Catalogs/projectStages';
import { Project } from '../../../../interfaces/Projects/projects';
import { DialogEntity } from '../../../../components/Dialogs/DialogEntity';
import { Header } from '../../../../components/Header';
import { ConfirmDialog } from './dialogs/confirmDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { useParamsFilter } from '../../../../hooks/useParamsFilter';
import { ProjectTaskDashboard } from '../../../../interfaces/Projects/projectTaskDashboard';
import { format } from 'date-fns';
import { useFormulaEvaluator } from '../../../../hooks/useFormulaEvaluator';
import { Parameter } from '../../../../interfaces/Commons/parameters';
import { useProjectTaskColor } from '../../../../hooks/useProjectTaskColor';
import { useCalculateDueDate } from '../../../../hooks/useCalculateDueDate';


interface TaskCardProps {
  task: ProjectTaskDashboard,
  color: number[] | undefined;
  isEdit: boolean;
  handleUpdateTask: (updateTask: ProjectTaskDashboard) => void;
  taskStatesLocal: TaskState[];
  projectStagesLocal: ProjectStage[];
  projectsLocal: Project[];
  parametersLocal: Parameter[];
  pendingState: TaskState | undefined,
  doneState: TaskState | undefined
}

export const TaskCard = ({
  task,
  color,
  isEdit,
  handleUpdateTask,
  taskStatesLocal,
  projectStagesLocal,
  projectsLocal,
  parametersLocal,
  pendingState,
  doneState
}: TaskCardProps) => {
  const [t] = useTranslation("global");
  const { GenericPostResource, GenericGetResource } = GenericPromises();
  const [dataloaded, setDataLoaded] = useState(false);
  const [onChecked, setOnChecked] = useState(false);
  const [loadingPut, setLoadingPut] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [projectStage, setProjectStage] = useState<ProjectStage>();
  const [project, setProject] = useState<Project>();
  const [dialogConfirmUncheckTask, setDialogConfirmUncheckTask] = useState(false);
  const { ConvertParamsToBase64 } = useParamsFilter();
  const [projectTaskDueDate, setProjectTaskDueDate] = useState<Date | string>()
  const [taskColor, setTaskColor] = useState<string>();
  const { getProjectTaskColor } = useProjectTaskColor();
  const { calculateDueDate } = useCalculateDueDate();


  const handleTaskCompletion = () => {
    if (taskStatesLocal) {
      const currentState = taskStatesLocal.find(
        (state) => state.task_state_id === task.current_task_state_id
      )
      const nextState = taskStatesLocal.find((state) =>
        state.task_state_name === (onChecked ? "PENDING" : "DONE")
      );
      if (nextState?.task_state_id && currentState?.task_state_id) {
        setLoadingPut(true);
        const myData = {
          previous_state_id: currentState.task_state_id,
          new_state_id: nextState.task_state_id,
          project_task_id: task.project_task_id,
          project_task_state_name: task.project_task_name
        }
        GenericPostResource(`/projecttaskstates`, myData)
          .then((response) => {
            showSnackBar(t("projecttaskdashboard.messages.state_updated"), "success");
            setOnChecked(!onChecked);
            GenericGetResource(`/projecttaskdashboard/${response.data.project_task_id}`)
              .then((response2) => {
                task=response2.data;
                handleUpdateTask(response2.data)
                setTaskColor(response2.data.project_task_color)
                if(task.field_type_id==4){
                  updateTaskDueDateAndColor()
                }
              })
              .catch((error) => {
                showSnackBar(error.message, "error");
              })
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
            setOnChecked(!onChecked);
          })
          .finally(() => setLoadingPut(false));
      }
    }
  };

  const openProjectDetails = useCallback(() => {
    if (project) {
      const params = ConvertParamsToBase64(`project_id=${project.project_id}`);
      window.open(`/projectsdetails/view?${params}`, '_blank');
    }
  }, [project, ConvertParamsToBase64]);


  const updateTaskDueDateAndColor = async () => {
    if(!task.project_task_due_date){
      setProjectTaskDueDate('')
      return;
    }
    if (project) {
      const originalDueDate = task.project_task_due_date;
      try {
        const calculatedDueDate = await calculateDueDate( task, parametersLocal,undefined, project );
        if (calculatedDueDate) {
          task.project_task_due_date = calculatedDueDate;
          setProjectTaskDueDate(format(new Date(calculatedDueDate), 'dd/MM/yyyy HH:mm:ss'));
        }
        if (pendingState && doneState && task.field_type_id === 4) {
          const color = await getProjectTaskColor(task, pendingState, doneState);
          if (color) {
            task.project_task_color = color;
            setTaskColor(task.project_task_color)
          }
        }
      } finally {
        task.project_task_due_date = originalDueDate;
      }
    }
  };

  useEffect(() => {
    if (taskStatesLocal) {
      const currentState = taskStatesLocal.find(
        (state: TaskState) =>
          state.task_state_id === task.current_task_state_id
      );
      if (currentState && currentState.task_state_name === "DONE") {
        setOnChecked((prevChecked) => true);
      } else {
        setOnChecked((prevChecked) => false);
      }
      setTaskColor(task.project_task_color);  

    }
  }, []);

  useEffect(() => {
    if (projectStagesLocal) {
      const matchedStage = projectStagesLocal.find(
        (stage) => stage.project_stage_id === task.project_stage_id
      );
      if (matchedStage) {
        setProjectStage(matchedStage);
      }
    }
  }, [projectStagesLocal]);

  useEffect(() => {
    if (projectStage) {
      const matchedProject = projectsLocal.find(
        (project) => project.project_id === projectStage.project_id
      );
      if (matchedProject) {
        setProject(matchedProject);
      }
    }
  }, [projectStage]);

  useEffect(() => {
    updateTaskDueDateAndColor();
    setDataLoaded(true);
  }, [project, task]);

  return (
    <>
      {!dataloaded &&
        <div className="w-100 my-2">
          <Spinner isBox={false} />
        </div>
      }
      {dataloaded && (
        <Grid item key={task.project_task_id} xs={12} sx={{ marginBlock: 2 }}>
          <Box
            sx={{
              border: `2px solid ${taskColor}`,
              borderRadius: `8px`,
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
              justifyContent: "space-between",
              backgroundColor: color
                ? `rgb(${color[0] - 20}, ${color[1] - 20}, ${color[2]}, 0.2)`
                : "rgba(0, 0, 0, 0.1)",
              "&:hover": {
                backgroundColor: color
                  ? `rgb(${color[0] - 20}, ${color[1] - 20}, ${color[2]}, 0.09)`
                  : "rgba(0, 0, 0, 0.1)",
              },
              display: "flex",
              alignItems: "center",
              padding: "8px",
            }}

          >
            <FormControlLabel
              sx={{ marginLeft: 0.1 }}
              control={
                <Checkbox
                  checked={onChecked}
                  onChange={(e) => {
                    if (onChecked) {
                      setDialogConfirmUncheckTask(true);
                      return
                    }
                    handleTaskCompletion();
                  }}
                  icon={<CircleOutlined />}
                  checkedIcon={<CheckCircle />}
                  sx={{
                    color: "primary.main",
                    "&:hover": {
                      color: "primary.main",
                    },
                    "&.Mui-checked": {
                      color: "primary.main",
                    },
                  }}
                />
              }
              label={
                <>
                </>
              }

              disabled={loadingPut}
            />
            <Box>
              <span
                style={{
                  textDecoration: onChecked ? "line-through" : "none",
                  color: onChecked ? "gray" : "inherit",
                }}
              >
                {task.project_task_name} {project && project.project_name} {projectStage && projectStage.project_stage_name}
              </span>
              {project && (
                <Typography
                  variant="caption"
                  color="textSecondary"
                  display="block"
                >
                  <>
                    {t("projecttaskdashboard.fields.due-date")}: {
                      projectTaskDueDate ? projectTaskDueDate.toString() : ' '
                    }. {' '}
                    {t("projecttaskdashboard.fields.customer")}: {project.business_name}. {' '}
                    {t("projecttaskdashboard.fields.project-manager")}: {project.user_name}. {' '}
                    {t("projecttaskdashboard.fields.notes")}: {project.project_comments}. {' '}
                  </>
                </Typography>
              )}
            </Box>
            <FontAwesomeIcon icon={faCircleInfo} style={{ marginLeft: 'auto', cursor: 'pointer', width: 'auto', height: 25, marginRight: 1 }} onClick={openProjectDetails} />
          </Box>
        </Grid>
      )}
      <DialogEntity
        open={dialogConfirmUncheckTask}
        title={<Header title={t("projecttasks.title-view")} />}
        maxWidth={"sm"}
        fullWidth
        content={
          <ConfirmDialog
            projectTaskName={task.project_task_name}
            isEdit={isEdit}
            loadingPut={loadingPut}
            setDialogConfirmUncheckTask={setDialogConfirmUncheckTask}
            handleTaskCompletion={handleTaskCompletion}
          />
        }
      />
      <SnackbarComponent />
    </>
  )
}
