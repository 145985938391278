import { useContext, useEffect, useState } from "react"
import { Autocomplete, Avatar, Box, Button, IconButton, Switch, TextField, Typography } from "@mui/material";
import { User } from "../../../../interfaces/Security/users";
import { Group } from '../../../../interfaces/Security/groups';
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors";
import { emailRegex } from "../../../../constants/Regex";
import GenericPromises from "../../../../api/GenericPromises";
import { PrimaryButton } from "../../../../theme/buttons";
import { useTranslation } from "react-i18next";
import { File } from "../../../../interfaces/Commons/files";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ImageDataComponent } from "../../../../components/Images/ImageData";
import { useFiles } from "../../../../hooks/useFiles";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { AuthContext } from "../../../../context/AuthContext";
import ComboLanguage from "../../../../components/Commons/ComboLanguage";

export const UpdateUser = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPutResource, GenericGetResource, GenericGet, GenericPost, GenericPut } = GenericPromises();
  const { DecodeFileToBase64, GetContentURLImage } = useFiles();
  const { GetError } = FormsErrors();
  const { user } = useContext(AuthContext);
  const [loadingPost, setLoadingPost] = useState(false);
  const [comboGroups, setComboGroups] = useState<Group[]>([]);
  const [defaultGroup, setDefaultGroup] = useState<Group>();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [imageFile, setImageFile] = useState<File>({});
  const [defaultStatus, setDefaultStatus] = useState(false);
  const [isSamesUser, setIsSamesUser] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [hiddenPassword, setHiddenPassword] = useState(false);
  const { userPayload } = props;
  const isEdit = !props.isEdit;
  const { showSnackBar, SnackbarComponent } = useSnackBar();

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<User>({
    defaultValues: {
      user_id: 0,
      user_phone: '',
      user_email: '',
      user_name: '',
      language: undefined,
      photo_id: undefined,
      group_id: undefined,
      password: undefined,
      user_status: false,
      MS_user_id: undefined
    }
  }
  );
  const onSubmit: SubmitHandler<User> = (data) => onPut(data);

  const onPut = (data: User, photo_id?: number) => {
    if (data.group_id === null) return;
    let myData = {
      user_name: data.user_name,
      user_email: data.user_email,
      password: data.password,
      language: data.language,
      photo_id: photo_id ?? null,
      user_phone: data.user_phone,
      user_status: data.user_status,
      group_id: data.group_id,
      MS_user_id: data.MS_user_id ?? null
    }
    setLoadingPost(true);
    GenericPutResource(`/users/${data.user_id}`, myData)
      .then(() => {
        showSnackBar(t("users.snackbar.update"), "success");
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
        setLoadingPost(false);
      });
  }

  const onPostImage = (image: any) => {
    if (getValues("photo_id")) {
      let myFile = {
        entity: "Users",
        recordId: userPayload.user_id,
        fileName: image.file_name,
        typeOfFile: image.type_of_file,
        schema_name: 'dbo',
        Content: GetContentURLImage(image.dataURL),
      }
      GenericPut(`/files/${getValues("photo_id")}`, myFile)
        .then(async (response) => {
          await onPut(getValues(), response.file_id)
          setImageFile((prev) => image);
          await setValue("photo_id", response.file_id);
          showSnackBar(t("generic.snackbar.update_photo"), "success");
          setImageLoaded(true);
          setLoadingPost(false);
        })
        .catch((error) => {
          setLoadingPost(false);
          showSnackBar(error.message, "error");
        });
    }
    else {
      let myFile = {
        entity: "Users",
        recordId: userPayload.user_id,
        fileName: image.file_name,
        typeOfFile: image.type_of_file,
        schema_name: 'dbo',
        Content: GetContentURLImage(image.dataURL),
      }
      GenericPost(`/files`, myFile)
        .then(async (response) => {
          await onPut(getValues(), response.file_id)
          setImageFile((prev) => image);
          showSnackBar(t("generic.snackbar.update_photo"), "success");
          await setValue("photo_id", response.file_id);
          setImageLoaded(true);
          setLoadingPost(false);
        })
        .catch((error) => {
          setLoadingPost(false);
          showSnackBar(error.message, "error");
        });
    }
  }

  useEffect(() => {
    let myPromises = [
      GenericGet(`/files/recordId/${userPayload.user_id}/entity/Users/schema/dbo`),
      GenericGetResource("/groups"),
      GenericGetResource(`/users/${userPayload.user_id}`),
    ];
    Promise.all(myPromises)
      .then(async (responses) => {
        setComboGroups(responses[1].data.data);

        reset({
          ...responses[2].data
        });

        setDefaultStatus(userPayload.user_status);

        setIsSamesUser(user?.user_id === userPayload.user_id);

        setDefaultGroup(responses[1].data.data.find((group: Group) => group.group_id === userPayload.group_id));
        // setDefaultLanguage(.find((language: { id: string, name: string }) => language.id === userPayload.language));

        if (userPayload?.photo_id !== undefined || userPayload?.photo_id !== null) {
          if (responses[0].data.data && responses[0].data.data.length > 0) {
            for (let index = 0; index < responses[0].data.data.length; index++) {
              const element = responses[0].data.data[index];
              await GenericGet(`/files/${element.object_id}`)
                .then(async (response1) => {
                  element.dataURL = await `data:${element.type_of_file};base64,${response1.data.content}`;
                });
            }
            let photo: File;
            photo = responses[0].data.data.find((photo: File) => photo.file_id === userPayload?.photo_id)
            await setImageFile(photo);
            setImageLoaded(true);
            setDataLoaded(true);
          }
        }
        else {
          setImageLoaded(false);
        }
        setDataLoaded(true);
      }).catch((error) => {
        showSnackBar(error.message, "error");
      });

  }, [])

  useEffect(() => { }, [hiddenPassword]);

  return (
    <>
      {!dataLoaded && <Spinner />}
      {dataLoaded &&
        <div className="d-flex justify-content-center">
          <div className="custom-card-view w-100">
            <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

              <div className="custom-card-row d-flex justify-content-between">

                <div className="w-100">

                  <div className="d-flex">
                    <div className="w-50">
                      <Controller
                        name="user_name"
                        control={control}
                        rules={{ required: true, minLength: 6 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.user_name}
                            helperText={GetError(errors.user_name?.type)}
                            label={t("users.fields.user_name")}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>

                    <div className="w-50">
                      <Controller
                        name="user_phone"
                        control={control}
                        rules={{ maxLength: 10, minLength: 10 }}
                        render={({ field }) =>
                          <TextField
                            ref={field.ref}
                            variant="filled"
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                              "& input[type=number]": { MozAppearance: "textfield", }
                            }}
                            type="number"
                            value={field.value}
                            onChange={field.onChange}
                            label={t("users.fields.user_phone")}
                            error={!!errors.user_phone}
                            helperText={GetError(errors.user_phone?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                  </div>

                  <div className="d-flex mt-3">
                    <div className="w-50">
                      <Controller
                        name="user_email"
                        control={control}
                        rules={{ required: true, pattern: emailRegex }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            ref={field.ref}
                            value={field.value}
                            onChange={field.onChange}
                            label={t("users.fields.user_email")}
                            error={!!errors.user_email}
                            helperText={GetError(errors.user_email?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>

                    <div className='w-50'>
                      <Controller
                        name="password"
                        control={control}
                        rules={{ maxLength: 50, minLength: 8, required: true }}
                        render={({ field }) =>
                          <TextField
                            ref={field.ref}
                            InputProps={{
                              endAdornment: <IconButton children={hiddenPassword ? <VisibilityOffIcon /> : <VisibilityIcon />} onClick={() => { setHiddenPassword((prev) => (!hiddenPassword)) }} />
                            }}
                            variant="filled"
                            type={`${hiddenPassword ? 'text' : 'password'}`}
                            value={field.value}
                            onChange={field.onChange}
                            label={t("users.fields.password")}
                            error={!!errors.password}
                            helperText={GetError(errors.password?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            autoComplete='off'
                            disabled={isEdit}
                          />
                        }
                      />
                    </div>
                  </div>

                  <div className="d-flex mt-3">
                    <div className="w-50">
                      <Controller
                        name="group_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            sx={{ width: "100%" }}
                            options={comboGroups}
                            defaultValue={defaultGroup}
                            getOptionLabel={(option) => option.group_name}
                            renderOption={(props, option: Group) => (
                              <div key={option.group_id}>
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.group_name}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) => option.group_id === value.group_id}
                            onChange={(_, values) => {
                              field.onChange(values?.group_id || null)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="filled"
                                label={t("users.fields.group_name")}
                                value={field.value}
                                error={!!errors.group_id || field.value === null}
                                helperText={(errors.group_id?.type || field.value === null) ? t("generic.forms-errors.required") : null}
                                sx={{ paddingRight: 2 }}
                              />
                            )}
                            disabled={isEdit}
                          />
                        )}
                      />
                    </div>
                    <div className='w-50'>
                      <Controller
                        name="language"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <ComboLanguage
                            ref={field.ref}
                            value={field.value}
                            defaultValue={userPayload?.language}
                            onChange={(event) => { field.onChange(event?.id || null) }}
                            errors={errors}
                            sx={{ width: "100%" }}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="d-flex mt-3">
                    <div className="w-50">
                      <Controller
                        name="user_status"
                        control={control}
                        render={({ field }) =>
                          <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
                            <Typography sx={{ ml: 2 }}>{t("users.fields.user_status")}</Typography>
                            <Switch disabled={isSamesUser} ref={field.ref} onChange={field.onChange} value={field.value} defaultChecked={defaultStatus} />
                          </Box>
                        }
                      />
                    </div>
                  </div>

                </div>

                <Button component="label" sx={{ marginBlock: -2 }}>
                  <Avatar className="mx-4" sx={{ bgcolor: 'transparent', border: '1px solid grey', height: 190, width: 190 }} variant='rounded' alt={getValues("user_name")}>
                    {imageLoaded && imageFile && (imageFile.dataURL !== "" || imageFile.dataURL !== undefined || imageFile.dataURL !== null) ?
                      <ImageDataComponent url={imageFile && imageFile.dataURL} /> :
                      <img alt='profile' width={250} height={250} src={require("../../../../assets/img/profile.jpeg")} />
                    }
                  </Avatar>
                  <input type="file" accept="image/*" hidden
                    onChange={async (e) => {
                      await DecodeFileToBase64(e)
                        .then(async (response) => {
                          onPostImage(response);
                        });
                    }}
                  />
                </Button>

              </div>

              <div className='d-flex flex-row-reverse mt-4'>
                <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
              </div>

            </form>

          </div>

        </div>
      }
      <SnackbarComponent />
    </>
  )
}
