import React from 'react'
import { TableMerchandise } from './tableMerchandise';
import { MerchandiseDetail, TransportationDocument } from '../../../../interfaces/Sales/Catalogs/TransportationDocuments/transportationDocument';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UseFormGetValues } from 'react-hook-form';
import { Menuitem } from '../../../../interfaces/Security/menu';

type MerchandiseProps = {
  isEditIndex: boolean;
  getValues: UseFormGetValues<TransportationDocument>;
  transportationDocumentPayload?: TransportationDocument,
  ChangeTotalMerchandise: () => void,
  resourceScreenMerchandise: Menuitem | undefined,
  hasTransportation: boolean,
  setRows: React.Dispatch<React.SetStateAction<MerchandiseDetail[]>>,
  rows: MerchandiseDetail[],
}

export const Merchandise = ({
  isEditIndex,
  transportationDocumentPayload,
  getValues,
  ChangeTotalMerchandise,
  hasTransportation,
  setRows,
  rows,
  resourceScreenMerchandise,
}: MerchandiseProps) => {
  const [t] = useTranslation("global");

  return (
    <div className='d-flex flex-column'>
      <div>
        {/* Tabla de mercancías
        -- ClaveProductoServicio, descripcion, cantidad, clave unidad, peso en kg */}
        <TableMerchandise
          rows={rows}
          setRows={setRows}
          transportationDocumentPayload={transportationDocumentPayload}
          isEdit={isEditIndex}
          ChangeTotalMerchandise={ChangeTotalMerchandise}
          resourceScreenMerchandise={resourceScreenMerchandise}
          hasTransportation={hasTransportation}
        />
      </div>
      <div className='d-flex flex-row-reverse mt-2'>
        {/* Totales
        -- Unidad de peso, Numero total de mercancias, peso bruto total */}
        <div className='d-flex flex-column'>
          <Typography>{t("transportationdocuments.fields.weight_unit")}: {getValues("weight_unit")}</Typography>
          <Typography>{t("transportationdocuments.fields.total_merchandise")}: {getValues("total_merchandise")}</Typography>
          <Typography>{t("transportationdocuments.fields.total_gross_weight")}: {getValues("gross_total_weight")}</Typography>
        </div>
      </div>
    </div>
  );
}
