import { useCallback, useContext, useEffect, useState } from 'react'
import { Spinner } from '../../../../components/Commons/Spinner/Spinner'
import GenericPromises from '../../../../api/GenericPromises'
import { useLocation } from 'react-router-dom'
import { PrimaryButton } from '../../../../theme/buttons'
import { AuthContext } from '../../../../context/AuthContext'
import { Header } from '../../../../components/Header';
import { Menuitem } from '../../../../interfaces/Security/menu'
import DataTable from '../../../../components/Tables/GridTableMaterialUI/DataTable'
import { GridColDef, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from '@mui/x-data-grid'
import { UserCompanies } from '../../../../interfaces/Security/userCompanies'
import { useTranslation } from 'react-i18next'
import { AddUserCompanies } from './add'
import { UpdateUserCompanies } from './update'
import { usePermissions } from '../../../../hooks/usePermissions'
import { DialogEntity } from '../../../../components/Dialogs/DialogEntity'
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar'
import { useDates } from '../../../../hooks/useDates'
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Switch } from '@mui/material'

export const UserCompaniesTable = ({ ...props }) => {
    const [t] = useTranslation("global");
    const location = useLocation();
    const { TimeConverter } = useDates();
    const { GenericGetResource, GenericPutResource } = GenericPromises();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [unauthorized, setUnauthorized] = useState(true);
    const [openDialogAdd, setOpenDialogAdd] = useState(false);
    const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
    const [userCompaniesPayload, setUserCompaniesPayload] = useState({});
    const { user } = useContext(AuthContext);
    const [usersCompaniesData, setUsersCompaniesData] = useState<GridRowsProp>([]);
    const [messageSnack, setMessageSnack] = useState("");
    const [resourceScreen, setResourceScreen] = useState<Menuitem>();
    const [myPreferences, setMyPreferences] = useState({});
    const { GetResourceByUrl } = usePermissions();
    const [isLoadingDefault, setIsLoadingDefault] = useState(false);
    const { showSnackBar, SnackbarComponent } = useSnackBar();

    const [columnsUserCompanies, setColumnsUserCompanies] = useState<GridColDef<UserCompanies>[]>([
        { field: 'business_name', headerName: t("userCompanies.fields.business_name"), headerClassName: 'header-grid-table', flex: 1 },
        { field: 'schema_name', headerName: t("userCompanies.fields.schema_name"), headerClassName: 'header-grid-table', flex: 1 },
        {
            field: 'is_default_schema',
            type: 'actions',
            headerName: t("userCompanies.fields.is_default_schema"),
            headerClassName: 'header-grid-table',
            flex: 1,
            renderCell: (params) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Switch
                            checked={params.value}
                            onClick={async () => {
                                await onChangeDefault(params.row);
                            }}
                            disabled={isLoadingDefault}
                        />
                    </div>
                );
            }
        },
        {
            field: 'customer_default_id',
            type: 'actions',
            headerName: t("userCompanies.fields.customer_default_id"),
            headerClassName: 'header-grid-table',
            flex: 1,
            renderCell: (params) => {
                if (params.row.customer_default_id) {
                    return (
                        <>
                            {(params.row.customer_identifier) ? params.row.customer_identifier : "XXX"} - {(params.row.customer_comercial_name) ? params.row.customer_comercial_name : params.row.customer_business_name}
                        </>
                    );
                }
                return (<></>)
            }
        },
        {
            field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
            valueGetter(params) {
                return TimeConverter(params.value);
            },
        },
        { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
        {
            field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
            valueGetter(params) {
                return TimeConverter(params.value);
            },
        },
    ]);

    const methodUsed = useCallback(() => {
        switch (location.state.method) {
            case "add":
                {
                    showSnackBar(t("generic.snackbar.add"), "success");
                    break;
                }
            case "delete":
                {
                    showSnackBar(t("generic.snackbar.delete"), "success");
                    break;
                }
        }

    }, [location.state, t]);



    const onChangeDefault = async (data: any) => {
        if (data.is_default_schema === true) {
            setIsLoadingDefault(false);
            return;
        }
        setIsLoadingDefault(true);
        try {
            const response = await GenericGetResource(`/usercompanies/byuserid/${data.user_id}`);
            let myDataUserCompanies = response.data.data;
            const updatedCompanies = await Promise.all(myDataUserCompanies.map(async (element: UserCompanies) => {
                const isDefault = element.schema_id === data.schema_id;
                const myDataPut = { is_default_schema: isDefault };
                try {
                    const response2 = await GenericPutResource(`/usercompanies/${element.user_company_id}/Schema/${element.schema_id}`, myDataPut);
                    handleUpdateRow(response2.data);
                    return {
                        ...element,
                        is_default_schema: isDefault
                    };
                } catch (error: any) {
                    showSnackBar(error.message, "error");
                    setMessageSnack(error.message);
                    setUnauthorized(false);
                    return element;
                }
            }));
            setUsersCompaniesData(updatedCompanies);
        } catch (error: any) {
            showSnackBar(error.message, "error");
            setMessageSnack(error.message);
            setUnauthorized(false);
        }
        finally {
            setIsLoadingDefault(false);
        }
    };


    const loadPreferences = async () => {
        let myPreferences = await localStorage.getItem("grid-usercompanies");
        if (myPreferences != null) {
            setMyPreferences(JSON.parse(myPreferences));
        }
    }

    const loadColumnsOrder = async () => {
        let myOrderColumns = await localStorage.getItem("grid-usercompanies-columns");
        if (myOrderColumns != null) {
            let myJson = JSON.parse(myOrderColumns);
            for (let index = 0; index < myJson.length; index++) {
                const element = myJson[index];
                if (element['type'] === 'date') {
                    element.headerName = t(`generic.${element.field}`);
                    element.valueGetter = function (params: GridValueGetterParams<UserCompanies, any, GridTreeNodeWithRender>) {
                        return TimeConverter(params.value);
                    };
                }
                else {
                    if (element.field !== 'last_update_user') {
                        element.headerName = t(`userCompanies.fields.${element.field}`);
                    }
                }
            }
            setColumnsUserCompanies(myJson);
        }
    }

    const handleAddRow = (newRow: UserCompanies) => {
        let myRow = {
            ...newRow,
            creation_date: new Date(),
            last_update_user: user?.user_email,
            last_update_date: new Date()
        }
        setUsersCompaniesData((prevState) => [...usersCompaniesData, myRow]);
    };

    const handleDeleteRow = (deleteRow: number) => {
        if (usersCompaniesData.length === 0) {
            return;
        }
        setUsersCompaniesData((prevRows) => {
            const rowToDeleteIndex = usersCompaniesData.findIndex(e => e.user_company_id === deleteRow);
            return [
                ...usersCompaniesData.slice(0, rowToDeleteIndex),
                ...usersCompaniesData.slice(rowToDeleteIndex + 1),
            ];
        });
    };

    const handleUpdateRow = (updateRow: UserCompanies) => {
        if (usersCompaniesData.length === 0) {
            return;
        }
        setUsersCompaniesData((prevRows) => {
            const rowToUpdateIndex = usersCompaniesData.findIndex(e => e.schema_id === updateRow.schema_id);

            return prevRows.map((row, index) =>
                index === rowToUpdateIndex ? updateRow : row,
            );
        });
    };

    useEffect(() => {
        if (location.state !== null && location.state.method) methodUsed();
        GenericGetResource(`/usercompanies/byuserid/${props.user.user_id}`)
            .then(
                async (response) => {
                    setUsersCompaniesData((prev) => response.data.data);

                    GetResourceByUrl(`/usercompanies`)
                        .then((response1) => {
                            setResourceScreen((prev) => response1);
                            loadColumnsOrder();
                            loadPreferences();
                            setDataLoaded(true);
                        })
                        .catch((error) => {
                            showSnackBar(error.message, "error");
                            setMessageSnack(error.message);
                            setUnauthorized(false);
                        });
                }
            ).catch((error) => {
                showSnackBar(error.message, "error");
                setMessageSnack(error.message);
                setUnauthorized(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../../assets/img/error.jpeg")} /> <h2>{messageSnack}</h2> </div>}
            {unauthorized && !dataLoaded && <Spinner />}
            {dataLoaded && resourceScreen?.read &&
                <>
                    <div className='screen-container'>
                        {resourceScreen?.create === true &&
                            <div className='d-flex flex-row-reverse my-1'>
                                <PrimaryButton variant='outlined' className="my-1" onClick={() => { setOpenDialogAdd(true) }}>{t("generic.buttons.add")}</PrimaryButton>
                            </div>
                        }
                        <div className="d-flex justify-content-center my-1">
                            <DataTable
                                columns={columnsUserCompanies}
                                setColumns={setColumnsUserCompanies}
                                data={usersCompaniesData}
                                entityId={"schema_id"}
                                entity={`UserCompanies`}
                                preferences={myPreferences}
                                namePreferences={"grid-usercompanies"}
                                nameOrderColumns={"grid-usercompanies-columns"}
                                isChildren={true}
                                setOpenDialog={setOpenDialogUpdate}
                                setDataRow={setUserCompaniesPayload}
                            />
                        </div>
                    </div>
                    <DialogEntity
                        content={
                            <UpdateUserCompanies
                                userCompaniesPayload={userCompaniesPayload}
                                setOpenDialog={setOpenDialogUpdate}
                                permissions={resourceScreen}
                                user={props.user}
                                DeleteRow={handleDeleteRow}
                                UpdateRow={handleUpdateRow} />
                        }
                        open={openDialogUpdate}
                        disableEscapeKeyDown={true}
                        maxWidth={'xl'}
                        title={<Header title={t("userCompanies.title-view")} size='sm' />}
                    />
                    <DialogEntity
                        content={<AddUserCompanies setOpenDialog={setOpenDialogAdd} usersCompaniesData={usersCompaniesData} user={props.user} AddNewRow={handleAddRow} />}
                        open={openDialogAdd}
                        maxWidth={'xl'}
                        title={<Header title={t("userCompanies.title-view")} size='sm' />}
                    />
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingDefault}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <SnackbarComponent />
                </>
            }
        </>

    )
}