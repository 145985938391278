import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDates } from '../../../../hooks/useDates';
import GenericPromises from '../../../../api/GenericPromises';
import { useFormatNumber } from '../../../../hooks/useFormatNumber';
import { useParamsFilter } from '../../../../hooks/useParamsFilter';
import { PaymentDetail } from '../../../../interfaces/Purchases/Payments/payments';
import { GridColDef, GridRenderCellParams, GridRowSelectionModel, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from '@mui/x-data-grid';
import { Menuitem } from '../../../../interfaces/Security/menu';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { usePermissions } from '../../../../hooks/usePermissions';
import { Autocomplete, Box, Button, CircularProgress, TextField, Typography, Backdrop } from '@mui/material';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { Header } from '../../../../components/Header';
import DataTable from '../../../../components/Tables/GridTableMaterialUI/DataTable';
import { ButtonLoading, PrimaryButton } from '../../../../theme/buttons';
import GradingIcon from '@mui/icons-material/Grading';
import { DialogEntity } from '../../../../components/Dialogs/DialogEntity';
import { Currency } from '../../../../interfaces/Commons/currencies';
import { CompanyBankAccount } from '../../../../interfaces/Security/companies';
import { useCurrencies } from '../../../../hooks/useCurrencies';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface MyTableCurrencyBanks {
  currency_id: number,
  currency_code: string,
  currency_description: string,
  balanceBankAccount: number,
  balanceAutorized: number,
  balanceFree: number,
  exchangeRate: number,
  active: boolean
}

export const TableAuthorizationPayments = () => {
  const [t] = useTranslation("global");
  const { TimeConverter } = useDates();
  const { GenericGetResource, GenericGetResourceGeneric, GenericPostResource } = GenericPromises();
  const { setFormatNumberFromTable } = useFormatNumber();
  const { ConvertParamsToBase64 } = useParamsFilter();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetExchangeRateFromCurrencyCode, GetOperatorToGetDivisa } = useCurrencies();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(true);
  const [dialogPayment, setDialogPayment] = useState(false);
  const [clickedButtonGoBack, setClickedButtonGoBack] = useState(false);
  const [isLoadingPost, setIsLoadingPost] = useState(false);
  const [isLoadingDataTable, setIsLoadingDataTable] = useState(false);
  const [selectedRows, setSelectedRows] = useState<GridRowsProp>([]);
  const [paymentsDetailsData, setPaymentsDetailsData] = useState<GridRowsProp>([]);
  const [totalPaymentsDetailsData, setTotalPaymentsDetailsData] = useState<GridRowsProp>([]);
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [resourceAuthorizePayments, setResourceAuthorizePayments] = useState<Menuitem>();
  const [messageSnack, setMessageSnack] = useState("");
  const [myPreferences, setMyPreferences] = useState({});
  const [spinnerBalance, setSpinnerBalance] = useState(false);
  const [companyBalance, setCompanyBalance] = useState({ balance: 0, currency_id: 0, currency_code: "" })
  const [dataCurrencyTable, setDataCurrencyTable] = useState<MyTableCurrencyBanks[]>([]);
  const [backDropLoading, setBackDropLoading] = useState(false);
  const [dialogAgreePayNegative, setDialogAgreePayNegative] = useState(false);
  const [columnsCurrency, setColumnsCurrency] = useState<GridColDef<MyTableCurrencyBanks>[]>([
    {
      field: 'currency_code', headerName: t("currencies.title-view"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params) {
        return <>
          <Typography>
            {params.value}
          </Typography>
        </>
      }
    },
    {
      field: 'balanceBankAccount', headerName: t("payments.balance-table.balance"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params) {
        let value = params.value;
        if (typeof value === 'string')
          value = numberWithNotFormat(value);
        return <>
          <Typography sx={{ color: typeof value === 'number' && value < 0 ? 'red' : 'inherit', }}>
            {setFormatNumberFromTable(params.value ?? 0)}
          </Typography>
        </>
      },
    },
    {
      field: 'balanceAutorized', headerName: t("payments.balance-table.authorized"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params) {
        let value = params.value;
        if (typeof value === 'string')
          value = numberWithNotFormat(value);
        return <>
          <Typography sx={{ color: typeof value === 'number' && value < 0 ? 'red' : 'inherit', }}>
            {setFormatNumberFromTable(params.value ?? 0)}
          </Typography>
        </>
      },
    },
    {
      field: 'balanceFree', headerName: t("payments.balance-table.total_for_authorized"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params) {
        let value = params.value;
        if (typeof value === 'string')
          value = numberWithNotFormat(value);
        return <>
          <Typography sx={{ color: typeof value === 'number' && value < 0 ? 'red' : 'inherit', }}>
            {setFormatNumberFromTable(params.value ?? 0)}
          </Typography>
        </>
      },
    },
  ])
  const { GetResourceByUrl } = usePermissions();
  const [defaultFilter, setDefaultFilter] = useState<{
    columnfilter: string;
    value: string;
  }[]>([]);
  const [comboEnum, setComboEnum] = useState([
    { enum_id: 1, option_name: t("authorizationpayments.comboEnum.all"), route: `/authorizationpayments` },
    { enum_id: 2, option_name: t("authorizationpayments.comboEnum.onlypaid"), route: `/authorizationpayments?filter=${btoa(`payment_id!=null`)}` },
    { enum_id: 3, option_name: t("authorizationpayments.comboEnum.onlynopaid"), route: `/authorizationpayments?filter=${btoa(`payment_id=null`)}` },
    { enum_id: 4, option_name: t("authorizationpayments.comboEnum.authorized-n-nopaid"), route: `/authorizationpayments?filter=${btoa(`payment_id=null&is_authorized=True`)}` },
    { enum_id: 5, option_name: t("authorizationpayments.comboEnum.noauthorized-n-nopaid"), route: `/authorizationpayments?filter=${btoa(`payment_id=null&is_authorized=False`)}` },
  ]);
  const [myEnumValue, setMyEnumValue] = useState(comboEnum[4]);
  const [defaultEnum, setDefaultEnum] = useState(comboEnum[4]);
  const [columns, setColumns] = useState<GridColDef<PaymentDetail>[]>([
    {
      field: 'purchase_invoice_name', headerName: t("purchaseinvoices.fields.purchase_invoice_name"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params) {
        return <Button onClick={() => {
          let stringConverted = ConvertParamsToBase64(`purchase_invoice_id=${params.row.purchase_invoice_id}&supplier_id=${params.row.supplier_id}`);
          window.open(`/purchaseinvoices/view?${stringConverted}`, '_blank');
        }}>
          {params.row.purchase_invoice_name}
        </Button>
      }
    },
    {
      field: 'project_name', headerName: t("projects.fields.project_name"), headerClassName: 'header-grid-table', flex: 1,
      renderCell(params) {
        return <Button onClick={() => {
          let stringConverted = ConvertParamsToBase64(`project_id=${params.row.project_id}`);
          window.open(`/projects/view?${stringConverted}`, '_blank');
        }}>
          {params.row.project_name}
        </Button>
      }
    },
    {
      field: 'eta', headerName: t("projects.fields.eta"), headerClassName: 'header-grid-table', flex: 1, type: 'date',
      valueGetter(params) {
        return (params.value) ? TimeConverter(params.value) : undefined;
      },
    },
    {
      field: 'is_authorized',
      headerName: t("paymentsdetails.fields.is_authorized"),
      headerClassName: 'header-grid-table', flex: 1, align: "center", headerAlign: "center",
      renderCell(params) {
        return <>
          {(params.row?.is_authorized) ? <CheckCircleIcon /> : (params.row?.is_authorized === false) ? <CancelIcon /> : <></>}
        </>
      }
    },
    { field: 'supplier_business_name', headerName: t("purchaseinvoices.fields.supplier_business_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'customer_business_name', headerName: t("projects.fields.customer"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'shipping_company', headerName: t("projects.fields.shipping_company"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'notes', headerName: t("purchaseinvoices.fields.notes"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'status_name', headerName: t("paymentsdetails.fields.status_name"), headerClassName: 'header-grid-table', flex: 1 },
    { field: 'related_currency_code', headerName: t("currencies.title-view"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'amount_outstanding', headerName: t("paymentsdetails.fields.amount_outstanding"), headerClassName: 'header-grid-table', flex: 1, type: 'number', align: 'left', headerAlign: 'left',
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    {
      field: 'total_company_currency_amount', headerName: t("companies.configuration.fields.total_company_currency_amount"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return setFormatNumberFromTable(params.value ?? 0);
      },
    },
    {
      field: 'payment_deadline', headerName: t("paymentsdetails.fields.payment_deadline"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return (params.value) ? TimeConverter(params.value) : undefined;
      },
    },
    { field: 'user_mail_send', headerName: t("payments.fields.user_mail_send"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'date_mail_send', headerName: t("payments.fields.date_mail_send"), headerClassName: 'header-grid-table', flex: 1, type: 'date',
      valueGetter(params) {
        return (params.value) ? TimeConverter(params.value) : undefined;
      },
    },
    {
      field: 'payment_date', headerName: t("paymentsdetails.fields.payment_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return (params.value) ? TimeConverter(params.value) : undefined;
      },
    },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-authorizationpayments");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const numberWithNotFormat = (value: string): number => {
    return parseFloat(value.replace(/[$,]/g, ''));
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-authorizationpayments-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] === 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = (params: GridValueGetterParams<PaymentDetail, any, GridTreeNodeWithRender>) => {
            return TimeConverter(params.value);
          };
          if (element.field === 'payment_deadline') {
            element.headerName = t(`paymentsdetails.fields.${element.field}`);
          }
          else if (element.field === 'payment_date') {
            element.headerName = t(`paymentsdetails.fields.${element.field}`);
          }
          else if (element.field === 'date_mail_send') {
            element.headerName = t(`payments.fields.date_mail_send`);
          }
          else if (element.field === 'eta') {
            element.headerName = t("projects.fields.eta");
          }
        }
        else {
          element.headerName = t(`paymentsdetails.fields.${element.field}`);
          if (element.field === 'amount_outstanding') {
            element.valueGetter = function (params: GridValueGetterParams<PaymentDetail, any, GridTreeNodeWithRender>) {
              return setFormatNumberFromTable(params.value ?? 0);
            }
          }
          else if (element.field === 'total_company_currency_amount') {
            element.headerName = t("companies.configuration.fields.total_company_currency_amount");
            element.valueGetter = function (params: GridValueGetterParams<PaymentDetail, any, GridTreeNodeWithRender>) {
              return setFormatNumberFromTable(params.value ?? 0);
            }
          }
          else if (element.field === 'customer_business_name') { element.headerName = t(`projects.fields.customer`); }
          else if (element.field === 'shipping_company') { element.headerName = t(`projects.fields.shipping_company`); }
          else if (element.field === 'last_update_user') { element.headerName = t(`generic.${element.field}`); }
          else if (element.field === 'supplier_business_name') {
            element.headerName = t(`purchaseinvoices.fields.supplier_business_name`);
          }
          else if (element.field === 'related_currency_code') {
            element.headerName = t(`currencies.title-view`);
          }
          else if (element.field === 'user_mail_send') {
            element.headerName = t(`payments.fields.user_mail_send`);
          }
          else if (element.field === 'purchase_invoice_name') {
            element.headerName = t(`purchaseinvoices.fields.purchase_invoice_name`);
            element.renderCell = function (params: GridRenderCellParams<PaymentDetail, any, any, GridTreeNodeWithRender>) {
              return <Button onClick={() => {
                let stringConverted = ConvertParamsToBase64(`purchase_invoice_id=${params.row.purchase_invoice_id}&supplier_id=${params.row.supplier_id}`);
                window.open(`/purchaseinvoices/view?${stringConverted}`, '_blank');
              }}>
                {params.row.purchase_invoice_name}
              </Button>
            }
          }
          else if (element.field === 'project_name') {
            element.headerName = t(`projects.fields.project_name`);
            element.renderCell = (params: GridRenderCellParams<PaymentDetail, any, any, GridTreeNodeWithRender>) => {
              return <Button onClick={() => {
                let stringConverted = ConvertParamsToBase64(`project_id=${params.row.project_id}`);
                window.open(`/projects/view?${stringConverted}`, '_blank');
              }}>
                {params.row.project_name}
              </Button>
            }
          }
        }
      }
      setColumns(myJson);
    }
  }

  const onAuthorizePayments = (isAgree: boolean = false) => {
    let myPaymentsDataToAuthorize = {
      data: selectedRows
        .filter(row => row.payment_detail_id && row.is_authorized === false)
        .map(row => row.payment_detail_id),
    };
    if (dataCurrencyTable.find((element) => element.balanceFree < 0) && !isAgree) {
      showSnackBar(t("authorizationpayments.info.no_balance_free"), "warning");
      setDialogAgreePayNegative(true);
      return;
    }
    if (myPaymentsDataToAuthorize.data.length > 0) {
      setIsLoadingPost(true);
      setBackDropLoading(true);
      GenericPostResource(`/authorizationpayments/isauthorized`, myPaymentsDataToAuthorize)
        .then((response) => {
          showSnackBar(t("authorizationpayments.info.authorized"), "success");
          setPaymentsDetailsData(prevData =>
            prevData.map((item: any) =>
              myPaymentsDataToAuthorize.data.includes(item.payment_detail_id)
                ? { ...item, is_authorized: true }
                : item
            )
          );
          setTotalPaymentsDetailsData(prevData =>
            prevData.map((item: any) =>
              myPaymentsDataToAuthorize.data.includes(item.payment_detail_id)
                ? { ...item, is_authorized: true }
                : item
            )
          );
          setDialogPayment(false);
          setDialogAgreePayNegative(false);
          setClickedButtonGoBack((prev) => !prev);
        })
        .catch((error) => {
          showSnackBar(error.message, 'error');
        })
        .finally(() => {
          setIsLoadingPost(false);
          setBackDropLoading(false);
        });
    }
    else {
      showSnackBar(t("authorizationpayments.info.already-authorized"), "info");
    }
  }

  const onRowSelection = (rowsIds: GridRowSelectionModel) => {
    setSelectedRows((prev) => {
      return paymentsDetailsData.filter(item => rowsIds.includes(item.payment_detail_id));
    });
  }

  const GetTheAmountForSum = async (myRow: any) => {
    setBackDropLoading(true);
    if (dataCurrencyTable.length > 0) {
      //BUscar en la tabla de currency de cuentas de banco si hay un banco con la cuenta bancaria
      let currencyEntry = dataCurrencyTable.find((currency) => currency.currency_code === myRow.related_currency_code && currency.active)
      if (!currencyEntry) {
        //Busca el por default que es el MXN
        currencyEntry = dataCurrencyTable.find((currency) => currency.currency_code === "MXN" && currency.active)
        if (!currencyEntry) {
          //Agarra el primero que encuentre
          currencyEntry = dataCurrencyTable.find((currency) => currency.active)
        }
      }
      if (currencyEntry && myRow.related_currency_code === currencyEntry.currency_code) {
        let amountSum = 0;
        if (myRow.current_payment_status_id === 1) {
          amountSum = myRow.amount_outstanding
          currencyEntry.balanceAutorized += amountSum;
          currencyEntry.balanceFree -= amountSum;
        }
      }
      else if (currencyEntry) {
        {
          let exchangeRate = dataCurrencyTable.find((element) => element.currency_code === myRow.related_currency_code)?.exchangeRate ?? 1;
          if (exchangeRate === 1) {
            exchangeRate = await dataCurrencyTable.find((element) => element.currency_code === currencyEntry?.currency_code)?.exchangeRate ?? 1;
          }
          let amountSum = 0;
          if (myRow.current_payment_status_id === 1) {
            let myOperation = await GetOperatorToGetDivisa(currencyEntry.currency_code, myRow.related_currency_code);
            // si es multiply:
            if (myOperation === "multiply") {
              amountSum = myRow.amount_outstanding * exchangeRate
            }
            // si es divide
            else if (myOperation === "divide") {
              amountSum = myRow.amount_outstanding / exchangeRate
            }
            currencyEntry.balanceAutorized += amountSum;
            currencyEntry.balanceFree -= amountSum;
          }
        }
      }
    }
    setBackDropLoading(false);
  }

  const calculateBalanceCurrency = async (bankAccounts: CompanyBankAccount[], currencyCombo: Currency[]) => {
    setBackDropLoading(true);
    let mydataCurrencyTable: MyTableCurrencyBanks[] = await Promise.all(
      currencyCombo
        .map(async (element) => {
          let exchangeRate = 1;
          let date = new Date();
          date.setDate(date.getDate() + 1);
          let currencyId = currencyCombo.find((elementCurrency) => elementCurrency.currency_code === element.currency_code)?.currency_id ?? 0;

          await GetExchangeRateFromCurrencyCode(currencyId, date).then((currency) => {
            exchangeRate = (currency?.rate && currency?.rate > 0) ? currency?.rate : 1;
          });

          return {
            currency_id: element.currency_id,
            currency_code: element.currency_code,
            currency_description: element.currency_description,
            balanceBankAccount: 0,
            balanceAutorized: 0,
            balanceFree: 0,
            exchangeRate: exchangeRate,
            active: false
          };
        })
    );

    bankAccounts.forEach(bankAccount => {
      if (bankAccount.is_active) {
        const currencyEntry = mydataCurrencyTable.find(
          currency => currency.currency_code === bankAccount.currency_code
        );
        if (currencyEntry) {
          currencyEntry.active = true
          currencyEntry.balanceBankAccount += bankAccount.balance ?? 0;
        }
      }
    });

    setDataCurrencyTable(mydataCurrencyTable);
    setBackDropLoading(false);
  }

  useEffect(() => {
    const calculateBalance = async () => {
      if (companyBalance.currency_code) {
        try {
          //Setear en 0
          await dataCurrencyTable.map((element) => { element.balanceAutorized = 0; element.balanceFree = element.balanceBankAccount });
          //Sumar autorizadas
          for (const column of totalPaymentsDetailsData) {
            if (column.is_authorized) {
              await GetTheAmountForSum(column);
            }
          }

          //Sumar seleccionadas
          for (const column of selectedRows) {
            if (!column.is_authorized) {
              await GetTheAmountForSum(column);
            }
          }

          setSpinnerBalance(false);
        } catch (error) {
          console.error("Error calculating balance:", error);
          setSpinnerBalance(false);
        }
      }
    };

    calculateBalance();
  }, [spinnerBalance, companyBalance, selectedRows, totalPaymentsDetailsData]);

  useEffect(() => {
    setDataLoaded(false);
    setMyEnumValue(comboEnum[4]);
    GenericGetResource(comboEnum[4].route)
      .then((response) => {
        setPaymentsDetailsData(response.data.data);
        setDefaultFilter([]);
        setSelectedRows((prev) => []);

        let myPermissions = [
          GetResourceByUrl(`/authorizationpayments`),
          GetResourceByUrl(`/authorizationpayments/isauthorized`),
        ]
        Promise.all(myPermissions)
          .then((responses) => {
            setResourceScreen((prev) => responses[0]);
            setResourceAuthorizePayments((prev) => responses[1]);
            loadColumnsOrder();
            loadPreferences();
            setIsLoadingDataTable(true);
            setDataLoaded(true);
          })
          .catch((error) => {
            setMessageSnack(error.message);
            showSnackBar(error.message, "error");
            setUnauthorized(false);
          });
      }
      ).catch((error) => {
        setMessageSnack(error.message);
        showSnackBar(error.message, "error");
        setUnauthorized(false);
      });
  }, [clickedButtonGoBack]);

  useEffect(() => {
    setIsLoadingDataTable(false);
    GenericGetResource(myEnumValue.route)
      .then((response) => {
        setPaymentsDetailsData(response.data.data);
        setSelectedRows((prev) => []);
        setIsLoadingDataTable(true);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
  }, [myEnumValue]);

  useEffect(() => {
    let myPromises = [
      GenericGetResourceGeneric("/companies", "/gcompanies"),
      GenericGetResource(comboEnum[4].route),
      GenericGetResource(`/currencies`),
      GenericGetResourceGeneric(`/companybankaccounts`, "/gcompanybankaccounts"),
      GenericGetResource(comboEnum[0].route),
    ];
    Promise.all(myPromises)
      .then(async (response) => {
        document.title = `${response[0].data.data[0].comercial_name} - ${t("authorizationpayments.title")}`;
        setPaymentsDetailsData(response[1].data.data);
        setTotalPaymentsDetailsData(response[4].data.data);
        await calculateBalanceCurrency(response[3].data.data, response[2].data.data);
        await GenericGetResource(`/bankaccounts/${response[0].data.data[0].primary_bank_account}`)
          .then((responseBank) => {
            let balanceCompany = {
              balance: responseBank.data.balance,
              currency_id: responseBank.data.currency_id,
              currency_code: responseBank.data.currency_code
            }
            setCompanyBalance(balanceCompany);
          })

        let myPermissions = [
          GetResourceByUrl(`/authorizationpayments`),
          GetResourceByUrl(`/authorizationpayments/isauthorized`),
        ]
        await Promise.all(myPermissions)
          .then((responses) => {
            setResourceScreen((prev) => responses[0]);
            setResourceAuthorizePayments((prev) => responses[1]);
            loadColumnsOrder();
            loadPreferences();
          })
          .catch((error) => {
            setMessageSnack(error.message);
            showSnackBar(error.message, "error");
            setUnauthorized(false);
          });
        setDataLoaded(true);
        setIsLoadingDataTable(true);
      }).catch((error) => {
        setMessageSnack(error.message);
        showSnackBar(error.message, "error");
        setUnauthorized(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../../assets/img/error.jpeg")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <>
        <Spinner />
        <SnackbarComponent />
      </>}
      <>
        <Backdrop
          sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
          open={backDropLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
      {dataLoaded && resourceScreen?.read &&
        <div className='screen-container'>
          <Header size="no_title"
            child={
              <>
                <DataTable
                  columns={columnsCurrency}
                  setColumns={setColumnsCurrency}
                  data={dataCurrencyTable.filter(row => row.active === true)}
                  entityId={"currency_id"}
                  entity={`MyTableCurrencyBanks`}
                  preferences={{}}
                  namePreferences={"grid-currencybanks-columns"}
                  nameOrderColumns={"grid-currencybanks-columns"}
                  isChildren={true}
                  hideFooter={true}
                  hideShadow={true}
                  disableColumnMenu={true}
                />
                <div className="w-100 d-flex flex-row mt-2">
                  <div className="w-50 mt-2">
                    <h3>{t("authorizationpayments.title")}</h3>
                  </div>
                  <div className="w-50 d-flex flex-row-reverse">
                    <div className='w-25 mt-2'>
                      <PrimaryButton size="small" variant='outlined' className="m-1" sx={{ width: "100%", height: "90%", color: 'primary.main' }} disabled={(selectedRows?.length === 0) || !resourceAuthorizePayments?.create} onClick={() => setDialogPayment(true)}>
                        {t("authorizationpayments.buttons.authorize-payments")}<GradingIcon sx={{ marginLeft: 1 }} />
                      </PrimaryButton>
                    </div>
                    <div className='w-25'>
                      <Autocomplete
                        size="small"
                        options={comboEnum}
                        defaultValue={defaultEnum}
                        getOptionLabel={(option) => option.option_name}
                        renderOption={(props, option) => (
                          <div key={option.enum_id}>
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                              {option.option_name}
                            </Box>
                          </div>
                        )}
                        sx={{ width: "100%", paddingRight: 1, paddingTop: 1 }}
                        isOptionEqualToValue={(option, value) => option.enum_id === value.enum_id}
                        onChange={(_, values) => {
                          setMyEnumValue(values ?? { enum_id: 1, option_name: t("authorizationpayments.comboEnum.all"), route: `/authorizationpayments` });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${t("generic.filter")}`}
                            variant="filled"
                          />
                        )}
                        clearOnEscape={false}
                        clearIcon={null}
                      />
                    </div>
                  </div>
                </div>
              </>
            }
          />
          <div className="d-flex justify-content-center mt-2">
            {!isLoadingDataTable && <Spinner />}
            {isLoadingDataTable &&
              <DataTable
                columns={columns}
                setColumns={setColumns}
                data={paymentsDetailsData}
                entityId={"payment_detail_id"}
                entity={`AuthorizationPayments`}
                preferences={myPreferences}
                namePreferences={"grid-authorizationpayments"}
                nameOrderColumns={"grid-authorizationpayments-columns"}
                selectRows
                onRowSelectionModelChange={(rowsIds) => onRowSelection(rowsIds)}
                isChildren={true}
                hasDoubleClick
                initFilter={defaultFilter}
                countColumns={["total_company_currency_amount"]}
              />
            }
          </div>
          <DialogEntity
            open={dialogPayment}
            setOpen={setDialogPayment}
            title={t("authorizationpayments.dialog.title")}
            content={
              <Box display={"flex"} flexDirection={"column"}>
                <Typography>{t("authorizationpayments.dialog.content")}</Typography>
                <Box display={"flex"} flexDirection={"row-reverse"} marginTop={2}>
                  <ButtonLoading
                    disabled={!resourceAuthorizePayments?.create}
                    isLoading={isLoadingPost}
                    onClick={() => onAuthorizePayments()}
                    className="d-flex align-self-end w-25"
                    variant="outlined"
                  >
                    {t("generic.buttons.accept")}
                  </ButtonLoading>
                  <Button variant='outlined' onClick={() => { setDialogPayment(false); setClickedButtonGoBack((prev) => !prev) }}>{t("generic.buttons.goback")}</Button>
                </Box>
              </Box>
            }
          />
          <DialogEntity
            open={dialogAgreePayNegative}
            setOpen={setDialogAgreePayNegative}
            title={t("authorizationpayments.dialog.title")}
            content={
              <Box display={"flex"} flexDirection={"column"}>
                <Typography>{t("authorizationpayments.dialog.content_pay_negative")}</Typography>
                <Box display={"flex"} flexDirection={"row-reverse"} marginTop={2}>
                  <ButtonLoading
                    disabled={!resourceAuthorizePayments?.create}
                    isLoading={isLoadingPost}
                    onClick={() => onAuthorizePayments(true)}
                    className="d-flex align-self-end w-25"
                    variant="outlined"
                  >
                    {t("generic.buttons.accept")}
                  </ButtonLoading>
                  <Button variant='outlined' onClick={() => { setDialogPayment(false); setDialogAgreePayNegative(false); setClickedButtonGoBack((prev) => !prev) }}>{t("generic.buttons.goback")}</Button>
                </Box>
              </Box>
            }
          />
          <SnackbarComponent />
        </div>
      }
    </>
  )
}
