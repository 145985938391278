import { Header } from '../../../../components/Header/index';
import { Autocomplete, Avatar, Box, Button, IconButton, Switch, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '../../../../interfaces/Security/users';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { Group } from '../../../../interfaces/Security/groups';
import GenericPromises from '../../../../api/GenericPromises';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { emailRegex } from '../../../../constants/Regex';
import { ButtonStyled, PrimaryButton } from '../../../../theme/buttons';
import { useTranslation } from 'react-i18next';
import { ImageDataComponent } from '../../../../components/Images/ImageData';
import { File } from '../../../../interfaces/Commons/files';
import { useFiles } from '../../../../hooks/useFiles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';

export const AddUser = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { GenericGetResource, GenericPostResource, GenericPost, GenericPutResource, GenericGetResourceGeneric } = GenericPromises();
  const { DecodeFileToBase64 } = useFiles();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [comboGroups, setComboGroups] = useState<Group[]>([]);
  const comboLanguages = [{ id: 'es', name: "Español" }, { id: 'en', name: "English" }, { id: 'zh', name: "中文（简体）" },];
  const [dataLoaded, setDataLoaded] = useState(false);
  const [imageFile, setImageFile] = useState<File>({});
  const [imageLoaded, setImageLoaded] = useState(false);
  const [hiddenPassword, setHiddenPassword] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<User>({
    defaultValues: {
      user_id: 0,
      user_phone: '',
      user_email: '',
      user_name: '',
      language: undefined,
      photo_id: undefined,
      group_id: undefined,
      password: undefined,
      user_status: true,
    }
  });
  const onSubmit: SubmitHandler<User> = (data) => onPost(data);

  const onPost = (data: User) => {
    if (data.group_id === null) return;
    let myData = {
      user_name: data.user_name,
      user_email: data.user_email,
      password: data.password,
      language: data.language,
      photo_id: null,
      user_phone: data.user_phone,
      user_status: data.user_status,
      group_id: data.group_id,
    }
    setLoadingPost(true);
    GenericPostResource("/users", myData)
      .then((response) => {
        if (imageFile.dataURL !== undefined) {
          let myFile = {
            entity: "Users",
            recordId: response.data.user_id,
            fileName: imageFile.file_name,
            typeOfFile: imageFile.type_of_file,
            schema_name: localStorage.getItem("schemaName"),
            Content: 'dbo',
          }
          GenericPost(`/files`, myFile).then((response1) => {
            myData.photo_id = response1.file_id;
            GenericPutResource(`/users/${response.data.user_id}`, myData)
              .then(() => {
                navigate("/users", { state: { method: "add" } });
              });
          })
        }
        else {
          navigate("/users", { state: { method: "add" } });
        }
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    navigate("/users", { state: {} });
  }

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("users.title")}`;
    })

    GenericGetResource("/groups")
      .then((response) => {
        setComboGroups(response.data.data);
        setDataLoaded(true);
      }).catch((error) => {
        showSnackBar(error.message, "error");
      })

  }, []);

  useEffect(() => { }, [hiddenPassword]);

  return (
    <>
      {!dataLoaded && <Spinner />}
      {dataLoaded &&
        <>
          <Header title={t("users.title-view")} />

          <div className='d-flex justify-content-center'>

            <div className='custom-card-view w-75'>

              <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                <div className="custom-card-row d-flex justify-content-between">

                  <div className='w-100'>

                    <div className='d-flex'>
                      <div className="w-50">
                        <Controller
                          name="user_name"
                          control={control}
                          rules={{ required: true, minLength: 6 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              ref={field.ref}
                              value={field.value}
                              label={t("users.fields.user_name")}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.user_name}
                              helperText={GetError(errors.user_name?.type)}
                              size="small"
                              style={{ width: "100%" }}
                              sx={{ paddingRight: 2 }}
                            />
                          }
                        />
                      </div>
                      <div className="w-50">
                        <Controller
                          name="user_phone"
                          control={control}
                          rules={{ maxLength: 10, minLength: 10 }}
                          render={({ field }) =>
                            <TextField
                              ref={field.ref}
                              variant="filled"
                              sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                "& input[type=number]": { MozAppearance: "textfield", }
                              }}
                              type="number"
                              value={field.value}
                              onChange={field.onChange}
                              label={t("users.fields.user_phone")}
                              error={!!errors.user_phone}
                              helperText={GetError(errors.user_phone?.type)}
                              size="small"
                              style={{ width: "100%" }}
                            />
                          }
                        />
                      </div>
                    </div>

                    <div className="d-flex w-100 mt-3">
                      <div className="w-50">
                        <Controller
                          name="user_email"
                          control={control}
                          rules={{ required: true, pattern: emailRegex }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              ref={field.ref}
                              value={field.value}
                              onChange={field.onChange}
                              label={t("users.fields.user_email")}
                              error={!!errors.user_email}
                              helperText={GetError(errors.user_email?.type)}
                              size="small"
                              style={{ width: "100%" }}
                              sx={{ paddingRight: 2 }}
                              autoComplete='off'
                            />
                          }
                        />
                      </div>
                      <div className='w-50'>
                        <Controller
                          name="password"
                          control={control}
                          rules={{ maxLength: 50, minLength: 8, required: true }}
                          render={({ field }) =>
                            <TextField
                              ref={field.ref}
                              InputProps={{
                                endAdornment: <IconButton children={hiddenPassword ? <VisibilityOffIcon /> : <VisibilityIcon />} onClick={() => { setHiddenPassword((prev) => (!hiddenPassword)) }} />
                              }}
                              variant="filled"
                              type={`${hiddenPassword ? 'text' : 'password'}`}
                              value={field.value}
                              onChange={field.onChange}
                              label={t("users.fields.password")}
                              error={!!errors.password}
                              helperText={GetError(errors.password?.type)}
                              size="small"
                              style={{ width: "100%" }}
                              autoComplete='off'
                            />
                          }
                        />
                      </div>
                    </div>

                    <div className="d-flex w-100 mt-3">
                      <div className="w-50">
                        <Controller
                          name="group_id"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Autocomplete
                              ref={field.ref}
                              size="small"
                              sx={{ width: "100%" }}
                              options={comboGroups}
                              getOptionLabel={(option) => option.group_name}
                              renderOption={(props, option: Group) => (
                                <div key={option.group_id}>
                                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.group_name}
                                  </Box>
                                </div>
                              )}
                              isOptionEqualToValue={(option, value) => option.group_id === value.group_id}
                              onChange={(_, values) => {
                                field.onChange(values?.group_id || null)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  label={t("users.fields.group_name")}
                                  value={field.value}
                                  error={!!errors.group_id || field.value === null}
                                  helperText={(errors.group_id?.type || field.value === null) ? t("generic.forms-errors.required") : null}
                                  sx={{ paddingRight: 2 }}
                                />
                              )}
                            />
                          )}
                        />
                      </div>

                      <div className="w-50">
                        <Controller
                          name="language"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Autocomplete
                              ref={field.ref}
                              size="small"
                              sx={{ width: "100%" }}
                              options={comboLanguages}
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option) => (
                                <div key={option.id}>
                                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.name}
                                  </Box>
                                </div>
                              )}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              onChange={(_, values) => {
                                field.onChange(values?.id || null)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  label={t("users.fields.language")}
                                  value={field.value}
                                  error={!!errors.language || field.value === null}
                                  helperText={(errors.language?.type || field.value === null) ? t("generic.forms-errors.required") : null}
                                />
                              )}
                            />
                          )}
                        />
                      </div>

                    </div>

                    <div className="d-flex mt-3">
                      <div className="w-50">
                        <Controller
                          name="user_status"
                          control={control}
                          render={({ field }) =>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
                              <Typography sx={{ ml: 2 }}>{t("users.fields.user_status")}</Typography>
                              <Switch ref={field.ref} onChange={field.onChange} value={field.value} defaultChecked={true} />
                            </Box>
                          }
                        />
                      </div>
                    </div>

                  </div>

                  <Avatar className='mx-4' sx={{ bgcolor: 'transparent', border: '1px solid grey', height: 190, width: 190 }} variant='rounded' alt={""}>
                    <Button component="label">
                      {imageLoaded &&
                        <ImageDataComponent url={imageFile.dataURL === undefined ? '' : imageFile.dataURL} />
                      }
                      {!imageLoaded && <img alt='profile' width={190} height={190} src={require("../../../../assets/img/profile.jpeg")} />}
                      <input type="file" accept="image/*" hidden
                        onChange={async (e) => {
                          await DecodeFileToBase64(e).then(async (response) => {
                            await setImageFile(response);
                            setImageLoaded(true);
                          });
                        }
                        } />
                    </Button>
                  </Avatar>

                </div>

                <div className='d-flex flex-row-reverse mt-4'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                  <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
                </div>

              </form>

            </div>
          </div>

        </>
      }
      <SnackbarComponent />
    </>
  )
}

