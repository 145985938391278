import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GenericPromises from '../../../api/GenericPromises';
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import { Header } from '../../../components/Header';
import { Autocomplete, Box, Switch, TextField, Typography } from '@mui/material';
import { ButtonStyled, PrimaryButton } from '../../../theme/buttons';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { Parameter } from '../../../interfaces/Commons/parameters';
import { FieldType } from '../../../interfaces/Projects/Catalogs/fieldTypes';

export const AddParameter = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { GenericPostResource, GenericGetResourceGeneric, GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboFieldTypes, setComboFieldTypes] = useState<FieldType[]>([]);
  const [isBoolean, setIsBoolean] = useState(false);
  const [valueBoolean, setValueBoolean] = useState(false);

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<Parameter>({
    defaultValues: {
      parameter_name: undefined,
      parameter_value: "",
    }
  });
  const onSubmit: SubmitHandler<Parameter> = (data) => onPost(data);

  const onPost = (data: Parameter) => {
    let myData = {
      field_type_id: data.field_type_id,
      parameter_name: data.parameter_name ?? null,
      parameter_value: (isBoolean) ? valueBoolean.valueOf().toString() : data.parameter_value,
    }
    setLoadingPost(true);
    GenericPostResource("/parameters", myData)
      .then((response) => {
        navigate("/parameters", { state: { method: "add" } });
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, 'error');
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    navigate("/parameters", { state: {} });
  }

  useEffect(() => {
    GenericGetResource("/fieldtypes").then((responseFieldTypes) => {
      setComboFieldTypes(responseFieldTypes.data.data);
      setDataLoaded(true);
    })
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("parameters.title")}`;
    })
  }, [])

  return (
    <>
      {!dataLoaded && <Spinner />}
      {dataLoaded &&
        <>
          <Header title={t("parameters.title-view")} />

          <div className='d-flex justify-content-center'>

            <div className='custom-card-view w-75'>

              <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                <div className="d-flex justify-content-between">

                  <div className='d-flex w-100'>
                    <div className='w-50'>
                      <Controller
                        name="parameter_name"
                        control={control}
                        rules={{ maxLength: 200 }}
                        render={({ field }) =>
                          <TextField
                            variant="filled"
                            label={`${t("parameters.fields.parameter_name")}`}
                            ref={field.ref}
                            value={field.value}
                            onChange={(event) => { field.onChange(event.target.value) }}
                            error={!!errors.parameter_name}
                            helperText={GetError(errors.parameter_name?.type)}
                            size="small"
                            style={{ width: "100%" }}
                            sx={{ paddingRight: 2 }}
                          />
                        }
                      />
                    </div>

                    <div className='w-50'>
                      {(isBoolean)
                        ?
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: "space-between" }}>
                          <Typography sx={{ ml: 2 }}>{t("parameters.fields.parameter_value")}</Typography>
                          <Switch
                            onChange={(event) => {
                              if (event.target.value && isBoolean) {
                                setValue("parameter_value", event.target.value.toString())
                                setValueBoolean(event.target.checked);
                              }
                            }}
                            checked={valueBoolean}
                            value={(getValues("parameter_value") === "true")} />
                        </Box>
                        :
                        <Controller
                          name="parameter_value"
                          control={control}
                          rules={{ required: true, maxLength: 500 }}
                          render={({ field }) =>
                            <TextField
                              variant="filled"
                              label={`${t("parameters.fields.parameter_value")}`}
                              ref={field.ref}
                              sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                "& input[type=number]": { MozAppearance: "textfield", },
                              }}
                              value={field.value}
                              onChange={(event) => { field.onChange(event.target.value) }}
                              error={!!errors.parameter_value}
                              helperText={GetError(errors.parameter_value?.type)}
                              size="small"
                              style={{ width: "100%" }}
                            />
                          }
                        />
                      }
                    </div>
                  </div>

                </div>

                <div className='d-flex w-100 mt-2'>
                  <div className="d-flex w-100">
                    <div className="w-50">
                      <Controller
                        name="field_type_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Autocomplete
                            ref={field.ref}
                            size="small"
                            sx={{
                              width: "100%", paddingRight: 2,
                            }}
                            options={comboFieldTypes}
                            getOptionLabel={(option) => option.field_type_name}
                            renderOption={(props, option: FieldType) => (
                              <div key={option.field_type_id}>
                                <Box
                                  component="li"
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  {option.field_type_name}
                                </Box>
                              </div>
                            )}
                            isOptionEqualToValue={(option, value) =>
                              option.field_type_id === value.field_type_id
                            }
                            onChange={(_, values) => {
                              setIsBoolean((values?.field_type_name === 'Boolean'))
                              field.onChange(values?.field_type_id || null);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={`${t("parameters.fields.field_type")}`}
                                variant="filled"
                                value={field.value}
                                error={!!errors.field_type_id}
                                helperText={GetError(errors.field_type_id?.type)}
                              />
                            )}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-row-reverse mt-1'>
                  <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                  <ButtonStyled variant='contained' className='m-1' onClick={onCancel}>{t("generic.buttons.cancel")}</ButtonStyled>
                </div>

              </form>

            </div>

          </div>
        </>
      }
      <SnackbarComponent />
    </>
  )
}
