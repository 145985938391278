import React from 'react'

export const useColors = () => {

  const HexToRGB = (hexColor: string) => {
    hexColor = hexColor.replace("#", "");

    const red = parseInt(hexColor.substring(0, 2), 16);
    const green = parseInt(hexColor.substring(2, 4), 16);
    const blue = parseInt(hexColor.substring(4, 6), 16);

    return [red, green, blue];
  };

  const LightenColor = (colorHexadecimal: string, steps: number): string => {
    const normalizeHex = (color: string): string => {
      if (color.startsWith("#")) {
        color = color.slice(1);
      }
      if (color.length === 3) {
        color = color
          .split("")
          .map((char) => char + char)
          .join("");
      }
      return color;
    };

    const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
      const intValue = parseInt(hex, 16);
      return {
        r: (intValue >> 16) & 255,
        g: (intValue >> 8) & 255,
        b: intValue & 255,
      };
    };

    const rgbToHex = (r: number, g: number, b: number): string => {
      return `#${((1 << 24) + (r << 16) + (g << 8) + b)
        .toString(16)
        .slice(1)
        .toUpperCase()}`;
    };

    const lighten = (r: number, g: number, b: number, step: number): { r: number; g: number; b: number } => {
      const lightenFactor = step / 10; // Escala el factor de aclarado
      return {
        r: Math.min(255, Math.floor(r + (255 - r) * lightenFactor)),
        g: Math.min(255, Math.floor(g + (255 - g) * lightenFactor)),
        b: Math.min(255, Math.floor(b + (255 - b) * lightenFactor)),
      };
    };

    const normalizedHex = normalizeHex(colorHexadecimal);
    const { r, g, b } = hexToRgb(normalizedHex);
    const lighterColor = lighten(r, g, b, steps);

    return rgbToHex(lighterColor.r, lighterColor.g, lighterColor.b);
  };

  return {
    LightenColor,
    HexToRGB,
  }
}
