import { Route, Routes } from 'react-router-dom'
import { TableSalesOrders } from './table'
import { AddSalesOrders } from './add'
import { ViewSalesOrders } from './view'

export const BrowserSalesOrders = () => {
  return (
    <Routes>
      <Route path={"/"} element={<TableSalesOrders />} />
      <Route path={"/add"} element={<AddSalesOrders />} />
      <Route path={"/view"} element={<ViewSalesOrders />} />
    </Routes>
  )
}