import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Company } from "../../../../interfaces/Security/companies";
import { Menuitem } from "../../../../interfaces/Security/menu";
import GenericPromises from "../../../../api/GenericPromises";
import { Button } from "@mui/material";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../../components/Header";
import { UpdateCompanies } from "./update";
import CompanyDetail from "./companyDetail";
import { BrowserCompanyLocations } from "./CompanyLocations";
import { usePermissions } from "../../../../hooks/usePermissions";
import { GenericDialog } from "../../../../components/Dialogs/Dialog";
import { ButtonStyled } from "../../../../theme/buttons";
import { BrowserCompanyBankAccounts } from "./CompanyBankAccounts";
import { Desing } from "./desing";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { BrowserCompanyConfigurations } from "./companyConfigurations";
import { TabCompanySignature } from "./companySignature";

export const ViewCompanies = () => {
  const [t] = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();
  const [companyLocal, setCompanyLocal] = useState<Company>();
  const [openDialog, setOpenDialog] = useState(false);
  const [dataloaded, setDataLoaded] = useState(false);
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [resourceScreenLocation, setResourceScreenLocation] = useState<Menuitem>();
  const [resourceScreenConfig, setResourceScreenConfig] = useState<Menuitem>();
  const [resourceScreenBank, setResourceScreenBank] = useState<Menuitem>()
  const [resourceScreenSignature, setResourceScreenSignature] = useState<Menuitem>()
  const { GetResourceByUrl } = usePermissions();
  const { showSnackBar, SnackbarComponent } = useSnackBar();

  const onDelete = () => {
    GenericDeleteResource(`/companies/${companyLocal?.company_id}`)
      .then(() => {
        setOpenDialog(false);
        navigate("/companies", { state: { method: "delete" }, replace: true });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      })
  }

  useEffect(() => {
    if (location.state === null) return navigate("/companies", { state: {}, replace: true });
    GenericGetResource(`/companies/${location.state.row.company_id}`)
      .then(
        (response) => {
          setCompanyLocal(response.data);
          let myPromises = [
            GetResourceByUrl(`/companies`),
            GetResourceByUrl(`/companybankaccounts`),
            GetResourceByUrl(`/companyconfigurations`),
            GetResourceByUrl(`/companylocations`),
            GetResourceByUrl(`/companysignature`),
          ]
          Promise.all(myPromises)
            .then((responses) => {
              setResourceScreen((prev) => responses[0]);
              setResourceScreenBank((prev) => responses[1])
              setResourceScreenConfig((prev) => responses[2])
              setResourceScreenLocation((prev) => responses[3])
              setResourceScreenSignature((prev) => responses[4])
              setDataLoaded(true);
            })
            .catch((error) => {
              showSnackBar(error.message, "error");
            });
        }
      ).catch((error) => {
        showSnackBar(error.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded &&
        <>
          <Header
            title={t("companies.title-view")}
            child={
              <div className='d-flex flex-row-reverse m-1'>
                {resourceScreen?.delete === true &&
                  <ButtonStyled variant="contained" className="my-1" onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</ButtonStyled>
                }
                <Button variant="outlined" onClick={() => { navigate("/companies", { replace: true }) }}>{t("generic.buttons.goback")}</Button>
              </div>
            }
          />
          {resourceScreen?.read &&
            <>

              <div className="mt-4">
                <CompanyDetail
                  disabledLocation={!(resourceScreenLocation?.read)}
                  disabledInvoice={!(resourceScreenSignature?.read)}
                  disabledBankAccount={!(resourceScreenBank?.read)}
                  disabledConfiguration={!(resourceScreenConfig?.read)}
                  General={
                    <UpdateCompanies companyPayload={companyLocal} isEdit={resourceScreen?.update} />
                  }
                  Locations={
                    <BrowserCompanyLocations company_id={companyLocal?.company_id} resourceScreen={resourceScreenLocation} />
                  }
                  Invoice={
                    <TabCompanySignature companyPayload={companyLocal} isEdit={resourceScreenSignature?.update ?? false} setCompanyLocal={setCompanyLocal} />
                  }
                  BankAccounts={
                    <BrowserCompanyBankAccounts company_id={companyLocal?.company_id} />
                  }
                  Design={
                    <Desing companyPayload={companyLocal} setCompanyLocal={setCompanyLocal} isEdit={resourceScreen?.update} />
                  }
                  Configurations={
                    <BrowserCompanyConfigurations companyPayload={companyLocal} isEdit={resourceScreenConfig?.update} />
                  }
                />
              </div>
              <div>

              </div>
            </>
          }
        </>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("companies.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <SnackbarComponent />
    </>
  )
}