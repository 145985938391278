import { useState, useEffect, useCallback } from "react";
import GenericPromises from "../../api/GenericPromises";
import { ProjectTask } from "../../interfaces/Projects/Catalogs/projectTasks";
import { ProjectTaskDashboard } from "../../interfaces/Projects/projectTaskDashboard";
import useSnackBar from "../../components/Commons/SnackBar/useSnackBar";
import { ProjectTaskState } from "../../interfaces/Projects/Catalogs/projectTaskStates";
import { TaskState } from "../../interfaces/Projects/Catalogs/taskStates";

export const useProjectTaskColor = () => {
    const { GenericGetResource } = GenericPromises();
    const { showSnackBar, SnackbarComponent } = useSnackBar();

    const parseDate = (value?: string | Date | null): Date | null => {
        if (!value) return null;
        return new Date(value);
    };

    const determineTaskColor = (
        startDate: Date | null,
        dueDate: Date | null,
        currentStateId: number | undefined,
        taskCreationDate: Date | null,
        newStateId: number | undefined,
        pendingStateId: number,
        doneStateId: number
    ): string | null => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (!startDate || !dueDate) return null;

        if (today < startDate) return "gray";
        if (startDate <= today && dueDate > today && currentStateId === pendingStateId) return "yellow";
        if (taskCreationDate && taskCreationDate < dueDate && newStateId === doneStateId) return "green";
        if (today >= dueDate && currentStateId === pendingStateId) return "red";
        if (taskCreationDate && taskCreationDate > dueDate && newStateId === doneStateId) return "orange";
        return null;
    };

    const setProjectTaskData = (currentTask: ProjectTask | ProjectTaskDashboard,
        lastProjectTaskState: ProjectTaskState | null,
        pendingStateId: number,
        doneStateId: number
    ) => {

        const startDate = parseDate(currentTask.project_task_started_at);
        const dueDate = parseDate(currentTask.project_task_due_date);
        const taskCreationDate = parseDate(lastProjectTaskState?.creation_date);
        const newStateId = lastProjectTaskState?.new_state_id;
        const currentStateId = currentTask.current_task_state_id;

        return determineTaskColor(
            startDate,
            dueDate,
            currentStateId,
            taskCreationDate,
            newStateId,
            pendingStateId,
            doneStateId
        );
    }

    const getProjectTaskColor = async (task: ProjectTask | ProjectTaskDashboard, pendingStateId: TaskState, doneStateId: TaskState) => {
        if (!task.project_task_id) return;

        const pendingStateIdValue = pendingStateId.task_state_id;
        const doneStateIdValue = doneStateId.task_state_id;

        if (pendingStateIdValue === undefined || doneStateIdValue === undefined) {
            return;
        }
        let color=null;
        if (pendingStateId && doneStateId) {
            await GenericGetResource(`/projecttaskstates/lastprojecttaskstatebyprojecttaskid/${task.project_task_id}`)
                .then((response) => {
                    color= setProjectTaskData(task, response.data.data[0], pendingStateIdValue, doneStateIdValue)
                })
                .catch((error) => {

                    showSnackBar(error.message, "error");
                });
        }
        return color;
    };

    return {
        getProjectTaskColor,
    };
};
