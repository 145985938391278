import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors";
import GenericPromises from "../../../../api/GenericPromises";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { OptionMenu } from "../../../../interfaces/Security/optionsMenu";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { ButtonStyled, PrimaryButton } from "../../../../theme/buttons";
import ResourceEntities from "../../../../components/Security/AccordionMenu/resourceEntities";
import { Group } from "../../../../interfaces/Security/groups";
import { usePermissions } from "../../../../hooks/usePermissions";
import { useBase64 } from "../../../../hooks/useBase64";
import { Divider } from "../../../../components/Commons/Divider";

interface EnumRoutes {
  enum_id: number;
  option_name: string;
  route: string;
}

type CopyOptionMenu = {
  optionMenu: any;
  comboTrees: EnumRoutes[];
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  openDialog: boolean;
  setObjectCopyOptionMenu: Dispatch<SetStateAction<OptionMenu | undefined>>;
  setDataMenuLoaded: Dispatch<SetStateAction<boolean>>;
  myEnumValue: EnumRoutes | undefined;
  setDefaultOptionMenuData: Dispatch<SetStateAction<OptionMenu | undefined>>;
  orderMenu: (subleveltree: OptionMenu[]) => OptionMenu[]
  setMenuData: Dispatch<SetStateAction<OptionMenu[]>>
};

export const CopyOptionMenu = ({
  optionMenu,
  setOpenDialog,
  openDialog,
  comboTrees,
  setDefaultOptionMenuData,
  setDataMenuLoaded,
  orderMenu,
  setMenuData,
  myEnumValue,
  setObjectCopyOptionMenu
}: CopyOptionMenu) => {
  const [t] = useTranslation("global");
  const { GenericPostResource, GenericGetResource } = GenericPromises();
  const { GetError } = FormsErrors();
  const { GetResourceByUrl } = usePermissions();
  const { utf8ToBase64 } = useBase64();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboParents, setComboParents] = useState<OptionMenu[]>([]);
  const [myTree, setMyTree] = useState<EnumRoutes | null>();
  const [myParent, setMyParent] = useState<OptionMenu | null>(null);
  const [comboGroups, setComboGroups] = useState<Group[]>([])
  const [myGroup, setMyGroup] = useState<Group[]>([]);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [messageSnack, setMessageSnack] = useState("");
  const GetResource = ResourceEntities();

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    reset,
    trigger,
  } = useForm<any>({
    defaultValues: {
      groups: undefined,
      option_menu_id: undefined,
      parent_option_id: undefined,
      resource_id: undefined,
      option_name: undefined,
      description: undefined,
      menu_order: undefined,
      is_visible: true,
    },
  });
  const onSubmit: SubmitHandler<OptionMenu> = (data) => onPost(data);

  const onPost = (data: OptionMenu) => {
    setLoadingPost(true);
    trigger().then(async (triggerData) => {
      if (triggerData) {
        let myData = {
          is_visible: optionMenu?.is_visible,
          level: optionMenu?.level,
          menu_order: optionMenu?.menu_order,
          number_of_children: optionMenu?.number_of_children,
          option_menu_id: optionMenu?.option_menu_id,
          option_name: optionMenu?.option_name,
          description: optionMenu?.description,
          parent_option_id: optionMenu?.parent_option_id,
          prefix: optionMenu?.prefix,
          read: optionMenu?.read,
          resource_id: optionMenu?.resource_id,
          resource_name: optionMenu?.resource_name,
          resource_route: optionMenu?.resource_route,
          resource_type_name: optionMenu?.resource_type_name,
          root_id: optionMenu?.root_id,
          update: optionMenu?.update,
          children: optionMenu?.children,
          groups: myGroup
        }
        GenericPostResource(
          `/optionmenu/copyin/${myParent?.option_menu_id}`,
          myData
        )
          .then((response) => {
            setDataMenuLoaded(false);
            GenericGetResource(myEnumValue?.route ?? "")
              .then((response) => {
                setOpenDialog(false);
                setObjectCopyOptionMenu(undefined);
                setDefaultOptionMenuData(response.data);
                if (response.data && response.data.children && response.data.children.length > 0) {
                  let children = response.data.children;
                  if (children) {
                    children = orderMenu(children);
                  }
                  children = children.sort((a: any, b: any) => a.menu_order - b.menu_order);
                  setMenuData(children);
                  setDataMenuLoaded(true);
                }
                else {
                  setMenuData([]);
                  setDataMenuLoaded(true);
                }
              })
              .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, "error");
              });
          }).catch((error) => {
            setLoadingPost(false);
            showSnackBar(error.message, "error");
          });
      };
    })
  }

  const collectOptionMenusWithChildren = (menu: OptionMenu[]): OptionMenu[] => {
    const result: OptionMenu[] = [];

    const traverse = (items: OptionMenu[]) => {
      for (const item of items) {
        if (item.children && item.children.length > 0) {
          result.push(item);
          traverse(item.children);
        }
      }
    };

    traverse(menu);

    return result;
  };

  const onCancel = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    setDataLoaded(true);
  }, []);

  useEffect(() => {
    setComboParents([]);
    setComboGroups([]);
    if (myTree) {
      GenericGetResource(myTree?.route)
        .then((response) => {
          let myComboParents = collectOptionMenusWithChildren(
            response.data.children
          );
          myComboParents.push(response.data);
          setComboParents(myComboParents);
        })
        .catch((error) => {
          setMessageSnack(error.message);
          showSnackBar(error.message, "error");
        });
      let myPromises = [
        GetResourceByUrl("/permissions"),
        GetResourceByUrl("/groups"),
      ];
      Promise.all(myPromises)
        .then((responses) => {
          if ((responses[0].create && responses[0].create === true) &&
            (responses[1].read && responses[1].read === true)) {
            GenericGetResource(`/groups?filter=${utf8ToBase64(`option_menu_id=${myTree.enum_id}`)}`).then((responseGroup) => {
              setComboGroups(responseGroup.data.data)
            })
          }
        })
    }
  }, [myTree]);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded && (
        <Box className="d-flex justify-content-center" sx={{ minWidth: 1000 }}>
          <form onSubmit={handleSubmit(onSubmit)} className="w-100">
            <div className="w-100">
              <div className="w-100 d-flex">
                <div className="w-50">
                  <Autocomplete
                    size="small"
                    options={comboTrees}
                    getOptionLabel={(option) => option.option_name}
                    renderOption={(props, option) => (
                      <div key={option.enum_id}>
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.option_name}
                        </Box>
                      </div>
                    )}
                    sx={{ width: "100%", paddingRight: 2 }}
                    isOptionEqualToValue={(option, value) =>
                      option.enum_id === value.enum_id
                    }
                    onChange={(_, values) => {
                      setMyTree(values ?? null);
                      setValue("option_menu_id", undefined);
                      setMyParent(null);
                      setMyGroup([]);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${t("generic.filter")}`}
                        variant="filled"
                      />
                    )}
                    clearOnEscape={false}
                    clearIcon={null}
                  />
                </div>
                <div className="w-50">
                  <Controller
                    name="option_menu_id"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        ref={field.ref}
                        size="small"
                        sx={{ width: "100%" }}
                        options={comboParents}
                        value={myParent}
                        disabled={comboParents.length <= 0}
                        getOptionLabel={(option) =>
                          `${option.description ?? option.option_name}`
                        }
                        renderOption={(props, option: OptionMenu) => (
                          <div key={option.option_menu_id}>
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <div className="d-flex flex-row">
                                <div className="me-3">
                                  {
                                    GetResource(option.resource_name ?? option.option_name)
                                      ?.icon
                                  }
                                </div>
                                <div>
                                  {`${option.description}`}
                                </div>
                              </div>
                            </Box>
                          </div>
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option.option_menu_id === value.option_menu_id
                        }
                        onChange={(_, values) => {
                          field.onChange(values?.option_menu_id || null)
                          setMyParent(values ?? null);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${t(
                              "optionsmenu.fields.parent_option_id"
                            )}`}
                            variant="filled"
                            value={field.value}
                            error={!!errors.option_menu_id}
                            helperText={GetError(errors.option_menu_id?.type)}
                          />
                        )}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className='d-flex w-100 ms-1 mt-2'>
              <div>
                <Typography variant="h6">
                  {t("optionsmenu.info.resource_group")}
                </Typography>
              </div>
            </div>
            <div className='d-flex w-100 mt-2'>
              <div className='w-50'>
                <Controller
                  name="groups"
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      multiple
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboGroups}
                      disabled={comboGroups.length <= 0}
                      value={myGroup}
                      getOptionLabel={(option) => option.group_name}
                      renderOption={(props, option: Group) => (
                        <div key={option.group_id}>
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {`${option.group_name}`}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) => option.group_id === value.group_id}
                      onChange={(_, values) => {
                        field.onChange(values || null)
                        setMyGroup(values)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("groups.title-view")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.resource_id}
                          helperText={GetError(errors.resource_id?.type)}
                        />
                      )}
                    />
                  )}
                />
              </div>
            </div>

            <div className="d-flex flex-row-reverse mt-1">
              <PrimaryButton
                onClick={() => {
                  onPost(getValues());
                }}
                variant="outlined"
                className="m-1"
                disabled={loadingPost}
              >
                {t("generic.buttons.accept")}
              </PrimaryButton>
              <ButtonStyled
                variant="contained"
                className="m-1"
                onClick={onCancel}
                disabled={loadingPost}
              >
                {t("generic.buttons.cancel")}
              </ButtonStyled>
            </div>
          </form>
        </Box>
      )}
      <SnackbarComponent />
    </>
  );
};
