import { Route, Routes } from "react-router-dom"
import { TableCompanyLocations } from "./table"


export const BrowserCompanyLocations = ({ ...props }) => {
  return (
    <Routes>
      <Route path={"/"} element={<TableCompanyLocations company_id={props.company_id} resourceScreen={props.resourceScreen}/>} />
    </Routes>
  )
}
