import { Control, Controller, FieldErrors, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { Customer } from "../../../interfaces/Sales/Catalogs/Customers/customers";
import { SalesOrder } from "../../../interfaces/Sales/Orders/salesOrders";
import { Company } from "../../../interfaces/Security/companies";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormsErrors } from "../../../hooks/Forms/FormsErrors";
import { AlertColor, Autocomplete, Box, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { myStylePickers } from "../../../theme/buttons";
import dayjs from "dayjs";
import { Project } from "../../../interfaces/Projects/projects";
import GenericPromises from "../../../api/GenericPromises";

interface SalesOrderAddHeaderProps {
  control: Control<SalesOrder, any>,
  errors: FieldErrors<SalesOrder>,
  myConsecutive: string,
  company: Company | undefined,
  comboCustomers: Customer[] | undefined,
  onPost: (data: SalesOrder) => Promise<void>,
  getValues: UseFormGetValues<SalesOrder>,
  setValue: UseFormSetValue<SalesOrder>,
  isSelectedCustomerTextfield: boolean,
  setIsSelectedCustomerTextfield: Dispatch<SetStateAction<boolean>>,
  showSnackBar: (message: string, severity: AlertColor) => void,
}

export const SalesOrderAddHeader = ({
  control,
  errors,
  myConsecutive,
  company,
  comboCustomers,
  onPost,
  getValues,
  setValue,
  isSelectedCustomerTextfield,
  setIsSelectedCustomerTextfield,
  showSnackBar,
}: SalesOrderAddHeaderProps) => {
  const [t] = useTranslation("global");
  const { GetError } = FormsErrors();
  const { GenericGetResource } = GenericPromises();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [comboProjects, setComboProjects] = useState<Project[]>([]);

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  }

  useEffect(() => {
    GenericGetResource(`/projects`)
      .then((responseProjects) => {
        setComboProjects(responseProjects.data.data);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      })
  }, []);

  return (
    <>
      <div className='d-flex'>
        {/* Left Box */}
        <Box className='custom-card-view w-25' sx={{ marginRight: 2 }}>
          <div>
            <Typography>{company?.business_name}</Typography>
            <Typography>{company?.rfc}</Typography>
            <Typography>{company?.phone_number}</Typography>
            <Typography>{company?.street}, {company?.city_name}, {company?.state_name}, {company?.country_name}</Typography>
          </div>
          <div>
            <Controller
              name="customer_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  ref={field.ref}
                  size="small"
                  sx={{ width: "100%" }}
                  options={comboCustomers ?? []}
                  getOptionLabel={(option: Customer) => `${(option.customer_identifier) ? option.customer_identifier : "XXX"} - ${(option.comercial_name) ? option.comercial_name : option.business_name}`}
                  renderOption={(props, option: Customer) => (
                    <div key={option.customer_id}>
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {(option.customer_identifier) ? option.customer_identifier : "XXX"} - {(option.comercial_name) ? option.comercial_name : option.business_name}
                      </Box>
                    </div>
                  )}
                  isOptionEqualToValue={(option, value) => option.customer_id === value.customer_id}
                  onChange={async (_, values) => {
                    field.onChange(values?.customer_id || null);
                    if (values?.customer_id) {
                      setValue("customer_id", values?.customer_id);
                      await setIsSelectedCustomerTextfield(true);
                      onPost(getValues());
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${t("customers.title-view")}`}
                      variant="filled"
                      value={field.value}
                      error={!!errors.customer_id}
                      helperText={GetError(errors.customer_id?.type)}
                    />
                  )}
                  disabled={isSelectedCustomerTextfield}
                />
              )}
            />
          </div>
        </Box>
        {/* Right Box*/}
        <div className=' d-flex flex-column w-75'>
          {/* First row */}
          <div className='d-flex w-100'>

            <div className='w-50'>
              <Controller
                name="sales_order_name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    variant="filled"
                    label={`${t("salesorders.fields.sales_order_name")}`}
                    ref={field.ref}
                    value={myConsecutive}
                    onChange={(event) => { field.onChange(event.target.value) }}
                    error={!!errors.sales_order_name}
                    helperText={GetError(errors.sales_order_name?.type)}
                    size="small"
                    sx={{ width: "100%", paddingRight: 2 }}
                    InputProps={{ readOnly: true, disableUnderline: true }}
                  />
                )}
              />
            </div>

            <div className='w-50'>
              <Controller
                name="sales_order_date"
                control={control}
                rules={{}}
                render={({ field }) =>
                  <DatePicker
                    open={openDatePicker}
                    onClose={() => setOpenDatePicker(false)}
                    ref={field.ref}
                    label={`${t("salesorders.fields.sales_order_date")}`}
                    onChange={field.onChange}
                    slotProps={{
                      popper: {
                        sx: myStylePickers
                      },
                      textField: {
                        variant: "filled",
                        size: "small",
                        fullWidth: true,
                        onClick: () => setOpenDatePicker(true),
                        onBeforeInput: disableKeyboardEntry,
                        error: !!errors.sales_order_date,
                        helperText: GetError(errors.sales_order_date?.type),
                      }
                    }}
                    defaultValue={dayjs()}
                    format="DD/MM/YYYY"
                    disableFuture
                    disabled={isSelectedCustomerTextfield}
                  />
                }
              />
            </div>

          </div>
          {/* Second Row */}
          <div className='d-flex w-100 mt-2'>
            <div className="w-50">
              <Controller
                name="project_id"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    ref={field.ref}
                    size="small"
                    sx={{ width: "100%", paddingRight: 2 }}
                    options={comboProjects}
                    getOptionLabel={(option) => `${option.project_name}`}
                    // defaultValue={defaultProject}
                    renderOption={(props, option: Project) => (
                      <div key={option.project_id}>
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.project_name}
                        </Box>
                      </div>
                    )}
                    isOptionEqualToValue={(option, value) => option.project_id === value.project_id}
                    onChange={(_, values) => {
                      field.onChange(values?.project_id || null)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${t("projects.title-view")}`}
                        variant="filled"
                        value={field.value}
                        error={!!errors.project_id}
                        helperText={GetError(errors.project_id?.type)}
                      />
                    )}
                  />
                )}
              />
            </div>
            <div className='w-50'>
              <Controller
                name="purchase_order"
                control={control}
                rules={{}}
                render={({ field }) => (
                  <TextField
                    variant="filled"
                    label={`${t("salesorders.fields.purchase_order")}`}
                    ref={field.ref}
                    value={field.value}
                    onChange={(event) => { field.onChange(event.target.value) }}
                    error={!!errors.purchase_order}
                    helperText={GetError(errors.purchase_order?.type)}
                    size="small"
                    sx={{ width: "100%" }}
                    disabled={isSelectedCustomerTextfield}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

