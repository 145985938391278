import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TabsProjectTaskDashboard from "./tabsDetails";
import { TableProjectTaskAccordions } from "./ProjectTaskAccordions/table";
import { TableProjectTaskList } from "./ProjectTaskList/table";
import { defaultTheme } from "../../../theme/theme";
import { useColors } from "../../../theme/hooks/useColors";
import GenericPromises from "../../../api/GenericPromises";
import { usePermissions } from "../../../hooks/usePermissions";
import { Menuitem } from "../../../interfaces/Security/menu";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { Spinner } from "../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../components/Header";
import { ProjectTaskDashboard } from "../../../interfaces/Projects/projectTaskDashboard";
import { AuthContext } from "../../../context/AuthContext";
import { TaskState } from "../../../interfaces/Projects/Catalogs/taskStates";
import { Project } from "../../../interfaces/Projects/projects";
import { ProjectStage } from "../../../interfaces/Projects/Catalogs/projectStages";
import { Parameter } from "../../../interfaces/Commons/parameters";

export const ViewProjectTaskDashboard = () => {
  const [t] = useTranslation("global");
  const { GenericGetResource } = GenericPromises();
  const { GetResourceByUrl } = usePermissions();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [projectTasksLocal, setProjectTasksLocal] = useState<ProjectTaskDashboard[]>([]);
  const [dataloaded, setDataLoaded] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [color, setColor] = useState<number[]>();
  const { HexToRGB } = useColors();
  const { user } = useContext(AuthContext);
  const [projectGroupId, setProjectGroupId] = useState<number>();
  const [taskStates, setTaskStates] = useState<TaskState[]>([]);
  const [projectStages, setProjectStages] = useState<ProjectStage[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [parameters, setParameters] = useState<Parameter[]>([]);
  const [pendingState, setPendingState] = useState<TaskState>()
  const [doneState, setDoneState] = useState<TaskState>();


  const handleUpdateTask = (updateTask: ProjectTaskDashboard) => {
    if (projectTasksLocal.length === 0) {
      return;
    }
    setProjectTasksLocal((prevTasks) => {
      const taskToUpdateIndex = projectTasksLocal.findIndex(e => e.project_task_id === updateTask.project_task_id);
      return prevTasks.map((task, index) =>
        index === taskToUpdateIndex ? updateTask : task,
      );
    });
  };

  useEffect(() => {
    GenericGetResource(`/projecttaskdashboard/bygroupid/${user?.group_id}`)
      .then(async (response) => {
        setProjectTasksLocal(response.data.data);
        if (user?.group_id) {
          setProjectGroupId(user?.group_id)
        }
        setColor(HexToRGB(defaultTheme.palette.primary.main));
        GetResourceByUrl(`/projecttaskdashboard`)
          .then((response1) => {
            setResourceScreen((prev) => response1);
            setDataLoaded(true);
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
          });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });

    GenericGetResource(`/taskstates`)
      .then(async (response) => {
        setTaskStates(response.data.data);
        setPendingState(response.data.data.find(
          (state: TaskState) =>
            state.task_state_name == "PENDING"
        ));
        setDoneState(response.data.data.find(
          (state: TaskState) =>
            state.task_state_name == "DONE"
        ));
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });

    GenericGetResource(`/projectstages`)
      .then(async (response) => {
        setProjectStages(response.data.data)
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      })

    GenericGetResource(`/projects`)
      .then(async (response) => {
        setProjects(response.data.data)
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      })

    GenericGetResource("/parameters")
      .then(async (response) => {
        setParameters(response.data.data)
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      })
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded && resourceScreen?.read && (
        <>
          <Header title={t("projecttaskdashboard.title")} />
          <div className="my-4" />
          <TabsProjectTaskDashboard
            assignedToMe={
              <TableProjectTaskList
                color={color}
                isEdit={!resourceScreen.update}
                projectGroupId={projectGroupId}
                handleUpdateTask={handleUpdateTask}
                taskStatesLocal={taskStates}
                projectStagesLocal={projectStages}
                projectsLocal={projects}
                parametersLocal={parameters}
                pendingState={pendingState}
                doneState={doneState}
              />
            }
            planned={
              <TableProjectTaskAccordions
                projectTasksLocal={projectTasksLocal}
                color={color}
                isEdit={!resourceScreen.update}
                taskStatesLocal={taskStates}
                projectStagesLocal={projectStages}
                projectsLocal={projects}
                parametersLocal={parameters}
                handleUpdateTask={handleUpdateTask}
                pendingState={pendingState}
                doneState={doneState}
              />
            }
          />
        </>
      )}
      <SnackbarComponent />
    </>
  );
};
