import { Route, Routes, useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { HomeComponent } from './home'
import { Spinner } from '../../components/Commons/Spinner/Spinner'
import GenericPromises from '../../api/GenericPromises'
import { AuthContext } from '../../context/AuthContext'
import { UserCompanies } from '../../interfaces/Security/userCompanies'
import { usePermissions } from '../../hooks/usePermissions'

export const Home = () => {
    const { GetResourceByUrl } = usePermissions();
    const navigate = useNavigate();
    const { GenericGetResourceGeneric } = GenericPromises();
    const [dataloaded, setdataloaded] = useState(false);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        GenericGetResourceGeneric(`/usercompanies/byuserid/${user?.user_id}`, "/gusercompanies")
            .then(async (response) => {
                if (response.data && response.data.data && response.data.data.length) {
                    let myCustomerDefault = response.data.data.find((element: UserCompanies) => element.schema_name === user?.schema)
                    if (myCustomerDefault && myCustomerDefault.resource_route) {
                        await GetResourceByUrl(myCustomerDefault.resource_route).then((responsePermission) => {
                            if (responsePermission && responsePermission.read === true) {
                                navigate(myCustomerDefault.resource_route, { replace: true })
                            }
                        })
                    }
                }
                setdataloaded(true)
            })
        // GetResourceByUrl(`/dashboard`)
        //     .then((responsePermission) => {
        //         if (responsePermission) {
        //             navigate(`/dashboard`, { replace: true })
        //         }
        //         else {
        //             setdataloaded(true)
        //         }
        //     })
    }, [])

    return (<>
        {!dataloaded && <Spinner />
        }
        {
            dataloaded &&
            <Routes>
                <Route path={"/"} element={<HomeComponent />} />
            </Routes>
        }
    </>
    )
}