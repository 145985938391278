import { Control, Controller, FieldErrors, UseFormGetValues, UseFormSetValue } from "react-hook-form"
import { SalesPreorder } from "../../../interfaces/Sales/Preorders/SalesPreorders"
import { useTranslation } from "react-i18next";
import { FormsErrors } from "../../../hooks/Forms/FormsErrors";
import { Dispatch, SetStateAction, useState } from "react";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { Company } from "../../../interfaces/Security/companies";
import { Customer } from "../../../interfaces/Sales/Catalogs/Customers/customers";
import { DatePicker } from "@mui/x-date-pickers";
import { myStylePickers } from "../../../theme/buttons";
import dayjs from "dayjs";

interface SalesPreOrderAddHeaderProps {
  control: Control<SalesPreorder, any>,
  errors: FieldErrors<SalesPreorder>,
  myConsecutive: string,
  company: Company | undefined,
  comboCustomers: Customer[] | undefined,
  onPost: (data: SalesPreorder) => Promise<void>,
  getValues: UseFormGetValues<SalesPreorder>,
  setValue: UseFormSetValue<SalesPreorder>,
  isSelectedCustomerTextfield: boolean,
  setIsSelectedCustomerTextfield: Dispatch<SetStateAction<boolean>>,
}

export const SalesPreOrderAddHeader = ({
  control,
  errors,
  myConsecutive,
  company,
  comboCustomers,
  onPost,
  getValues,
  setValue,
  isSelectedCustomerTextfield,
  setIsSelectedCustomerTextfield,
}: SalesPreOrderAddHeaderProps) => {
  const [t] = useTranslation("global");
  const { GetError } = FormsErrors();
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  }

  return (
    <>
      <div className='d-flex'>
        {/* Left Box */}
        <Box className='custom-card-view w-25' sx={{ marginRight: 2 }}>
          <div>
            <Typography>{company?.business_name}</Typography>
            <Typography>{company?.rfc}</Typography>
            <Typography>{company?.phone_number}</Typography>
            <Typography>{company?.street}, {company?.city_name}, {company?.state_name}, {company?.country_name}</Typography>
          </div>
          <div>
            <Controller
              name="customer_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  ref={field.ref}
                  size="small"
                  sx={{ width: "100%" }}
                  options={comboCustomers ?? []}
                  getOptionLabel={(option: Customer) => `${(option.customer_identifier) ? option.customer_identifier : "XXX"} - ${(option.comercial_name) ? option.comercial_name : option.business_name}`}
                  renderOption={(props, option: Customer) => (
                    <div key={option.customer_id}>
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {(option.customer_identifier) ? option.customer_identifier : "XXX"} - {(option.comercial_name) ? option.comercial_name : option.business_name}
                      </Box>
                    </div>
                  )}
                  isOptionEqualToValue={(option, value) => option.customer_id === value.customer_id}
                  onChange={async (_, values) => {
                    field.onChange(values?.customer_id || null);
                    if (values?.customer_id) {
                      setValue("customer_id", values?.customer_id);
                      await setIsSelectedCustomerTextfield(true);
                      onPost(getValues());
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${t("customers.title-view")}`}
                      variant="filled"
                      value={field.value}
                      error={!!errors.customer_id}
                      helperText={GetError(errors.customer_id?.type)}
                    />
                  )}
                  disabled={isSelectedCustomerTextfield}
                />
              )}
            />
          </div>
        </Box>
        {/* Right Box*/}
        <div className=' d-flex flex-column w-75'>
          {/* First row */}
          <div className='d-flex w-100'>

            <div className='w-50'>
              <Controller
                name="sales_preorder_name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    variant="filled"
                    label={`${t("salespreorders.fields.sales_preorder_name")}`}
                    ref={field.ref}
                    value={myConsecutive}
                    onChange={(event) => { field.onChange(event.target.value) }}
                    error={!!errors.sales_preorder_name}
                    helperText={GetError(errors.sales_preorder_name?.type)}
                    size="small"
                    sx={{ width: "100%", paddingRight: 2 }}
                    InputProps={{ readOnly: true, disableUnderline: true }}
                  />
                )}
              />
            </div>

            <div className='w-50'>
              <Controller
                name="sales_preorder_date"
                control={control}
                rules={{}}
                render={({ field }) =>
                  <DatePicker
                    open={openDatePicker}
                    onClose={() => setOpenDatePicker(false)}
                    ref={field.ref}
                    label={`${t("salespreorders.fields.sales_preorder_date")}`}
                    onChange={field.onChange}
                    slotProps={{
                      popper: {
                        sx: myStylePickers
                      },
                      textField: {
                        variant: "filled",
                        size: "small",
                        fullWidth: true,
                        onClick: () => setOpenDatePicker(true),
                        onBeforeInput: disableKeyboardEntry,
                        error: !!errors.sales_preorder_date,
                        helperText: GetError(errors.sales_preorder_date?.type),
                      }
                    }}
                    defaultValue={dayjs()}
                    format="DD/MM/YYYY"
                    disableFuture
                    disabled={isSelectedCustomerTextfield}
                  />
                }
              />
            </div>

          </div>
          {/* Second Row */}
          <div className='d-flex w-100 mt-2'>
            <div className='w-50'>
              <Controller
                name="purchase_order"
                control={control}
                rules={{}}
                render={({ field }) => (
                  <TextField
                    variant="filled"
                    label={`${t("salespreorders.fields.purchase_order")}`}
                    ref={field.ref}
                    value={field.value}
                    onChange={(event) => { field.onChange(event.target.value) }}
                    error={!!errors.purchase_order}
                    helperText={GetError(errors.purchase_order?.type)}
                    size="small"
                    sx={{ width: "100%", paddingRight: 2 }}
                    disabled={isSelectedCustomerTextfield}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
