import { AccordionDetails, AccordionSlots, AccordionSummary, Fade, Typography, Accordion } from "@mui/material";
import React, { useState } from "react";
import { TaskCard } from "./taskCard";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useTranslation } from "react-i18next";
import { ProjectTaskDashboard } from "../../../../interfaces/Projects/projectTaskDashboard";
import { TaskState } from "../../../../interfaces/Projects/Catalogs/taskStates";
import { ProjectStage } from "../../../../interfaces/Projects/Catalogs/projectStages";
import { Project } from "../../../../interfaces/Projects/projects";
import { Parameter } from "../../../../interfaces/Commons/parameters";

type TaskAccordionProps = {
  title: string;
  tasks: ProjectTaskDashboard[];
  color: number[] | undefined;
  isEdit: boolean;
  expanded: boolean,
  setExpandedAccordion: React.Dispatch<React.SetStateAction<string | false>>,
  identifier: string
  taskStatesLocal: TaskState[];
  projectStagesLocal: ProjectStage[];
  projectsLocal: Project[];
  parametersLocal: Parameter[];
  handleUpdateTask: (updateTask: ProjectTaskDashboard) => void
  pendingState: TaskState | undefined;
  doneState: TaskState | undefined;
};

export const TaskAccordion = ({
  title,
  tasks,
  color,
  isEdit,
  expanded,
  setExpandedAccordion,
  identifier,
  taskStatesLocal,
  projectStagesLocal,
  projectsLocal,
  parametersLocal,
  handleUpdateTask,
  pendingState,
  doneState
}: TaskAccordionProps) => {

  const [t] = useTranslation("global");

  const handleExpansion = () => {
    setExpandedAccordion(expanded ? false : identifier);
  };

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={handleExpansion}
        slots={{ transition: Fade as AccordionSlots['transition'] }}
        slotProps={{ transition: { timeout: 400 } }}
        sx={[
          {
            boxShadow: 'none',
            border: 'none',
            borderBottom: '1px solid #e0e0e0',
          },
          expanded
            ? {
              '& .MuiAccordion-region': {
                height: 'auto',
              },
              '& .MuiAccordionDetails-root': {
                display: 'block',
              },
            }
            : {
              '& .MuiAccordion-region': {
                height: 0,
              },
              '& .MuiAccordionDetails-root': {
                display: 'none',
              },
            },
        ]}

      >
        <AccordionSummary

          expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: 16 }} />}
          sx={{
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
              transform: 'rotate(90deg)',
            },
            gap: 2
          }}
        >
          <Typography fontWeight="bold">{title}</Typography>
          <Typography marginLeft={1}>{tasks.length}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {tasks.length > 0 ? (
            tasks.map((task) => (
              <TaskCard
                key={task.project_task_id}
                task={task}
                color={color}
                isEdit={isEdit}
                taskStatesLocal={taskStatesLocal}
                projectStagesLocal={projectStagesLocal}
                projectsLocal={projectsLocal}
                parametersLocal={parametersLocal}
                handleUpdateTask={handleUpdateTask}
                pendingState={pendingState}
                doneState={doneState}
              />
            ))
          ) : (
            <Typography>{t('projecttaskdashboard.messages.no-tasks')}</Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
