import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import GenericPromises from '../../../../api/GenericPromises';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import { ButtonStyled, PrimaryButton } from '../../../../theme/buttons';
import { useTranslation } from 'react-i18next';
import { UserCompanies } from '../../../../interfaces/Security/userCompanies';
import { Schema } from '../../../../interfaces/Security/schemas';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { Customer } from '../../../../interfaces/Sales/Catalogs/Customers/customers';
import { Resource } from '../../../../interfaces/Security/resources';
import { OptionMenu } from '../../../../interfaces/Security/optionsMenu';
import { Menuitem } from '../../../../interfaces/Security/menu';
import ResourceEntities from '../../../../components/Security/AccordionMenu/resourceEntities';


export const AddUserCompanies = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericPostResource, GenericGetResource, GenericGet } = GenericPromises();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comboSchemas, setComboSchemas] = useState<Schema[]>([]);
  const [comboCustomers, setComboCustomers] = useState<Customer[]>([]);
  const [customerValue, setCustomerValue] = useState<Customer | null>(null);
  const [comboMenuItem, setComboMenuItem] = useState<Menuitem[]>([])
  const [menuItemValue, setMenuItemValue] = useState<Menuitem | null>()
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const GetResource = ResourceEntities();

  let prefixFilter = 'Enty'

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<UserCompanies>({
    defaultValues: {
      schema_id: undefined,
      customer_default_id: undefined,
      option_menu_id_default: undefined
    }
  });
  const onSubmit: SubmitHandler<UserCompanies> = (data) => onPost(data);

  const onPost = (data: UserCompanies) => {
    setLoadingPost(true);
    let myData = {
      user_id: props.user.user_id,
      schema_id: data.schema_id,
      customer_default_id: data.customer_default_id ?? null,
      option_menu_id_default: data.option_menu_id_default ?? null,
    }
    GenericPostResource("/usercompanies/byschema", myData)
      .then((response) => {
        let schema = comboSchemas.find((element) => element.schema_id === response.data.schema_id);
        let myCustomer = comboCustomers.find((element) => element.customer_id === response.data.customer_default_id)
        let myNewData: UserCompanies = {
          user_company_id: response.data.user_company_id,
          user_id: response.data.user_id,
          company_id: response.data.company_id,
          business_name: schema?.schema_description,
          schema_id: response.data.schema_id,
          is_default_schema: false,
          schema_name: schema?.schema_name,
          customer_default_id: response.data.customer_default_id,
          option_menu_id_default: response.data.option_menu_id_default,
          customer_identifier: myCustomer?.customer_identifier,
          customer_business_name: myCustomer?.business_name,
          customer_comercial_name: myCustomer?.comercial_name,
          customer_rfc: myCustomer?.customer_rfc,
        };
        props.AddNewRow(myNewData);
        props.setOpenDialog(false);
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        if (error.message === 'Network Error') showSnackBar(t("userCompanies.fields.duplicate"), "error");
        else showSnackBar(error.message, "error");
      });
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    props.setOpenDialog(false);
  }

  const changeSchema = (schemaId: number | undefined) => {
    setComboCustomers([]);
    if (schemaId) {
      let myPromises = [
        GenericGetResource(`/customers/byschema/${schemaId}`),
      ];
      Promise.all(myPromises)
        .then((responses) => {
          setComboCustomers(responses[0].data.data);
        })
        .catch((error) => {
          setLoadingPost(false);
          showSnackBar(error.message, "error");
        });
    }
  }

  const filterEntyMenus = (menus: Menuitem[]): Menuitem[] => {
    const result: Menuitem[] = [];

    const recursiveEntyMenus = (row: Menuitem) => {
      if (row.prefix && row.prefix === prefixFilter) {
        result.push(row);
      }
      row.children?.forEach((child) => {
        recursiveEntyMenus(child);
      });
    };

    menus.forEach((menu) => recursiveEntyMenus(menu));

    return result;
  };

  useEffect(() => {
    let myPromises = [
      GenericGetResource("/schemas"),
      GenericGet(`/menu/${props.user.group_id}`)
    ];

    Promise.all(myPromises)
      .then((responses) => {
        var mySchemasFiltered = responses[0].data.data.filter((responseItem: Schema) => {
          // Verificamos si el schema_name actual no está en usersCompaniesData
          return !props.usersCompaniesData.some((companyItem: UserCompanies) => companyItem.schema_name === responseItem.schema_name);
        });
        setComboSchemas(mySchemasFiltered);
        let filteredOptionMenu = filterEntyMenus(responses[1].data.children);
        setComboMenuItem(filteredOptionMenu);
        setDataLoaded(true);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(error.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataLoaded && <Spinner isBox={false} />}
      {dataLoaded &&
        <Box className='d-flex justify-content-center w-100' sx={{ minWidth: 1000 }}>

          <form onSubmit={handleSubmit(onSubmit)} className='mt-4 w-100'>

            <div className='w-100'>
              <div className='w-100 d-flex'>
                <div className='w-50'>
                  <Controller
                    name="schema_id"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) =>
                      <Autocomplete
                        ref={field.ref}
                        size="small"
                        disabled={comboSchemas.length <= 0}
                        options={comboSchemas}
                        getOptionLabel={(option) => `${option.schema_description} - ${option.schema_name}`}
                        isOptionEqualToValue={(option, value) => option.schema_id === value.schema_id}
                        onChange={(_, values) => {
                          field.onChange(values?.schema_id || null);
                          setValue("customer_default_id", undefined);
                          setCustomerValue(null);
                          changeSchema(values?.schema_id);
                        }}
                        renderOption={(props, option: Schema) => (
                          <div key={option.schema_id}>
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                              {option.schema_description} - {option.schema_name}
                            </Box>
                          </div>
                        )}
                        sx={{ paddingRight: 2 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${t("userCompanies.title-view")}`}
                            variant="filled"
                            value={field.value}
                            error={!!errors.schema_id}
                            helperText={GetError(errors.schema_id?.type)}
                          />
                        )}
                      />
                    }
                  />
                </div>
                <div className='w-50'>
                  <Controller
                    name="customer_default_id"
                    control={control}
                    rules={{}}
                    render={({ field }) =>
                      <Autocomplete
                        ref={field.ref}
                        size="small"
                        options={comboCustomers}
                        disabled={comboCustomers.length <= 0}
                        value={customerValue}
                        getOptionLabel={(option) => `${(option.customer_identifier) ? option.customer_identifier : "XXX"} - ${(option.comercial_name) ? option.comercial_name : option.business_name}`}
                        onChange={(_, values) => {
                          field.onChange(values?.customer_id || null)
                          setCustomerValue(values);
                        }}
                        renderOption={(props, option: Customer) => (
                          <div key={option.customer_id}>
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                              {(option.customer_identifier) ? option.customer_identifier : "XXX"} - {(option.comercial_name) ? option.comercial_name : option.business_name}
                            </Box>
                          </div>
                        )}
                        isOptionEqualToValue={(option, value) => option.customer_id === value.customer_id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${t("customers.title-view")}`}
                            variant="filled"
                            value={field.value}
                            error={!!errors.customer_default_id}
                            helperText={GetError(errors.customer_default_id?.type)}
                          />
                        )}
                      />
                    }
                  />
                </div>
              </div>
              <div className='d-flex flex-row mt-3 w-100'>
                <div className='w-50'>
                  <Controller
                    name="option_menu_id_default"
                    control={control}
                    render={({ field }) =>
                      <Autocomplete
                        ref={field.ref}
                        size="small"
                        options={comboMenuItem}
                        disabled={comboMenuItem.length <= 0}
                        value={menuItemValue}
                        sx={{ paddingRight: 2 }}
                        getOptionLabel={(option) => `${(option.description) ? option.description : option.option_name}`}
                        onChange={(_, values) => {
                          field.onChange(values?.option_menu_id || null)
                          setMenuItemValue(values);
                        }}
                        renderOption={(props, option: Menuitem) => (
                          <div key={option.option_menu_id}>
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                              <div className='d-flex flex-row'>
                                <div className='pe-3'>
                                  {GetResource(option.resource_name ?? option.option_name)?.icon}
                                </div>
                                <div>
                                  {(option.description) ? option.description : option.option_name}
                                </div>
                              </div>
                            </Box>
                          </div>
                        )}
                        isOptionEqualToValue={(option, value) => option.option_menu_id === value.option_menu_id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${t("optionsmenu.title-view")}`}
                            variant="filled"
                            value={field.value}
                            error={!!errors.option_menu_id_default}
                            helperText={GetError(errors.option_menu_id_default?.type)}
                          />
                        )}
                      />
                    }
                  />
                </div>
              </div>
            </div>

            <div className='d-flex flex-row-reverse mt-1'>
              <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
              <ButtonStyled variant='contained' className='m-1' onClick={onCancel} disabled={loadingPost}>{t("generic.buttons.cancel")}</ButtonStyled>
            </div>

          </form>

        </Box>
      }
      <SnackbarComponent />
    </>
  )
}
