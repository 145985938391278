import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import GenericPromises from '../../../../api/GenericPromises';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Autocomplete, Box, TextField } from '@mui/material';
import { ButtonLoading, ButtonStyled, PrimaryButton } from '../../../../theme/buttons';
import { UserCompanies } from '../../../../interfaces/Security/userCompanies';
import { GenericDialog } from '../../../../components/Dialogs/Dialog';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { AuthContext } from '../../../../context/AuthContext';
import { Customer } from '../../../../interfaces/Sales/Catalogs/Customers/customers';
import { Menuitem } from '../../../../interfaces/Security/menu';
import ResourceEntities from '../../../../components/Security/AccordionMenu/resourceEntities';

export const UpdateUserCompanies = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericDeleteResource, GenericGetResource, GenericPutResource, GenericGet } = GenericPromises();
  const { user, logOut } = React.useContext(AuthContext);
  const { GetError } = FormsErrors();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogAcept, setopenDialogAcept] = useState(false);
  const [messageSnack, setMessageSnack] = useState("");
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [comboCustomers, setComboCustomers] = useState<Customer[]>([]);
  const [defaultCustomer, setDefaultCustomer] = useState<Customer>();
  const [comboMenuItem, setComboMenuItem] = useState<Menuitem[]>([])
  const [defaultMenuItem, setDefaultMenuItem] = useState<Menuitem>()
  const [loadingPost, setLoadingPost] = useState(false);
  const [isLoading, setisLoading] = useState(false)
  const { userCompaniesPayload, permissions } = props;
  const GetResource = ResourceEntities();
  const isEdit = !permissions.update;

  let prefixFilter = 'Enty'

  const {
    handleSubmit,
    getValues,
    trigger,
    control,
    reset,
    formState: { errors },
  } = useForm<UserCompanies>({
    defaultValues: {
      company_id: undefined,
      business_name: "",
      customer_default_id: undefined,
      option_menu_id_default: undefined
    }
  });

  const onSubmit: SubmitHandler<UserCompanies> = (data) => onPut(data);

  const onPut = (data: UserCompanies) => {
    setLoadingPost(true);
    trigger().then(async (triggerData) => {
      if (triggerData) {
        let myData = {
          customer_default_id: data.customer_default_id ?? null,
          option_menu_id_default: data.option_menu_id_default ?? null
        }
        GenericPutResource(`/usercompanies/${userCompaniesPayload.user_company_id}/Schema/${userCompaniesPayload.schema_id}`, myData)
          .then((response) => {
            let myCustomer = comboCustomers.find((element) => element.customer_id === data.customer_default_id)
            let myNewRow = {
              ...response.data,
              customer_default_id: data.customer_default_id,
              customer_identifier: myCustomer?.customer_identifier,
              customer_business_name: myCustomer?.business_name,
              customer_comercial_name: myCustomer?.comercial_name,
              customer_rfc: myCustomer?.customer_rfc,
              option_menu_id_default : data.option_menu_id_default
            }
            setLoadingPost(false);
            props.UpdateRow(myNewRow);
            props.setOpenDialog(false);
          })
          .catch((error) => {
            setLoadingPost(false);
            showSnackBar(error.message, 'error');
          });
      }
      else {
        setLoadingPost(false);
      }
    })
  }

  const onDelete = (isAccepted: boolean = false) => {
    setopenDialogAcept(false);
    setOpenDialog(false);
    setisLoading(true);
    if (userCompaniesPayload.user_id === user?.user_id &&
      localStorage.getItem("schemaName")?.toLocaleUpperCase() === userCompaniesPayload.schema_name &&
      !isAccepted) {
      setisLoading(false);
      setopenDialogAcept(true)
      return;
    }
    GenericDeleteResource(`/usercompanies/${userCompaniesPayload.user_company_id}/byschema/${userCompaniesPayload.schema_id}`)
      .then(() => {
        setisLoading(false);
        props.DeleteRow(userCompaniesPayload?.user_company_id);
        props.setOpenDialog(false);
        if (isAccepted) {
          logOut();
        }
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setMessageSnack(error.message);
        setOpenDialog(false);
        setisLoading(false);
      })
  }

  const onCancel = () => {
    // validacion si quiere cancelar sus cambios 
    props.setOpenDialog(false);
  }

  const filterEntyMenus = (menus: Menuitem[]): Menuitem[] => {
    const result: Menuitem[] = [];

    const recursiveEntyMenus = (row: Menuitem) => {
      if (row.prefix && row.prefix === prefixFilter) {
        result.push(row);
      }
      row.children?.forEach((child) => {
        recursiveEntyMenus(child);
      });
    };

    menus.forEach((menu) => recursiveEntyMenus(menu));

    return result;
  };

  useEffect(() => {
    let myPromises = [
      GenericGetResource(`/customers/byschema/${userCompaniesPayload.schema_id}`),
      GenericGet(`/menu/${props.user.group_id}`)
    ];
    Promise.all(myPromises)
      .then((responses) => {
        setComboCustomers(responses[0].data.data);
        setDefaultCustomer(responses[0].data.data.find((element: Customer) => element.customer_id === userCompaniesPayload.customer_default_id))
        reset({
          business_name: userCompaniesPayload.business_name ?? "",
          customer_default_id: userCompaniesPayload.customer_default_id,
          option_menu_id_default: userCompaniesPayload.option_menu_id_default,
        })
        let filteredOptionMenu = filterEntyMenus(responses[1].data.children);
        setDefaultMenuItem(filteredOptionMenu.find((element: Menuitem) => element.option_menu_id === userCompaniesPayload.option_menu_id_default))
        setComboMenuItem(filteredOptionMenu);
        setDataLoaded(true)
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  return (
    <>
      {dataLoaded &&
        <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>

          <form className='mt-4 w-100'>

            <div className='d-flex'>
              <div className='w-100'>
                <div className='w-100 d-flex'>
                  <div className='w-50'>
                    <Controller
                      name="business_name"
                      control={control}
                      rules={{ required: true, maxLength: 60 }}
                      render={({ field }) =>
                        <TextField
                          variant="filled"
                          label={`${t("userCompanies.title-view")}`}
                          ref={field.ref}
                          value={field.value}
                          onChange={(event) => { field.onChange(event.target.value) }}
                          error={!!errors.business_name}
                          helperText={GetError(errors.business_name?.type)}
                          size="small"
                          style={{ width: "100%" }}
                          disabled={true}
                          sx={{ paddingRight: 2 }}
                        />
                      }
                    />
                  </div>
                  <div className='w-50'>
                    <Controller
                      name="customer_default_id"
                      control={control}
                      rules={{}}
                      render={({ field }) =>
                        <Autocomplete
                          ref={field.ref}
                          size="small"
                          disabled={(comboCustomers.length <= 0 || isEdit)}
                          options={comboCustomers}
                          defaultValue={defaultCustomer}
                          getOptionLabel={(option) => `${(option.customer_identifier) ? option.customer_identifier : "XXX"} - ${(option.comercial_name) ? option.comercial_name : option.business_name}`}
                          onChange={(_, values) => {
                            field.onChange(values?.customer_id || null)
                          }}
                          renderOption={(props, option: Customer) => (
                            <div key={option.customer_id}>
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {(option.customer_identifier) ? option.customer_identifier : "XXX"} - {(option.comercial_name) ? option.comercial_name : option.business_name}
                              </Box>
                            </div>
                          )}
                          isOptionEqualToValue={(option, value) => option.customer_id === value.customer_id}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${t("customers.title-view")}`}
                              variant="filled"
                              value={field.value}
                              error={!!errors.customer_default_id}
                              helperText={GetError(errors.customer_default_id?.type)}
                            />
                          )}
                        />
                      }
                    />
                  </div>
                </div>
                <div className='d-flex flex-row mt-3 w-100'>
                  <div className='w-50'>
                    <Controller
                      name="option_menu_id_default"
                      control={control}
                      render={({ field }) =>
                        <Autocomplete
                          ref={field.ref}
                          size="small"
                          options={comboMenuItem}
                          disabled={comboMenuItem.length <= 0}
                          defaultValue={defaultMenuItem}
                          sx={{ paddingRight: 2 }}
                          getOptionLabel={(option) => `${(option.description) ? option.description : option.option_name}`}
                          onChange={(_, values) => {
                            field.onChange(values?.option_menu_id || null)
                            setDefaultMenuItem(values ?? undefined);
                          }}
                          renderOption={(props, option: Menuitem) => (
                            <div key={option.option_menu_id}>
                              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <div className='d-flex flex-row'>
                                  <div className='pe-3'>
                                    {GetResource(option.resource_name ?? option.option_name)?.icon}
                                  </div>
                                  <div>
                                    {(option.description) ? option.description : option.option_name}
                                  </div>
                                </div>
                              </Box>
                            </div>
                          )}
                          isOptionEqualToValue={(option, value) => option.option_menu_id === value.option_menu_id}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${t("optionsmenu.title-view")}`}
                              variant="filled"
                              value={field.value}
                              error={!!errors.option_menu_id_default}
                              helperText={GetError(errors.option_menu_id_default?.type)}
                            />
                          )}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex mt-1'>
              <div className="w-25">
                {permissions.delete ?
                  <ButtonLoading isLoading={isLoading} onClick={() => setOpenDialog(true)}>{t("generic.buttons.delete")}</ButtonLoading> : <></>
                }
              </div>
              <div className="d-flex flex-row-reverse w-75">
                <PrimaryButton onClick={() => { onPut(getValues()) }} variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                <ButtonStyled variant='contained' className='m-1' onClick={onCancel} disabled={isLoading}>{t("generic.buttons.cancel")}</ButtonStyled>
              </div>
            </div>

          </form>

        </Box>
      }
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("userCompanies.fields.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={() => { onDelete() }}
      />
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("userCompanies.fields.acept_same_schema")}
        open={openDialogAcept}
        setOpen={setopenDialogAcept}
        onAcept={() => { onDelete(true) }}
      />
      <SnackbarComponent />
    </>
  )
}
