import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GenericPromises from '../../../../../api/GenericPromises';
import { GridColDef, GridRowsProp, GridTreeNodeWithRender, GridValueGetterParams } from '@mui/x-data-grid';
import { Menuitem } from '../../../../../interfaces/Security/menu';
import { usePermissions } from '../../../../../hooks/usePermissions';
import { CompanyLocation } from '../../../../../interfaces/Security/companies';
import { Spinner } from '../../../../../components/Commons/Spinner/Spinner';
import { Header } from '../../../../../components/Header';
import DataTable from '../../../../../components/Tables/GridTableMaterialUI/DataTable';
import { PrimaryButton } from '../../../../../theme/buttons';
import { AddCompanyLocation } from './add';
import { AuthContext } from '../../../../../context/AuthContext';
import { UpdateCompanyLocation } from './update';
import { DialogEntity } from '../../../../../components/Dialogs/DialogEntity';
import useSnackBar from '../../../../../components/Commons/SnackBar/useSnackBar';
import { useDates } from '../../../../../hooks/useDates';

export const TableCompanyLocations = ({ ...props }) => {
  const [t] = useTranslation("global");
  const { GenericGetResource } = GenericPromises();
  const { GetResourceByUrl } = usePermissions();
  const { user } = useContext(AuthContext);
  const { TimeConverter } = useDates();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unauthorized, setUnauthorized] = useState(true);
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [companyLocationsData, setCompanyLocationsData] = useState<GridRowsProp>([]);
  const [messageSnack, setMessageSnack] = useState("");
  const [myPreferences, setMyPreferences] = useState({});
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [companyLocationPayload, setCompanyLocationPayload] = useState<CompanyLocation>({});
  const [columns, setColumns] = useState<GridColDef<CompanyLocation>[]>([
    {
      field: 'Address', headerName: t("companylocations.fields.address"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return `${params.row.street} ${params.row.city_name} ${params.row.state_name} ${params.row.country_name}`
      }
    },
    {
      field: 'main_location', headerName: t("companylocations.fields.main_location"), headerClassName: 'header-grid-table', flex: 1,
      valueGetter(params) {
        return params.value ? t("generic.yes") : t("generic.no");
      },
    },
    {
      field: 'creation_date', headerName: t("generic.creation_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
    { field: 'last_update_user', headerName: t("generic.last_update_user"), headerClassName: 'header-grid-table', flex: 1 },
    {
      field: 'last_update_date', headerName: t("generic.last_update_date"), headerClassName: 'header-grid-table', type: "date", flex: 1,
      valueGetter(params) {
        return TimeConverter(params.value);
      },
    },
  ]);

  const loadPreferences = async () => {
    let myPreferences = await localStorage.getItem("grid-companylocations");
    if (myPreferences != null) {
      setMyPreferences(JSON.parse(myPreferences));
    }
  }

  const loadColumnsOrder = async () => {
    let myOrderColumns = await localStorage.getItem("grid-companylocations-columns");
    if (myOrderColumns != null) {
      let myJson = JSON.parse(myOrderColumns);
      for (let index = 0; index < myJson.length; index++) {
        const element = myJson[index];
        if (element['type'] == 'date') {
          element.headerName = t(`generic.${element.field}`);
          element.valueGetter = function (params: GridValueGetterParams<CompanyLocation, any, GridTreeNodeWithRender>) {
            return TimeConverter(params.value);
          };
        }
        else {
          if (element.field === "main_location") {
            element.valueGetter = function (params: GridValueGetterParams<CompanyLocation, any, GridTreeNodeWithRender>) {
              return params.value ? t("generic.yes") : t("generic.no");
            };
          }
          else if (element.field !== "last_update_user") {
            element.headerName = t(`companylocations.fields.${element.field}`);
          }
        }
      }
      setColumns(myJson);
    }
  }

  const handleAddRow = (newRow: CompanyLocation) => {
    let myRow = {
      ...newRow,
      creation_date: new Date(),
      last_update_user: user?.user_email,
      last_update_date: new Date()
    }
    setCompanyLocationsData((prevState) => [...companyLocationsData, myRow]);
  };

  const handleUpdateRow = (updateRow: CompanyLocation) => {
    if (companyLocationsData.length === 0) {
      return;
    }
    setCompanyLocationsData((prevRows) => {
      const rowToUpdateIndex = companyLocationsData.findIndex(e => e.company_location_id === updateRow.company_location_id);

      return prevRows.map((row, index) =>
        index === rowToUpdateIndex ? updateRow : row,
      );
    });
  };

  const handleDeleteRow = (deleteRow: number) => {
    if (companyLocationsData.length === 0) {
      return;
    }
    setCompanyLocationsData((prevRows) => {
      const rowToDeleteIndex = companyLocationsData.findIndex(e => e.company_location_id === deleteRow);
      return [
        ...companyLocationsData.slice(0, rowToDeleteIndex),
        ...companyLocationsData.slice(rowToDeleteIndex + 1),
      ];
    });
  };

  useEffect(() => {
    GenericGetResource("/companylocations")
      .then(
        (response) => {
          setCompanyLocationsData(response.data.data);
          loadColumnsOrder();
          loadPreferences();
          setDataLoaded(true);
        }
      ).catch((error) => {
        showSnackBar(error.message, "error");
        setMessageSnack(error.message);
        setUnauthorized(false);
      });
  }, []);

  return (
    <div className='d-flex w-100 justify-content-center'>
      {!unauthorized && <div className='screen-container d-flex flex-column justify-content-center align-items-center'> <img alt='ERROR' style={{ height: "20rem", width: "20rem" }} src={require("../../../../../assets/img/error.jpeg")} /> <h2>{messageSnack}</h2> </div>}
      {unauthorized && !dataLoaded && <Spinner />}
      {dataLoaded && props.resourceScreen?.read && <div className='screen-container w-75'>
        {props.resourceScreen?.create === true &&
          <div className='d-flex flex-row-reverse my-1'>
            <PrimaryButton variant='outlined' className="my-1" onClick={() => { setOpenDialogAdd(true) }}>{t("generic.buttons.add")}</PrimaryButton>
          </div>
        }
        <div className="d-flex justify-content-center">
          <DataTable
            columns={columns}
            setColumns={setColumns}
            data={companyLocationsData}
            entityId={"company_location_id"}
            entity={`CompanyLocations`}
            preferences={myPreferences}
            namePreferences={"grid-companylocations"}
            nameOrderColumns={"grid-companylocations-columns"}
            isChildren={true}
            setOpenDialog={setOpenDialogUpdate}
            setDataRow={setCompanyLocationPayload}
          />
        </div>
        <DialogEntity
          content={<AddCompanyLocation openDialog={openDialogAdd} setOpenDialog={setOpenDialogAdd} company_id={props.company_id} AddNewRow={handleAddRow} UpdateRow={handleUpdateRow} />}
          open={openDialogAdd}
          setOpen={setOpenDialogAdd}
          title={<Header title={t("companylocations.title-view")} size='md' />}
        />
        <DialogEntity
          content={<UpdateCompanyLocation companyLocationPayload={companyLocationPayload} company_id={props.company_id} setOpenDialog={setOpenDialogUpdate} permissions={props.resourceScreen} DeleteRow={handleDeleteRow} UpdateRow={handleUpdateRow} />}
          open={openDialogUpdate}
          setOpen={setOpenDialogUpdate}
          title={<Header title={t("companylocations.title-view")} size='md' />}
        />
        <SnackbarComponent />
      </div>
      }

    </div>
  )
}
