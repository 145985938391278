import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FormsErrors } from '../../../../hooks/Forms/FormsErrors';
import GenericPromises from '../../../../api/GenericPromises';
import useSnackBar from '../../../../components/Commons/SnackBar/useSnackBar';
import { OptionMenu } from '../../../../interfaces/Security/optionsMenu';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Autocomplete, Box, Switch, TextField, Typography } from '@mui/material';
import { Spinner } from '../../../../components/Commons/Spinner/Spinner';
import { Resource } from '../../../../interfaces/Security/resources';
import InfoIcon from '@mui/icons-material/Info';
import { ButtonStyled, PrimaryButton } from '../../../../theme/buttons';

export const UpdateOptionMenu = ({ ...props }) => {
    const [t] = useTranslation("global");
    const { GenericPutResource, GenericGetResource, GenericDeleteResource } = GenericPromises();
    const { GetError } = FormsErrors();
    const [isResource, setIsResource] = useState(false);
    const [defaultResource, setDefaultResource] = useState<Resource>()
    const [menuParentsCombo, setMenuParentsCombo] = useState<OptionMenu[]>([]);
    const [comboResources, setComboResources] = useState<Resource[]>([]);
    const [defaultParent, setDefaultParent] = useState<OptionMenu>();
    const [loadingPost, setLoadingPost] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const { showSnackBar, SnackbarComponent } = useSnackBar();

    const {
        handleSubmit,
        control,
        formState: { errors },
        getValues,
        reset,
        trigger,
    } = useForm<OptionMenu>({
        defaultValues: {
            parent_option_id: undefined,
            resource_id: undefined,
            option_name: undefined,
            description: undefined,
            menu_order: undefined,
            is_visible: true,
        }
    });
    const onSubmit: SubmitHandler<OptionMenu> = (data) => onPut(data);

    const onPut = (data: OptionMenu) => {
        setLoadingPost(true);
        trigger().then(async (triggerData) => {
            if (triggerData) {
                let myOptionMenu = {
                    parent_option_id: data.parent_option_id,
                    resource_id: data.resource_id ?? null,
                    option_name: data.option_name,
                    description: data.description ?? null,
                    is_visible: data.is_visible,
                    menu_order: data.menu_order,
                }
                GenericPutResource(`/optionmenu/${props.defaultOptionMenu.option_menu_id}`, myOptionMenu)
                    .then((response) => {
                        let myNewRow = {
                            ...response.data,
                        }
                        setLoadingPost(false);
                        props.updateNewRow(myNewRow);
                        props.setOpenDialog(false);
                    })
                    .catch((error) => {
                        setLoadingPost(false);
                        showSnackBar(error.message, 'error');
                    });
            }
            else {
                setLoadingPost(false);
            }
        })
    }

    const onDelete = () => {
        setLoadingPost(true);
        GenericDeleteResource(`/optionmenu/${props.defaultOptionMenu.option_menu_id}`)
            .then((response) => {
                setLoadingPost(false);
                props.deleteNewRow(props.defaultOptionMenu);
                props.setOpenDialog(false);
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, 'error');
            });
    }

    const onCancel = () => {
        props.setOpenDialog(false);
    }

    const getNodesWithoutResource = (tree: any): any => {
        let result: OptionMenu[] = [];
        const traverse = (node: any): void => {
            if (node.resource_id === undefined || node.resource_id === null) {
                result.push(node);
            }
            if (node.children && node.children.length > 0) {
                node.children.forEach(traverse);
            }
        };

        traverse(tree);

        return result;
    };

    useEffect(() => {
        let myPromises = [
            GenericGetResource("/resources"),
        ];
        Promise.all(myPromises)
            .then((responses) => {
                reset({
                    parent_option_id: props.defaultOptionMenu.parent_option_id,
                    resource_id: props.defaultOptionMenu.resource_id,
                    option_name: props.defaultOptionMenu.option_name,
                    description: props.defaultOptionMenu.description,
                    menu_order: props.defaultOptionMenu.menu_order,
                    is_visible: props.defaultOptionMenu.is_visible,
                });
                let myResource = responses[0].data.data.find((element: Resource) => element.resource_id === props.defaultOptionMenu.resource_id)
                if (myResource) {
                    setDefaultResource(myResource)
                    setIsResource(true);
                }
                else {
                    setIsResource(false);
                }
                let nodesWithoutResource = getNodesWithoutResource(props.defaultOptionMenuData);
                setDefaultParent(nodesWithoutResource.find((element: OptionMenu) => element.option_menu_id === props.defaultOptionMenu.parent_option_id))
                setMenuParentsCombo(nodesWithoutResource);
                setComboResources((prev) => responses[0].data.data);
                setDataLoaded(true);
            })
            .catch((error) => {
                showSnackBar(error.message, "error");
            })
    }, []);

    return (
        <>
            {!dataLoaded && <Spinner isBox={false} />}
            {dataLoaded &&
                <Box className='d-flex justify-content-center' sx={{ minWidth: 1000 }}>
                    <form onSubmit={handleSubmit(onSubmit)} className='w-100'>
                        <div className='w-100'>
                            <div className='w-100 d-flex'>
                                <div className='w-50'>
                                    <Controller
                                        name="parent_option_id"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                ref={field.ref}
                                                size="small"
                                                sx={{ width: "100%" }}
                                                options={menuParentsCombo}
                                                defaultValue={defaultParent}
                                                getOptionLabel={(option) => `${option.description}`}
                                                renderOption={(props, option: OptionMenu) => (
                                                    <div key={option.option_menu_id}>
                                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                            {`${option.description}`}
                                                        </Box>
                                                    </div>
                                                )}
                                                isOptionEqualToValue={(option, value) => option.option_menu_id === value.option_menu_id}
                                                onChange={(_, values) => {
                                                    field.onChange(values?.option_menu_id || null)
                                                }}
                                                disabled={true}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={`${t("optionsmenu.fields.parent_option_id")}`}
                                                        variant="filled"
                                                        value={field.value}
                                                        error={!!errors.option_menu_id || field.value === null}
                                                        helperText={(errors.option_menu_id?.type || field.value === null) ? t("generic.forms-errors.required") : null}
                                                        sx={{ paddingRight: 2 }}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </div>
                                <div className='w-50'>
                                    <Controller
                                        name="resource_id"
                                        control={control}
                                        rules={{ required: isResource }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                ref={field.ref}
                                                size="small"
                                                sx={{ width: "100%" }}
                                                options={comboResources}
                                                defaultValue={defaultResource}
                                                getOptionLabel={(option) => `${option.resource_name} - ${option.resource_route}`}
                                                renderOption={(props, option: Resource) => (
                                                    <div key={option.resource_id}>
                                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                            {`${option.resource_name} - ${option.resource_route}`}
                                                        </Box>
                                                    </div>
                                                )}
                                                isOptionEqualToValue={(option, value) => option.resource_id === value.resource_id}
                                                onChange={(_, values) => {
                                                    field.onChange(values?.resource_id || null)
                                                }}
                                                disabled={!isResource}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={`${t("resources.title-view")}`}
                                                        variant="filled"
                                                        value={field.value}
                                                        error={!!errors.resource_id}
                                                        helperText={GetError(errors.option_name?.type)}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className='w-100 d-flex mt-3'>
                                <div className="w-50">
                                    <Controller
                                        name="option_name"
                                        control={control}
                                        rules={{ required: true, maxLength: 200 }}
                                        render={({ field }) =>
                                            <TextField
                                                variant="filled"
                                                label={`${t("optionsmenu.fields.option_name")}`}
                                                ref={field.ref}
                                                value={field.value}
                                                onChange={(event) => { field.onChange(event.target.value.replace(/\s+/g, '')) }}
                                                error={!!errors.option_name}
                                                helperText={GetError(errors.option_name?.type)}
                                                size="small"
                                                disabled={isResource}
                                                InputLabelProps={{ shrink: true }}
                                                style={{ width: "100%" }}
                                                sx={{ paddingRight: 2 }}
                                            />
                                        }
                                    />
                                </div>
                                <div className="w-50">
                                    <Controller
                                        name="description"
                                        control={control}
                                        rules={{ maxLength: 200 }}
                                        render={({ field }) =>
                                            <TextField
                                                variant="filled"
                                                label={`${t("optionsmenu.fields.description")}`}
                                                ref={field.ref}
                                                value={field.value}
                                                onChange={(event) => { field.onChange(event.target.value) }}
                                                error={!!errors.description}
                                                helperText={GetError(errors.description?.type)}
                                                size="small"
                                                style={{ width: "100%" }}
                                            />
                                        }
                                    />
                                </div>
                            </div>
                            <div className='d-flex w-100 mt-3'>
                                <div className='w-50'>
                                    <Controller
                                        name="menu_order"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) =>
                                            <TextField
                                                variant="filled"
                                                label={`${t("optionsmenu.fields.menu_order")}`}
                                                type='number'
                                                ref={field.ref}
                                                sx={{
                                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                                    "& input[type=number]": { MozAppearance: "textfield", }, paddingRight: 2
                                                }}
                                                value={field.value}
                                                onChange={(event) => { field.onChange(event.target.value) }}
                                                error={!!errors.menu_order}
                                                helperText={GetError(errors.menu_order?.type)}
                                                size="small"
                                                style={{ width: "100%" }}
                                            />
                                        }
                                    />
                                </div>
                                <div className='w-25 mt-1'>
                                    <Controller
                                        name="is_visible"
                                        control={control}
                                        render={({ field }) =>
                                            <Box sx={{ display: 'flex', alignItems: 'center', width: '75%', justifyContent: "space-between" }}>
                                                <Typography sx={{ ml: 2 }}>{t("optionsmenu.fields.is_visible")}</Typography>
                                                <Switch ref={field.ref} onChange={field.onChange} value={field.value} defaultChecked={props.defaultOptionMenu.is_visible} />
                                            </Box>
                                        }
                                    />
                                </div>
                                <div className="d-flex flex-row w-25 mt-2">
                                    <div>
                                        <InfoIcon />
                                    </div>
                                    <div>
                                        {isResource ?
                                            <Typography sx={{ ml: 2 }}>{t("optionsmenu.info.is_resource")}</Typography>
                                            :
                                            <Typography sx={{ ml: 2 }}>{t("optionsmenu.info.is_menu")}</Typography>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex mt-1'>
                            <div className="w-25">
                                {props.permissions.delete ? (
                                    <ButtonStyled
                                        onClick={onDelete}
                                        variant='outlined'
                                        className='m-1'
                                        disabled={props.defaultOptionMenu.children.length > 0 || loadingPost}>
                                        {t("generic.buttons.delete")}
                                    </ButtonStyled>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div className="d-flex flex-row-reverse w-75">
                                <PrimaryButton onClick={() => { onPut(getValues()) }} variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.accept")}</PrimaryButton>
                                <ButtonStyled variant='contained' className='m-1' onClick={onCancel} disabled={loadingPost}>{t("generic.buttons.cancel")}</ButtonStyled>
                            </div>
                        </div>
                    </form>
                </Box >
            }
            <SnackbarComponent />
        </>
    )
}
