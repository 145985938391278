import { useEffect, useState } from 'react'
import { Header } from '../../../components/Header'
import GenericPromises from '../../../api/GenericPromises';
import { InvoiceHeader } from './invoiceHeader';
import { SubmitHandler, useForm } from 'react-hook-form';
import { InvoiceDocumentType, SalesInvoice } from '../../../interfaces/Sales/Invoices/salesinvoices';
import { useTranslation } from 'react-i18next';
import { Currency } from '../../../interfaces/Commons/currencies';
import { PaymentMethod } from '../../../interfaces/Sales/Catalogs/paymentMethods';
import { PaymentMode } from '../../../interfaces/Sales/Catalogs/paymentModes';
import { Customer, CustomerLocation } from '../../../interfaces/Sales/Catalogs/Customers/customers';
import { CFDI } from '../../../interfaces/Sales/Catalogs/CFDIs';
import { Spinner } from '../../../components/Commons/Spinner/Spinner';
import { Tax } from '../../../interfaces/Sales/Catalogs/taxes';
import { Company } from '../../../interfaces/Security/companies';
import { Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePermissions } from '../../../hooks/usePermissions';
import { PaymentCondition } from '../../../interfaces/Sales/Catalogs/paymentConditions';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { Language } from '../../../interfaces/Commons/languages';
import { useParamsFilter } from '../../../hooks/useParamsFilter';
import { TaxRegime } from '../../../interfaces/Sales/Catalogs/taxRegimes';

export const AddSalesInvoices = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const [dataLoaded, setDataLoaded] = useState(false);
  const { GetParamsFromBase64 } = useParamsFilter();
  const [isChangedTotalValue, setIsChangedTotalValue] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [myConsecutive, setMyConsecutive] = useState("");
  const { GenericGetResource, GenericGet, GenericPostResource, GenericDeleteResource, GenericPostDocument, GenericGetResourceGeneric } = GenericPromises();
  const { GetResourceByUrl } = usePermissions();
  const [company, setCompany] = useState<Company>();
  const [comboCurrencies, setComboCurrencies] = useState<Currency[]>([]);
  const [comboPaymentMethods, setComboPaymentMethods] = useState<PaymentMethod[]>([]);
  const [comboPaymentModes, setComboPaymentModes] = useState<PaymentMode[]>([]);
  const [comboCustomers, setComboCustomers] = useState<Customer[]>([]);
  const [comboCFDIs, setComboCFDIs] = useState<CFDI[]>([]);
  const [comboTaxes, setComboTaxes] = useState<Tax[]>([]);
  const [comboCustomerLocations, setComboCustomerLocations] = useState<CustomerLocation[]>([]);
  const [defaultCustomerLocation, setDefaultCustomerLocation] = useState<CustomerLocation>();
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod>();
  const [comboPaymentConditions, setComboPaymentConditions] = useState<PaymentCondition[]>([]);
  const [comboLanguages, setComboLanguages] = useState<Language[]>([])
  const [defaultPaymentConditions, setDefaultPaymentConditions] = useState<PaymentCondition>()
  const [defaultPaymentMode, setDefaultPaymentMode] = useState<PaymentMode>();
  const [defaultCurrency, setDefaultCurrency] = useState<Currency>();
  const [defaultCFDI, setDefaultCFDI] = useState<CFDI>();
  const [comboInvoiceDocumentTypes, setComboInvoiceDocumentTypes] = useState<InvoiceDocumentType[]>([]);
  const [isSelectedCustomerTextfield, setIsSelectedCustomerTextfield] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [customerId, setCustomerId] = useState("");

  const {
    handleSubmit,
    control,
    reset,
    trigger,
    formState: { errors },
    getValues,
  } = useForm<SalesInvoice>({
    defaultValues: {
      sales_invoice_id: undefined,
      sales_invoice_name: undefined,
      sales_invoice_date: undefined,
      purchase_order: undefined,
      customer_id: undefined,
      customer_business_name: undefined,
      customer_comercial_name: undefined,
      customer_rfc: undefined,
      customer_street_name: undefined,
      customer_location_id: 0,
      customer_postal_code: undefined,
      customer_city_id: undefined,
      customer_city_name: undefined,
      customer_state_id: undefined,
      customer_state_name: undefined,
      customer_state_abbr: undefined,
      customer_country_id: undefined,
      customer_country_name: undefined,
      customer_country_code: undefined,
      subtotal_amount: 0,
      total_amount: 0,
      tax_amount: 0,
      currency_id: undefined,
      currency_code: undefined,
      currency_description: undefined,
      project_id: undefined,
      invoice_doc_type_id: undefined,
      invoice_doc_status_id: undefined,
      payment_mode_id: undefined,
      payment_mode_code: undefined,
      payment_mode_description: undefined,
      payment_method_id: undefined,
      payment_method_code: undefined,
      payment_method_name: undefined,
      cfdi_id: undefined,
      cfdi_code: undefined,
      cfdi_description: undefined,
      discount_amount: 0,
      original_string_SAT: undefined,
      certificate_number_SAT: undefined,
      certificate_number_CFDI: undefined,
      uuid: undefined,
      seal_SAT: undefined,
      seal_CFDI: undefined,
      signing_date: undefined,
      cancellation_date: undefined,
      cancellation_reason: undefined,
      cancellation_reason_description: undefined,
      cancellation_response_code: undefined,
      customer_tax_regime_id: undefined,
      customer_tax_regime_code: undefined,
      customer_tax_regime_description: undefined,
      includes_transportation_document: undefined,
      includes_exterior_complement: undefined,
      is_accounted: undefined,
      id_consecutive: undefined,
      notes: undefined,
      retention_amount: undefined,
      payment_condition_id: undefined,
      payment_condition_days: undefined,
      payment_condition_name: undefined,
      payment_condition_description: undefined,
      payment_due_date: undefined,
      customer_language: undefined,
      customer_email: undefined,
      customer_phone_number: undefined,
      sales_invoice_QR: undefined,
      sales_invoice_XML: undefined,
      cancelled_sales_invoice_XML: undefined,
      sales_invoice_pdf: undefined,
      exchange_rate: undefined,
      company_business_name: undefined,
      company_street: undefined,
      company_city_id: undefined,
      company_city_name: undefined,
      company_city_code: undefined,
      company_state_id: undefined,
      company_state_name: undefined,
      company_state_abbr: undefined,
      company_country_id: undefined,
      company_country_name: undefined,
      company_country_code: undefined,
      company_postal_code: undefined,
      company_rfc: undefined,
      company_tax_regime_id: undefined,
      company_tax_regime_code: undefined,
      company_tax_regime_description: undefined,
      company_email: undefined,
      company_phone_number: undefined,
      company_account_alias: undefined,
      company_primary_account_alias: undefined,
      company_primary_bank_name: undefined,
      company_primary_account_number: undefined,
      company_primary_interbank_key: undefined,
      company_primary_swift_code: undefined,
      company_secondary_account_alias: undefined,
      company_secondary_bank_name: undefined,
      company_secondary_account_number: undefined,
      company_secondary_interbank_key: undefined,
      company_secondary_swift_code: undefined,
      creation_date: undefined,
      last_update_user: undefined,
      last_update_date: undefined,
    }
  });
  const onSubmit: SubmitHandler<SalesInvoice> = (data) => onPost(data);

  const onPost = async (data: SalesInvoice, myCustomerLocation?: CustomerLocation, myCustomerLocationDelivery?: CustomerLocation | undefined) => {
    trigger()
      .then(async (responseTrigger) => {
        if (responseTrigger) {
          let myCustomer = comboCustomers.find((customer: Customer) => customer.customer_id === data.customer_id);
          let myPaymentMode = comboPaymentModes.find((item: PaymentMode) => item.payment_mode_id === data.payment_mode_id);
          let myPaymentMethod = comboPaymentMethods.find((item: PaymentMethod) => item.payment_method_id === data.payment_method_id);
          let myCFDI = comboCFDIs.find((item: CFDI) => item.cfdi_id === data.cfdi_id);
          let myCurrency = comboCurrencies.find((item: Currency) => item.currency_id === data.currency_id);
          let myInvoiceDocumentType = comboInvoiceDocumentTypes.find((item: InvoiceDocumentType) => item.invoice_doc_type_id === (data.invoice_doc_type_id ?? 1));
          let myPaymentCondition = comboPaymentConditions.find((item: PaymentCondition) => item.payment_condition_id === data.payment_condition_id);
          let myCustomerLanguage = comboLanguages.find((language: Language) => language.language_id === myCustomer?.language_id)

          let myCustomerTaxRegime: TaxRegime | undefined = undefined;
          if (myCustomerLocation) {
            let myTaxRegimeResponse = await GenericGetResource(`/taxregimes/${myCustomerLocation.tax_regime_id}`);
            myCustomerTaxRegime = myTaxRegimeResponse.data;
          }

          const responseConsecutive = await GenericGetResource(`/consecutives/getandupdate/byConsecutiveId/${myInvoiceDocumentType?.consecutive_id}`);
          const responseCompany = await GenericGetResourceGeneric("/companies/1", "/gcompanies");

          let deliveryAdreesId: number;
          let mySalesDelivery = {
            business_name: myCustomerLocationDelivery?.business_name ?? null,
            comercial_name: null,
            street: myCustomerLocationDelivery?.street ?? null,
            postal_code: myCustomerLocationDelivery?.postal_code ?? null,
            city_id: myCustomerLocationDelivery?.city_id ?? null,
            city_name: myCustomerLocationDelivery?.city_name ?? null,
            state_id: myCustomerLocationDelivery?.state_id ?? null,
            state_name: myCustomerLocationDelivery?.state_name ?? null,
            state_abbr: myCustomerLocationDelivery?.state_abbr ?? null,
            country_id: myCustomerLocationDelivery?.country_id ?? null,
            country_name: myCustomerLocationDelivery?.country_name ?? null,
            country_code: myCustomerLocationDelivery?.country_code ?? null,
            rfc: myCustomerLocationDelivery?.customer_rfc ?? null,
            tax_regime_id: myCustomerLocationDelivery?.tax_regime_id ?? null,
            tax_regime_code: myCustomerLocationDelivery?.tax_regime_code ?? null,
            tax_regime_description: myCustomerLocationDelivery?.tax_regime_description ?? null,
            email: myCustomerLocationDelivery?.customer_email ?? null,
            phone_number: myCustomerLocationDelivery?.customer_phone_number ?? null,
          }
          let deliveryAddress = await GenericPostResource("/salesdeliveryaddresses", mySalesDelivery).catch((error) => { });
          deliveryAdreesId = (deliveryAddress !== undefined) ? deliveryAddress.data.sales_delivery_addresses_id : null;

          let myData = {
            sales_invoice_name: responseConsecutive.data.data[0].Consecutive,
            sales_invoice_date: data.sales_invoice_date ?? new Date(),
            purchase_order: data.purchase_order ?? null,
            customer_id: data.customer_id,
            customer_business_name: myCustomer?.business_name,
            customer_comercial_name: myCustomer?.comercial_name,
            customer_rfc: myCustomer?.customer_rfc,
            customer_street_name: myCustomerLocation?.street ?? null,
            customer_location_id: myCustomerLocation?.customer_location_id,
            customer_postal_code: myCustomerLocation?.postal_code ?? null,
            customer_city_id: 0,
            customer_city_name: myCustomerLocation?.city_name ?? null,
            customer_state_id: 0,
            customer_state_name: myCustomerLocation?.state_name ?? null,
            customer_state_abbr: myCustomerLocation?.state_abbr ?? null,
            customer_country_id: 0,
            customer_country_name: myCustomerLocation?.country_name ?? null,
            customer_country_code: myCustomerLocation?.country_code ?? null,
            subtotal_amount: data.subtotal_amount ?? 0,
            total_amount: data.total_amount ?? 0,
            tax_amount: data.tax_amount ?? 0,
            currency_id: data.currency_id ?? null,
            currency_code: myCurrency?.currency_code ?? null,
            currency_description: myCurrency?.currency_description ?? null,
            project_id: (projectId === "") ? null : projectId,
            invoice_doc_type_id: data.invoice_doc_type_id ?? 1,
            invoice_doc_status_id: 1,
            payment_mode_id: null,
            payment_mode_code: myPaymentMode?.code ?? null,
            payment_mode_description: myPaymentMode?.description ?? null,
            payment_method_id: null,
            payment_method_code: myPaymentMethod?.code ?? null,
            payment_method_name: myPaymentMethod?.name ?? null,
            cfdi_id: null,
            cfdi_code: myCFDI?.code ?? null,
            cfdi_description: myCFDI?.description ?? null,
            discount_amount: null,
            original_string_SAT: null,
            certificate_number_SAT: null,
            certificate_number_CFDI: null,
            uuid: null,
            seal_SAT: null,
            seal_CFDI: null,
            signing_date: null,
            cancellation_date: null,
            cancellation_reason: null,
            cancellation_reason_description: null,
            cancellation_response_code: null,
            customer_tax_regime_id: 0,
            customer_tax_regime_code: myCustomerTaxRegime?.tax_regime_code ?? null,
            customer_tax_regime_description: myCustomerTaxRegime?.description ?? null,
            includes_transportation_document: false,
            includes_exterior_complement: false,
            is_accounted: data.is_accounted ?? false,
            id_consecutive: myInvoiceDocumentType?.consecutive_id,
            notes: null,
            retention_amount: null,
            payment_condition_id: null,
            payment_condition_days: myPaymentCondition?.payment_condition_days ?? null,
            payment_condition_name: myPaymentCondition?.payment_condition_name ?? null,
            payment_condition_description: myPaymentCondition?.payment_condition_description ?? null,
            payment_due_date: null,
            customer_language: myCustomerLanguage?.language_code ?? "ES",
            customer_email: myCustomerLocation?.customer_email ?? null,
            customer_phone_number: myCustomerLocation?.customer_phone_number ?? null,
            sales_invoice_QR: null,
            sales_invoice_XML: null,
            cancelled_sales_invoice_XML: null,
            sales_invoice_pdf: null,
            exchange_rate: 1,
            date_mail_send: null,
            user_mail_send: null,
            company_business_name: responseCompany.data?.business_name ?? null,
            company_street: responseCompany.data?.street ?? null,
            company_city_id: responseCompany.data?.city_id ?? null,
            company_city_name: responseCompany.data?.city_name ?? null,
            company_city_code: responseCompany.data?.city_code ?? null,
            company_state_id: responseCompany.data?.state_id ?? null,
            company_state_name: responseCompany.data?.state_name ?? null,
            company_state_abbr: responseCompany.data?.state_abbr ?? null,
            company_country_id: responseCompany.data?.country_id ?? null,
            company_country_name: responseCompany.data?.country_name ?? null,
            company_country_code: responseCompany.data?.country_code ?? null,
            company_postal_code: responseCompany.data?.postal_code ?? null,
            company_rfc: responseCompany.data?.rfc ?? null,
            company_tax_regime_id: responseCompany.data?.tax_regime_id ?? null,
            company_tax_regime_code: responseCompany.data?.tax_regime_code ?? null,
            company_tax_regime_description: responseCompany.data?.tax_regime_description ?? null,
            company_email: responseCompany.data?.email ?? null,
            company_phone_number: responseCompany.data?.phone_number ?? null,
            company_account_alias: responseCompany.data?.primary_account_alias ?? null,
            company_primary_account_alias: responseCompany.data?.primary_account_alias ?? null,
            company_primary_bank_name: responseCompany.data?.primary_bank_name ?? null,
            company_primary_account_number: responseCompany.data?.primary_account_number ?? null,
            company_primary_interbank_key: responseCompany.data?.primary_interbank_key ?? null,
            company_primary_swift_code: responseCompany.data?.primary_swift_code ?? null,
            company_secondary_account_alias: responseCompany.data?.secondary_account_alias ?? null,
            company_secondary_bank_name: responseCompany.data?.secondary_bank_account ?? null,
            company_secondary_account_number: responseCompany.data?.secondary_account_number ?? null,
            company_secondary_interbank_key: responseCompany.data?.secondary_interbank_key ?? null,
            company_secondary_swift_code: responseCompany.data?.secondary_swift_code ?? null,
            sales_delivery_addresses_id: deliveryAdreesId,
          }
          GenericPostDocument("/salesinvoices/add", myData, true)
            .then(async (response) => {
              let myShippingTracking = {
                sales_invoice_id: response.data.sales_invoice_id,
                sales_shipping_document_id: null,
                track_id: null,
                gross_weight: null,
                real_gross_weight: null,
                net_weight: null,
                real_net_weight: null,
                packages_quantity: null,
                containers_quantity: null,
                pallets_quantity: null,
                dimensions: null,
              }
              GenericPostResource("/shippingtrackings", myShippingTracking)
                .then(async () => {
                  await navigate("/salesinvoices/view", { state: { row: response.data } });
                })
                .catch(async (error) => {
                  await GenericDeleteResource(`/salesinvoices/${response.data.sales_invoice_id}`);
                  showSnackBar(error.message, "error");
                  setIsSelectedCustomerTextfield(false);
                })
            })
            .catch((error) => {
              showSnackBar(error.message, "error")
              setIsSelectedCustomerTextfield(false);
            });
        } else {
          setIsSelectedCustomerTextfield(false);
          showSnackBar(t("salesinvoices.info.no-data"), "warning")
        }
      })
  };

  const onChangeCustomer = (customer_id: number | undefined) => {
    let myPromises = [
      GenericGetResource(`/customerlocations/bycustomerid/${customer_id}`),
      GenericGetResource(`/customers/${customer_id}`)
    ];
    Promise.all(myPromises)
      .then(async (responses) => {
        let myMainLocation: CustomerLocation = await responses[0].data.data.find((customerLocation: CustomerLocation) => customerLocation.fiscal_address === true);
        await setComboCustomerLocations((prev) => responses[0].data.data);
        if (responses[0].data.data.length > 0 && myMainLocation) {

          await setDefaultCustomerLocation((prev) => myMainLocation);
          await setDefaultPaymentMethod((prev) => comboPaymentMethods.find((item: PaymentMethod) => item.payment_method_id === responses[1].data.default_payment_method_id));
          await setDefaultPaymentMode((prev) => comboPaymentModes.find((item: PaymentMode) => item.payment_mode_id === responses[1].data.default_payment_mode_id));
          await setDefaultCurrency((prev) => comboCurrencies.find((item: Currency) => item.currency_id === responses[1].data.default_currency_id));
          await setDefaultCFDI((prev) => comboCFDIs.find((item: CFDI) => item.cfdi_id === responses[1].data.default_cfdi_id));
          await setDefaultPaymentConditions((prev) => comboPaymentConditions.find((item: PaymentCondition) => item.payment_condition_id === responses[1].data.default_payment_condition_id))

          let myMainLocationDelivery: CustomerLocation = await responses[0].data.data.find((customerLocation: CustomerLocation) => customerLocation.default_delivery_address === true);

          reset({
            customer_id: customer_id,
            customer_location_id: myMainLocation?.customer_location_id ?? undefined,
            customer_city_id: myMainLocation.city_id,
            customer_city_name: myMainLocation.city_name,
            customer_state_id: myMainLocation.state_id,
            customer_state_name: myMainLocation.state_name,
            customer_state_abbr: myMainLocation.state_abbr,
            customer_country_id: myMainLocation.country_id,
            customer_country_name: myMainLocation.country_name,
            customer_country_code: myMainLocation.country_code,
            customer_postal_code: myMainLocation.postal_code,
            customer_street_name: myMainLocation.street,
            payment_method_id: responses[1].data.default_payment_method_id ?? undefined,
            payment_mode_id: responses[1].data.default_payment_mode_id ?? undefined,
            currency_id: responses[1].data.default_currency_id ?? undefined,
            cfdi_id: responses[1].data.default_cfdi_id ?? undefined,
            payment_condition_id: responses[1].data.default_payment_condition_id ?? undefined,
            sales_invoice_date: getValues("sales_invoice_date"),
            invoice_doc_type_id: getValues("invoice_doc_type_id"),
            project_id: parseInt(projectId),
          });

          onPost(getValues(), myMainLocation, myMainLocationDelivery);
        }
        else {
          onPost(getValues());
        }
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setIsSelectedCustomerTextfield(false);
      });
  }

  useEffect(() => {
    let myProjectId = "";
    let myCustomerId = "";
    if (location.search.length > 0 && location.state === null) {
      let myParams = GetParamsFromBase64();
      myProjectId = myParams.get("project_id") ?? '';
      myCustomerId = myParams.get("customer_id") ?? '';
      setProjectId((prev) => myProjectId ?? "");
      setCustomerId((prev) => myCustomerId ?? "");
    }

    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("salesinvoices.title")}`;
    });

    let myPromises = [
      GenericGetResource("/currencies"),
      GenericGetResource("/paymentmethods"),
      GenericGetResource("/paymentmodes"),
      GenericGetResource("/customers"),
      GenericGetResourceGeneric("/companies", "/gcompanies"),
      GenericGetResource("/cfdi"),
      GenericGetResource("/taxes"),
      GenericGet("/invoicedocumenttypes"),
      GetResourceByUrl("/salesinvoices/fiscalsignature"),
      GetResourceByUrl("/salesinvoices/cancel/fiscalsignature"),
      GenericGetResource("/paymentconditions"),
      GenericGetResource("/languages"),
    ]

    Promise.all(myPromises)
      .then(async (responses) => {
        await setComboCurrencies((prev) => responses[0].data.data);
        await setComboPaymentMethods((prev) => responses[1].data.data);
        await setComboPaymentModes((prev) => responses[2].data.data);
        await setComboCustomers((prev) => responses[3].data.data);
        await setCompany((prev) => responses[4].data.data[0]);
        await setComboCFDIs((prev) => responses[5].data.data);
        await setComboTaxes((prev) => responses[6].data.data);
        await setComboInvoiceDocumentTypes((prev) => responses[7].data.data.filter((element: InvoiceDocumentType) => element.is_type_for_sale == true));
        await setComboPaymentConditions((prev) => responses[10].data.data);
        await setComboLanguages((prev) => responses[11].data.data);

        await GenericGetResource(`/consecutives/byConsecutiveId/${responses[7].data.data[0].consecutive_id}`)
          .then((response) => {
            reset({
              sales_invoice_name: response.data.data[0].Consecutive,
              invoice_doc_type_id: 1,
            }, { keepValues: true });
            setMyConsecutive((prev) => response.data.data[0].Consecutive);
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
          });

        if (!myProjectId && !myCustomerId) {
          setDataLoaded((prev) => true);
        }
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });

  }, []);

  useEffect(() => {
    if (customerId && comboCurrencies && comboPaymentMethods && comboPaymentModes && company && comboCFDIs && comboTaxes && comboInvoiceDocumentTypes && comboPaymentConditions && comboLanguages) {
      onChangeCustomer(parseInt(customerId));
    }
  }, [customerId, comboCurrencies, comboPaymentMethods, comboPaymentModes, company, comboCFDIs, comboTaxes, comboInvoiceDocumentTypes, comboPaymentConditions, comboLanguages]);

  useEffect(() => { }, [myConsecutive]);

  return (
    <>
      {!dataLoaded && <Spinner />}
      {dataLoaded &&
        <>
          <Header title={t("salesinvoices.title-view")} size='md' />

          <form onSubmit={handleSubmit(onSubmit)} className='custom-card-view'>

            <Grid container display={'flex'} flexDirection={'column'}>
              <Grid item>
                <InvoiceHeader
                  control={control}
                  errors={errors}
                  isCustomerSelected={false}
                  onChangeCustomer={onChangeCustomer}
                  company={company}
                  defaultPaymentMethod={defaultPaymentMethod}
                  defaultPaymentMode={defaultPaymentMode}
                  defaultCurrency={defaultCurrency}
                  defaultCFDI={defaultCFDI}
                  comboCurrencies={comboCurrencies}
                  comboPaymentMethods={comboPaymentMethods}
                  comboPaymentModes={comboPaymentModes}
                  comboCustomers={comboCustomers}
                  comboCFDIs={comboCFDIs}
                  comboInvoiceDocumentTypes={comboInvoiceDocumentTypes}
                  setMyConsecutive={setMyConsecutive}
                  myConsecutive={myConsecutive}
                  isSelectedCustomerTextfield={isSelectedCustomerTextfield}
                  setIsSelectedCustomerTextfield={setIsSelectedCustomerTextfield}
                />
              </Grid>
              <Grid item>
              </Grid>
            </Grid>

          </form>
        </>
      }
      <SnackbarComponent />
    </>
  )
}
