import { useEffect, useState } from "react";
import { Parameter } from "../../interfaces/Commons/parameters";
import { ProjectExtraField } from "../../interfaces/Projects/Catalogs/projectExtraFields";
import GenericPromises from "../../api/GenericPromises";

export const useFormulaEvaluator = () => {
  const [parameters, setParameters] = useState<Parameter[]>([]);
  const { GenericGetResource } = GenericPromises();

  // Función para obtener parámetros desde la API
  const fetchParameters = async () => {
    try {
      const response = await GenericGetResource("/parameters");
      setParameters(response.data.data);
      return response.data.data;
    } catch (error) {
      console.error("Error fetching parameters:", error);
    }
  };

  // Función para reemplazar variables por sus valores
  const replaceVariables = (formula: string, fields: ProjectExtraField[], parameters: Parameter[]): string => {
    let formulaWithValues = formula;

    // Reemplazo de valores de fields (solo aquellos rodeados de '{}')
    fields.forEach(field => {
      const { project_extra_field_name, project_extra_field_value, field_type_name } = field;
      let valueStr;

      if (field_type_name === 'Date') {
        valueStr = `new Date("${project_extra_field_value}")`;
      } else if (field_type_name === 'number') {
        valueStr = `${project_extra_field_value}`;
      } else if (field_type_name === 'string') {
        valueStr = `"${project_extra_field_value}"`;
      } else {
        valueStr = `${project_extra_field_value}`;
      }

      // Reemplazo solo los nombres de campos rodeados de {}
      formulaWithValues = formulaWithValues.replace(new RegExp(`\\{${project_extra_field_name}\\}`, 'g'), valueStr);
    });

    // Reemplazo de valores de parameters (solo aquellos rodeados de '<>')
    parameters.forEach(param => {
      const { parameter_name, parameter_value, field_type_name } = param;

      if (parameter_name && parameter_value) {
        let paramValue: string | number = parameter_value;

        if (field_type_name === 'Date') {
          paramValue = `new Date("${parameter_value}")`;
        } else if (field_type_name === 'number') {
          paramValue = `${parameter_value}`;
        } else if (field_type_name === 'string') {
          paramValue = `"${parameter_value}"`;
        }

        // Reemplazar solo los nombres de parámetros rodeados de <>
        formulaWithValues = formulaWithValues.replace(new RegExp(`<${parameter_name}>`, 'g'), paramValue);
      }
    });

    // Reemplazo de funciones como ROUND, AVERAGE, etc.
    formulaWithValues = replaceFunctions(formulaWithValues);

    return formulaWithValues;
  };

  // Función auxiliar para reemplazar funciones de manera recursiva
  const replaceFunctions = (formula: string): string => {
    // Expresión regular para encontrar funciones con su argumento
    const functionRegex = /(\b(ROUND|FLOOR|CEIL)\b)\(([^)]+)\)/g;

    // Mientras se encuentren funciones, se siguen reemplazando
    while (functionRegex.test(formula)) {
      formula = formula.replace(functionRegex, (match, functionName, _, param) => {
        switch (functionName) {
          case 'FLOOR':
            // Redondear el valor
            return `Math.floor(${param})`;
          case 'ROUND':
            // Redondear el valor
            return `Math.round(${param})`;
          case 'CEIL':
            // Redondear el valor
            return `Math.ceil(${param})`;
          default:
            return match; // Devolver la expresión original si no es soportada
        }
      });
    }

    return formula;
  };

  const CalculateValue = (formulaWithValues: string): number => {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const dateRegex = /new Date\("([^"]+)"\)/g;

    // Paso 1: Convertir todas las fechas a milisegundos
    let processedFormula = formulaWithValues;
    processedFormula = processedFormula.replace(dateRegex, (match, p1) => {
      const dateObj = new Date(p1);
      return dateObj.getTime().toString();
    });

    // Paso 2: Reemplazar diferencias de fechas en milisegundos por días
    const diffRegex = /(\d+)\s*-\s*(\d+)/g;
    processedFormula = processedFormula.replace(diffRegex, (match, p1, p2) => {
      if (p1.length > 10 && p2.length > 10) { // Fechas en milisegundos
        const diffInMs = parseInt(p1) - parseInt(p2);
        return (diffInMs / millisecondsPerDay).toString();
      }
      return match;
    });

    // Paso 3: Evaluar la fórmula procesada
    return new Function(`return ${processedFormula};`)();
  };

  // Función para evaluar la fórmula
  const EvaluateFormula = async (formula: string, fields: ProjectExtraField[], myParameters: Parameter[]): Promise<number> => {
    const formulaWithValues = replaceVariables(formula, fields, myParameters);
    return CalculateValue(formulaWithValues);
  };


  const calculateDate = (expression: string): number | string => {
    const dateRegex = /new Date\("([^"]+)"\)|new Date\(\)/g;
  
    try {
      // Paso 1: Convertir todas las fechas a milisegundos
      let processedExpression = expression;
      processedExpression = processedExpression.replace(dateRegex, (match, p1) => {
        const dateObj = p1 ? new Date(p1) : new Date();
        if (isNaN(dateObj.getTime())) {
          throw new Error(`Fecha inválida: ${p1}`);
        }
        return dateObj.getTime().toString();
      });
  
      // Paso 2: Reemplazar operaciones entre fechas y días
      const diffAndAdditionRegex = /(\d+)\s*([\+\-])\s*(\d+)/g;
      processedExpression = processedExpression.replace(diffAndAdditionRegex, (match, p1, operator, p2) => {
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
  
        const num1 = parseInt(p1);
        const num2 = parseInt(p2);
  
        if (num1 > 10 ** 10 && num2 < 1000) { // num1 es timestamp y num2 es días
          // Convertir `num2` a milisegundos y aplicar la operación
          const resultDate = operator === '-' ? num1 - num2 * millisecondsPerDay : num1 + num2 * millisecondsPerDay;
          return resultDate.toString();
        } else if (num1 > 10 ** 10 && num2 > 10 ** 10 && operator === '-') { // Ambos son timestamps, calcular diferencia en días
          const diffInMs = num1 - num2;
          return (diffInMs / millisecondsPerDay).toString();
        }
  
        // Para otros casos, devolver la operación original
        return `${num1} ${operator} ${num2}`;
      });
  
      // Validación adicional: Verificar si hay variables no procesadas
      if (/{[^}]+}/.test(processedExpression)) {
        console.warn(`La expresión contiene variables sin sustituir: ${processedExpression}`);
        return "fecha sin calcular";
      }
  
      // Paso 3: Evaluar la expresión procesada
      return new Function(`return ${processedExpression};`)();
    } catch (error:any) {
      console.error(`Error en calculateDate: ${error.message}`);
      return "fecha sin calcular"; // Devuelve un mensaje específico en caso de error
    }
  };

  return {
    EvaluateFormula,
    fetchParameters,
    calculateDate,
    replaceVariables
  };
};
