import { Autocomplete, Box, TextField, Typography } from "@mui/material"
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../api/GenericPromises";
import { Header } from "../../../../components/Header";
import { ProjectType } from "../../../../interfaces/Projects/Catalogs/projectTypes";
import InfoIcon from '@mui/icons-material/Info';
import { User } from "../../../../interfaces/Security/users";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { myStylePickers } from "../../../../theme/buttons";
import { Project } from "../../../../interfaces/Projects/projects";

type CreateProjectFromSalesOrderProps = {
  myProjectLocal: Project,
  setMyProjectLocal: Dispatch<SetStateAction<Project>>,
  customerId: number,
  customerIdentifier: string,
}

export const CreateProjectFromSalesOrder = ({
  myProjectLocal,
  setMyProjectLocal,
  customerId,
  customerIdentifier,
}: CreateProjectFromSalesOrderProps) => {
  const [t] = useTranslation("global");
  const { GenericGetResource, GenericGetResourceGeneric } = GenericPromises();
  const [openStartDateDatePicker, setOpenStartDateDatePicker] = useState(false);
  const [openEndDateDatePicker, setOpenEndDateDatePicker] = useState(false);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [comboProjectTypes, setComboProjectTypes] = useState<ProjectType[]>([]);
  const [comboUsers, setComboUsers] = useState<User[]>([]);

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  }

  useEffect(() => {
    const loadData = async () => {
      await GenericGetResource(`/projecttypes`)
        .then((responseProjectTypes) => {
          setComboProjectTypes(responseProjectTypes.data.data);
        });
      await GenericGetResourceGeneric("/users", "/gusers")
        .then((responseUsers) => {
          setComboUsers(responseUsers.data.data);
        });
    }
    loadData();
  }, []);

  useEffect(() => {
    if (customerId) {
      GenericGetResource(`/projects/getprojectsnumberbycustomerid/${customerId}`)
        .then((response) => {
          const currentYear = new Date().getFullYear();

          const projectNumberRaw = response.data.res + 1;

          let formattedProjectNumber;
          if (projectNumberRaw != null) {
            const projectNumberStr = projectNumberRaw.toString();

            if (projectNumberStr.length === 1) {
              formattedProjectNumber = '00' + projectNumberStr;
            } else if (projectNumberStr.length === 2) {
              formattedProjectNumber = '0' + projectNumberStr;
            } else {
              formattedProjectNumber = projectNumberStr;
            }
          } else {
            formattedProjectNumber = '000';
          }

          const fullPrefix = `${customerIdentifier}-${formattedProjectNumber}-${currentYear}`;
          setMyProjectLocal((prev) => ({ ...prev, ["project_name"]: fullPrefix }));
        })
    } else {
      setMyProjectLocal((prev) => ({ ...prev, ["project_name"]: "" }));
    }
  }, []);

  return (
    <Box marginBlock={1}>
      <Header title={t("projects.title-view")} size="sm" />
      {/* Información se creará el siguiente proyecto: Como se construyen los projects */}
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <Typography>
          <>{t("projects.info.create-project")}</>
          <InfoIcon />
        </Typography>
        <TextField
          size="small"
          value={myProjectLocal.project_name}
          variant="filled"
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
          label={t("projects.fields.project_name")}
          sx={{ width: "50%" }}
        />
      </Box>
      <Box display={"flex"} marginTop={1}>
        <Autocomplete
          size="small"
          sx={{ width: "50%", paddingRight: 1 }}
          options={comboProjectTypes ?? []}
          getOptionLabel={(option: ProjectType) => option.project_type_name ?? ""}
          renderOption={(props, option: ProjectType) => (
            <div key={option.project_type_id}>
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.project_type_name}
              </Box>
            </div>
          )}
          isOptionEqualToValue={(option, value) => option.project_type_id === value.project_type_id}
          onChange={async (_, values) => {
            setMyProjectLocal((prev) => ({ ...prev, ["project_type_id"]: values?.project_type_id }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${t("projecttypes.title-view")}`}
              variant="filled"
            />
          )}
        />
        <Autocomplete
          size="small"
          sx={{ width: "50%" }}
          options={comboUsers}
          getOptionLabel={(option) => `${option.user_name}`}
          renderOption={(props, option: User) => (
            <div key={option.user_id}>
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.user_name}
              </Box>
            </div>
          )}
          isOptionEqualToValue={(option, value) => option.user_id === value.user_id}
          onChange={(_, values) => {
            setMyProjectLocal((prev) => ({ ...prev, ["project_manager_id"]: values?.user_id }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${t("projects.fields.project_manager")}`}
              variant="filled"
            />
          )}
        />
      </Box>
      <Box marginTop={1} display={"flex"}>
        <DatePicker
          open={openStartDateDatePicker}
          onClose={() => setOpenStartDateDatePicker(false)}
          label={`${t("projects.fields.project_start_date")}`}
          value={startDate ? dayjs(startDate) : null}
          onChange={(values: any) => {
            setMyProjectLocal((prev) => ({ ...prev, ["project_start_date"]: values?.toDate() }));
            setStartDate((prev) => values?.toDate());
          }}
          slotProps={{
            popper: {
              sx: myStylePickers,
            },
            textField: {
              variant: "filled",
              size: "small",
              fullWidth: true,
              onClick: () => setOpenStartDateDatePicker(true),
              onBeforeInput: disableKeyboardEntry,
            }
          }}
          format="DD/MM/YYYY"
          sx={{ width: "50%", paddingRight: 1 }}
        />
        <DatePicker
          open={openEndDateDatePicker}
          onClose={() => setOpenEndDateDatePicker(false)}
          label={`${t("projects.fields.project_end_date")}`}
          onChange={(values: any) => {
            setMyProjectLocal((prev) => ({ ...prev, ["project_end_date"]: values?.toDate() }));
            setEndDate((prev) => values?.toDate());
          }}
          value={endDate ? dayjs(endDate) : null}
          slotProps={{
            popper: {
              sx: myStylePickers
            },
            textField: {
              variant: "filled",
              size: "small",
              fullWidth: true,
              onClick: () => setOpenEndDateDatePicker(true),
              onBeforeInput: disableKeyboardEntry,
            }
          }}
          minDate={dayjs(startDate)}
          format="DD/MM/YYYY"
          disabled={(!startDate ? true : false)}
          sx={{ width: "50%" }}
        />
      </Box>
    </Box>
  )
}
