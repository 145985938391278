import { DataGrid, GridColDef, GridColumnMenu, GridColumnMenuColumnsItem, GridColumnMenuProps, GridEventListener, gridPageCountSelector, GridPagination, GridRowModel, GridRowModes, GridRowModesModel, GridRowsProp, GridToolbarContainer, GridValidRowModel, useGridApiContext, useGridSelector } from "@mui/x-data-grid";
import React from "react";
import { useTranslation } from "react-i18next";
import GenericPromises from "../../../api/GenericPromises";
import useSnackBar from "../../Commons/SnackBar/useSnackBar";
import { useParamsFilter } from "../../../hooks/useParamsFilter";
import { CustomerItem } from "../../../interfaces/Sales/Catalogs/Customers/customerItems";
import { Tax } from "../../../interfaces/Sales/Catalogs/taxes";
import { Retention } from "../../../interfaces/Sales/Catalogs/retentions";
import { useLocation } from "react-router-dom";
import { Item } from "../../../interfaces/Commons/items";
import { Unit } from "../../../interfaces/Commons/units";
import { TaxObject } from "../../../interfaces/Sales/Catalogs/taxObjects";
import { Autocomplete, Box, Button, TablePaginationProps, TextField } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { PrimaryButton } from "../../../theme/buttons";
import { DialogEntity } from "../../Dialogs/DialogEntity";
import { Header } from "../../Header";
import { HotAddItem } from "../../HotAdds/Items";
import MuiPagination from '@mui/material/Pagination';
import AddIcon from '@mui/icons-material/Add';
import { CustomColumnOrderSalesInvoice } from "../GridTableMaterialUI/CustomColumnOrderSalesInvoice";
import { SalesOrderDetail } from "../../../interfaces/Sales/Orders/salesOrders";
import { Menuitem } from "../../../interfaces/Security/menu";

type EditableTableProps = {
  rows: readonly GridValidRowModel[],
  columns: GridColDef[],
  nameOrderColumns: string;
  setColumns: React.Dispatch<React.SetStateAction<GridColDef<any>[]>>;
  rowModesModel: GridRowModesModel,
  handleRowModesModelChange: (newRowModesModel: GridRowModesModel) => void,
  handleRowEditStop: GridEventListener<"rowEditStop">,
  processRowUpdate?: (newRow: GridRowModel, oldRow: GridRowModel) => {},
  setRows: React.Dispatch<React.SetStateAction<SalesOrderDetail[]>>,
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>,
  onChangeTotalValues: () => void,
  loadingPut: boolean,
  resourceScreenDetails: Menuitem
}

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
  changeTotalValue: (addValue: number, minusValue: number, prevValue: number) => void,
  includesForeignTradeComplement: boolean,
  loadingPut: boolean,
  onChangeTotalValues: () => void,
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel, onChangeTotalValues } = props;
  const [t] = useTranslation("global");
  const { GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetParamsFromBase64 } = useParamsFilter();
  const [isLoading, setIsloading] = React.useState(false);
  const [isLoadedNewItems, setIsloadedNewItems] = React.useState(false);
  const [openDialogNewItem, setOpenDialogNewItem] = React.useState(false);
  const [inputItem, setInputItem] = React.useState("");
  const [comboItems, setComboItems] = React.useState<CustomerItem[]>([]);
  const [comboTaxes, setComboTaxes] = React.useState<Tax[]>([]);
  const [comboRetentions, setComboRetentions] = React.useState<Retention[]>([]);
  const [myItem, setMyitem] = React.useState<CustomerItem | null>(null);
  const [myTax, setMyTax] = React.useState<Tax | null>(null);
  const [myRetention, setMyRetention] = React.useState<Retention | null>(null);
  const [myQuantity, setMyQuantity] = React.useState<number>();
  const [myPrice, setMyPrice] = React.useState<number>();
  const [myDiscount, setMyDiscount] = React.useState<number>();
  const myQuantityRef = React.useRef<any>(null);
  const location = useLocation();
  const { GenericPostResource } = GenericPromises();

  const getMyCustomerIdFromParams = (): string | undefined => {
    let CustomerId;
    if (location.search.length > 0 && location.state === null) {
      const params = new URLSearchParams(location.search);
      CustomerId = params.get('customer_id');
      return CustomerId ?? undefined;
    }
    return CustomerId;
  }

  const onPostCustomerItem = async (myItem: CustomerItem | Item, isNewItem: boolean = false) => {
    setIsloading(true);
    let myDataCustomerItem = {
      customer_id: ((location.state && location.state.row.customer_id) ?? getMyCustomerIdFromParams()) ?? 0,
      item_id: myItem.item_id,
      item_price: myItem.default_item_price ?? 0,
      customer_reference: null,
      customer_reference_description: null,
    }
    await GenericPostResource("/customeritems", myDataCustomerItem)
      .then(async (responseCustomerItem) => {
        let myNewCustomerItem = await GenericGetResource(`/customeritems/${responseCustomerItem.data.customer_items_id}`);
        if (isNewItem) {
          let newItem = [myNewCustomerItem.data];
          setComboItems((prevState) => [...prevState, ...newItem]);
        }
        else {
          await setComboItems((prev) => prev.map(item =>
            item.item_id === myNewCustomerItem.data.item_id ? myNewCustomerItem.data : item
          ));
        }
        setIsloading(false);
        setInputItem("");
        setOpenDialogNewItem(false);
      }).catch((error) => {
        showSnackBar(error.message, 'error');
        setIsloading(false);
      });
  }

  const onAddNewItem = async (newItem: Item) => {
    await onPostCustomerItem(newItem, true);
  };

  const onAddItemsInput = async () => {
    setIsloadedNewItems(true);
    setIsloading(true);
    await GenericGetResource(`/items`)
      .then(async (responseItems) => {
        // load items con info de customer items para que lo tome el arreglo
        let CustomerId;
        if (location.search.length > 0 && location.state === null) {
          const params = new URLSearchParams(location.search);
          CustomerId = params.get('customer_id');
        }
        let myNewItems = responseItems.data.data;
        let myNewOptions: CustomerItem[] = [];

        // get units
        let myUnitsResponse = await GenericGetResource(`/units`);
        let myUnits: Unit[] = myUnitsResponse.data.data;
        // get taxobjects
        let myTaxObjectsResponse = await GenericGetResource(`/taxobjects`);
        let myTaxObjects: TaxObject[] = myTaxObjectsResponse.data.data;

        // bucle
        for (let i = 0; i < myNewItems.length; i++) {
          const element: Item = myNewItems[i];
          if (comboItems.find((item) => item.item_id === element.item_id) === undefined) {
            let myUnit = myUnits.find((item) => item.unit_id === element.unit_id);
            let myTaxObject = myTaxObjects.find((item) => item.tax_object_id === element.tax_object_id);
            let myTaxLocal = comboTaxes.find((item) => item.tax_id === element.tax_id);
            let myRetentionLocal = comboRetentions.find((item) => item.retention_id === element.retention_id);
            let myNewCustomerItem: CustomerItem = {
              customer_items_id: -1 * (i + 1),
              customer_id: ((location.state && location.state.row.customer_id) ?? CustomerId) ?? 0,
              item_id: element.item_id,
              item_price: element.default_item_price ?? 0,
              customer_reference: '',
              customer_reference_description: '',
              item_code: element.item_code ?? '',
              item_description: element.item_description ?? '',
              product_service_key_id: element.product_service_key_id,
              product_service_key: element.product_service_key,
              tax_object_id: myTaxObject?.tax_object_id,
              tax_object: myTaxObject?.tax_object,
              unit_id: myUnit?.unit_id,
              unit_code: myUnit?.unit_code,
              unit_description: myUnit?.unit_description,
              unit_symbol: myUnit?.unit_symbol,
              international_unit_symbol: myUnit?.international_unit_symbol,
              product_service_key_could_be_hazardous_material: element.product_service_key_could_be_hazardous_material,
              tax_id: myTaxLocal?.tax_id,
              tax_name: myTaxLocal?.tax_name,
              tax_code: myTaxLocal?.tax_code,
              tax_percent: myTaxLocal?.tax_percent,
              retention_id: myRetentionLocal?.retention_id,
              retention_name: myRetentionLocal?.retention_name,
              retention_code: myRetentionLocal?.retention_code,
              retention_percent: myRetentionLocal?.retention_percent,
              tariff_fraction_id: element.tariff_fraction_id,
            }
            myNewOptions.push(myNewCustomerItem);
          }
        }
        // asignación
        setComboItems((prevState) => [...prevState, ...myNewOptions]);
        setIsloading(false);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        setIsloadedNewItems(false);
        setIsloading(false);
      });
  }

  React.useEffect(() => {
    let customerId;
    if (location.search.length > 0 && location.state === null) {
      let myParams = GetParamsFromBase64();
      customerId = myParams.get("customer_id");
    }
    let myPromises = [
      GenericGetResource(`/customeritems/bycustomerid/${((location.state && location.state.row.customer_id) ?? customerId) ?? 0}`),
      GenericGetResource(`/taxes`),
      GenericGetResource(`/retentions`),
    ];
    Promise.all(myPromises)
      .then((responses) => {
        setComboItems(responses[0].data.data);
        setComboTaxes(responses[1].data.data);
        setComboRetentions(responses[2].data.data);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  const handleChange = (e: any, value: any) => {
    if (value === null) {
      setMyQuantity((prev) => 0);
      setMyPrice((prev) => 0);
    }
    else {
      myItem && myQuantityRef.current && myQuantityRef.current.focus()
      setMyPrice(comboItems.find((item: CustomerItem) => item.customer_items_id === value.customer_items_id)?.item_price ?? 0);
      setMyTax(comboTaxes.find((item: Tax) => item.tax_id === value.tax_id) ?? null);
      setMyRetention(comboRetentions.find((item: Retention) => item.retention_id === value.retention_id) ?? null);
    }
    setMyitem((prev) => value);
  };

  const handleChangeTax = (e: any, value: any) => {
    setMyTax((prev) => value);
  }

  const handleChangeRetention = (e: any, value: any) => {
    setMyRetention((prev) => value);
  }

  const handleChangeTextfields = (e: any) => {
    let { name, value } = e.target;
    if (name === 'price') {
      setMyPrice((prev) => value)
    }
    else if (name === 'quantity') {
      setMyQuantity((prev) => value);
    }
    else if (name === 'discount') {
      setMyDiscount((prev) => value);
    }
  }

  const AddRecord = () => {
    if (
      (myQuantity === undefined || myQuantity <= 0) ||
      (myPrice === undefined || myPrice <= 0) ||
      (parseFloat(myDiscount?.toString() ?? "") < 0) ||
      (parseFloat(myDiscount?.toString() ?? "") > 100)
    ) {
      showSnackBar(t("salesorderdetails.snackbar.warning"), "warning");
    }
    else {
      setIsloading(true);
      let mySalesOrderDetail = {
        quantity: myQuantity,
        price_by_unit: myPrice,
        discount: myDiscount ?? 0,
        subtotal_tax_amount: null,
        subtotal_amount: 0,
        total_amount: myQuantity * myPrice,
        tax_id: myTax?.tax_id ?? null,
        tax_name: myTax?.tax_name ?? null,
        tax_percent: myTax?.tax_percent ?? null,
        tax_code: myTax?.tax_code ?? null,
        name_item_family: null,
        name_item_type: null,
        item_weight: myItem?.weight ?? null,
        item_description: myItem?.item_description,
        item_code: myItem?.item_code,
        item_id: myItem?.item_id,
        unit_id: myItem?.unit_id,
        unit_code: myItem?.unit_code,
        unit_description: myItem?.unit_description,
        unit_symbol: myItem?.unit_symbol,
        international_unit_symbol: myItem?.international_unit_symbol,
        tax_object: myItem?.tax_object,
        product_service_key: myItem?.product_service_key,
        retention_id: myRetention?.retention_id ?? null,
        retention_code: myRetention?.retention_code ?? null,
        retention_name: myRetention?.retention_name ?? null,
        retention_percent: myRetention?.retention_percent ?? null,
        tariff_fraction_id: null,
        tariff_fraction_code: null,
        tariff_fraction_general_description: null,
        tariff_fraction_specific_description: null,
        tariff_fraction_unit_id: null,
        tariff_fraction_unit_code: null,
        tariff_fraction_unit_symbol: null,
        sales_order_id: location.state.row.sales_order_id,
        sales_preorder_detail_id: null,
      }
      GenericPostResource("/salesorderdetails", mySalesOrderDetail)
        .then(async (response) => {
          if (myItem?.customer_items_id! < 0) {
            await onPostCustomerItem(response.data, false);
          }
          await setRows((oldRows) => [...oldRows, {
            ...response.data,
            sales_order_detail_id: response.data.sales_order_detail_id,
            subtotal_amount: response.data.subtotal_amount,
          }]);
          setRowModesModel((oldModel) => ({
            ...oldModel,
            [response.data.sales_order_detail_id]: { mode: GridRowModes.View },
          }));
          await onChangeTotalValues();
          setIsloading(false);
          setMyitem((prev) => null);
          setMyQuantity((prev) => undefined);
          setMyPrice((prev) => undefined);
          setMyDiscount((prev) => undefined);
        }).catch((error) => {
          showSnackBar(error.message, "error");
          setIsloading(false);
        });
    }
  }

  return (
    <GridToolbarContainer className='d-flex p-1 w-100'>

      <>
        <Autocomplete
          value={myItem || null}
          size="small"
          sx={{ flex: 2 }}
          options={comboItems}
          getOptionLabel={(option) => `${option.item_code} - ${option.item_description}`}
          renderOption={(props, option: CustomerItem) => (
            <div key={option.customer_items_id}>
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.item_code} - {option.item_description}
              </Box>
            </div>
          )}
          onInputChange={(event: any) => {
            if (event && event.target && event.target.value && event.target.value.length) {
              setInputItem(event.target.value);
            }
            else {
              setInputItem("");
            }
          }}
          noOptionsText={
            <>
              {!isLoadedNewItems &&
                <Button onClick={onAddItemsInput} disabled={isLoadedNewItems}>
                  {t("invoices.actions.add-items")}
                </Button>}
            </>
          }
          isOptionEqualToValue={(option, value) => option.customer_items_id === value.customer_items_id}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${t("items.title-view")}`}
              variant="filled"
            />
          )}
          disabled={isLoading}
        />
        {!myItem &&
          <Button variant='outlined' disabled={inputItem.length < 1} onClick={() => setOpenDialogNewItem(true)}>
            <AddCircleIcon fontSize="large" />
          </Button>
        }
        {myItem &&
          <div className='w-75 d-flex'>
            <TextField
              inputRef={myQuantityRef}
              variant="filled"
              name="quantity"
              label={`${t("salesorderdetails.fields.quantity")}`}
              value={myQuantity || undefined}
              onChange={handleChangeTextfields}
              size="small"
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                "& input[type=number]": { MozAppearance: "textfield", }, flex: 1, paddingRight: 1
              }}
              type="number"
              disabled={isLoading}
            />
            <TextField
              variant="filled"
              name="price"
              label={`${t("salesorderdetails.fields.price")}`}
              value={myPrice || undefined}
              onChange={handleChangeTextfields}
              size="small"
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                "& input[type=number]": { MozAppearance: "textfield", }, flex: 1, paddingRight: 1
              }}
              type="number"
              disabled={isLoading}
            />
            <Autocomplete
              value={myTax || null}
              size="small"
              sx={{ flex: 1, paddingRight: 1 }}
              options={comboTaxes}
              getOptionLabel={(option) => `${option.tax_name} - ${option.tax_percent}`}
              defaultValue={undefined}
              renderOption={(props, option: Tax) => (
                <div key={option.tax_id}>
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.tax_name} - {option.tax_percent}
                  </Box>
                </div>
              )}
              isOptionEqualToValue={(option, value) => option.tax_id === value.tax_id}
              onChange={handleChangeTax}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${t("taxes.title-view")}`}
                  variant="filled"
                />
              )}
              disabled={isLoading}
            />
            <Autocomplete
              value={myRetention || null}
              size="small"
              sx={{ flex: 1, paddingRight: 1 }}
              options={comboRetentions}
              getOptionLabel={(option) => `${option.retention_name} - ${option.retention_percent}`}
              defaultValue={undefined}
              renderOption={(props, option: Retention) => (
                <div key={option.retention_id}>
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.retention_name} - {option.retention_percent}
                  </Box>
                </div>
              )}
              isOptionEqualToValue={(option, value) => option.retention_id === value.retention_id}
              onChange={handleChangeRetention}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${t("retentions.title-view")}`}
                  variant="filled"
                />
              )}
              disabled={isLoading}
            />
            <TextField
              variant="filled"
              name="discount"
              label={`${t("salesorders.fields.discount")}`}
              value={myDiscount || undefined}
              onChange={handleChangeTextfields}
              size="small"
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                "& input[type=number]": { MozAppearance: "textfield", }, flex: 1, paddingRight: 1
              }}
              type="number"
              disabled={isLoading}
            />
            <PrimaryButton disabled={isLoading} variant='outlined' sx={{ flex: 1, padding: 1 }} onClick={AddRecord}><AddIcon /></PrimaryButton>
          </div>
        }
      </>

      <SnackbarComponent />
      <DialogEntity
        title={<Header title={t("items.title-view")} />}
        content={
          <HotAddItem
            label={inputItem}
            onAddNewItem={onAddNewItem}
            setOpenDialog={setOpenDialogNewItem}
            setLabel={setInputItem}
            showSnackBar={showSnackBar}
          />
        }
        open={openDialogNewItem}
        fullWidth
      />
    </GridToolbarContainer>
  );
}

function Pagination({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      variant='outlined'
      sx={{
        '& .MuiPaginationItem-root:hover': {
          color: 'secondary',
          backgroundColor: 'primary',
          border: 'none',
        }
      }}
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
    />
  );
}

function CustomPagination(props: any) {
  const [t] = useTranslation("global");
  return <div className='d-flex'>
    <GridPagination labelRowsPerPage={t("generic.grid-table.rows-per-page")} ActionsComponent={Pagination} {...props} />
  </div>
}

export default function TableSalesOrderDetail({
  rows,
  columns,
  setColumns,
  nameOrderColumns,
  rowModesModel,
  handleRowModesModelChange,
  handleRowEditStop,
  processRowUpdate,
  setRows,
  setRowModesModel,
  onChangeTotalValues,
  resourceScreenDetails,
}: EditableTableProps) {
  const [t] = useTranslation("global");


  function CustomColumnsItems({ ...props }) {
    return <div>
      <CustomColumnOrderSalesInvoice columns={columns} setColumns={setColumns} nameOrderColumns={nameOrderColumns} />
      <GridColumnMenuColumnsItem colDef={props.colDef} onClick={props.onClick} />
    </div>
  }

  function CustomColumnMenuComponent(props: GridColumnMenuProps) {
    return (
      <GridColumnMenu
        {...props}
        slots={{
          columnMenuColumnsItem: CustomColumnsItems,
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        sx={{
          '& .MuiDataGrid-row:hover': {
            color: 'primary.main',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            color: 'ActiveCaption',
            outline: 'none',
            border: 'none'
          },
          '& .MuiDataGrid-columnHeader--showColumnBorder': {
            outline: 'none',
            bordere: 'none'
          },
          ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
            marginTop: ".5em",
            marginBottom: ".5em",
          }
        }}
        localeText={{
          toolbarExport: t("generic.grid-table.toolbar-export"),
          toolbarExportCSV: t("generic.grid-table.toolbar-export-csv"),
          columnMenuUnsort: t("generic.grid-table.column-menu-unsort"),
          columnMenuSortAsc: t("generic.grid-table.column-menu-sort-asc"),
          columnMenuSortDesc: t("generic.grid-table.column-menu-sort-desc"),
          columnMenuFilter: t("generic.grid-table.column-menu-filter"),
          columnMenuHideColumn: t("generic.grid-table.column-menu-hide-column"),
          columnMenuShowColumns: t("generic.grid-table.column-menu-show-columns"),
          columnMenuManageColumns: t("generic.grid-table.column-menu-manage-columns"),
          filterOperatorContains: t("generic.grid-table.filter-operator-contains"),
          filterOperatorEquals: t("generic.grid-table.filter-operator-equals"),
          filterOperatorStartsWith: t("generic.grid-table.filter-operator-starts-with"),
          filterOperatorEndsWith: t("generic.grid-table.filter-operator-ends-with"),
          filterOperatorIsEmpty: t("generic.grid-table.filter-operator-is-empty"),
          filterOperatorIsNotEmpty: t("generic.grid-table.filter-operator-is-not-empty"),
          filterOperatorIsAnyOf: t("generic.grid-table.filter-operator-is-any-of"),
        }}
        getRowId={(row) => row["sales_order_detail_id"]}
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
        slots={{
          columnMenu: CustomColumnMenuComponent,
          toolbar: resourceScreenDetails.create ? EditToolbar : undefined,
          pagination: CustomPagination,
        }}
        slotProps={{
          ...(resourceScreenDetails.create && {
            toolbar: {
              setRows,
              setRowModesModel,
              resourceScreenDetails,
              onChangeTotalValues,
            },
          }),
        }}
        pageSizeOptions={[5, 10]}
        autoHeight
      />
    </Box>
  );
}