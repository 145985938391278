import { Control, Controller, FieldErrors, UseFormGetValues, useWatch } from "react-hook-form"
import { SalesPreorder } from "../../../../interfaces/Sales/Preorders/SalesPreorders"
import { Box, Button, IconButton, TextField, Typography } from "@mui/material"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { DialogEntity } from "../../../../components/Dialogs/DialogEntity";
import { ButtonLoading } from '../../../../theme/buttons';
import EditIcon from '@mui/icons-material/Edit';

type DocumentsSalesPreorderProps = {
  getValues: UseFormGetValues<SalesPreorder>
  isEdit: boolean,
  control: Control<SalesPreorder, any>,
  errors: FieldErrors<SalesPreorder>,
  onPut: (data: SalesPreorder) => Promise<void>,
}

export const DocumentsSalesPreorder = ({
  getValues,
  isEdit,
  control,
  errors,
  onPut,
}: DocumentsSalesPreorderProps) => {
  const { GetError } = FormsErrors();
  const [t] = useTranslation("global");
  const urlFiles = useWatch({ control, name: "url_files" });
  const [openEditLink, setOpenEditLink] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

  const onUpdate = async () => {
    setIsLoadingUpdate(true);
    onPut(getValues())
      .then(() => {
        setOpenEditLink(false);
      })
      .finally(() => {
        setIsLoadingUpdate(false);
      });
  }

  return (
    <div className="d-flex w-100 custom-card-view">
      <Button
        disabled={(urlFiles === null || urlFiles === undefined) || isEdit}
        onClick={() => {
          const urlFiles = getValues("url_files");
          if (urlFiles) {
            window.open(urlFiles, '_blank');
          }
        }}
      >
        <Typography fontWeight={"bold"}>
          {getValues("sales_preorder_name")}
        </Typography>
        <OpenInNewIcon />
      </Button>
      <IconButton
        disabled={isEdit}
        onClick={() => {
          setOpenEditLink(true);
        }}
      >
        <EditIcon />
      </IconButton>

      <DialogEntity
        open={openEditLink}
        title={<></>}
        content={
          <Box width={600}>
            <Controller
              name="url_files"
              control={control}
              rules={{}}
              render={({ field }) => (
                <TextField
                  variant="filled"
                  label={`${t("salespreorders.fields.url_files")}`}
                  ref={field.ref}
                  value={field.value}
                  onChange={(event) => { field.onChange(event.target.value) }}
                  error={!!errors.url_files}
                  helperText={GetError(errors.url_files?.type)}
                  size="small"
                  sx={{ width: "100%" }}
                  disabled={isEdit}
                />
              )}
            />
            <Box display={"flex"} flexDirection={"row-reverse"} mt={2}>
              <ButtonLoading isLoading={isLoadingUpdate} variant="outlined" onClick={onUpdate}>{t("generic.buttons.update")}</ButtonLoading>
              <Button variant="outlined" onClick={() => setOpenEditLink(false)}>{t("generic.buttons.cancel")}</Button>
            </Box>
          </Box>
        }
      />
    </div>
  )
}
