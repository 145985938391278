import { useState, useEffect, useCallback } from "react";
import GenericPromises from "../../api/GenericPromises";
import { useFormulaEvaluator } from "../useFormulaEvaluator";
import { ProjectTask } from "../../interfaces/Projects/Catalogs/projectTasks";
import { ProjectTaskDashboard } from "../../interfaces/Projects/projectTaskDashboard";
import { Parameter } from "../../interfaces/Commons/parameters";
import { Project } from "../../interfaces/Projects/projects";
import { ProjectExtraField } from "../../interfaces/Projects/Catalogs/projectExtraFields";


interface UseCalculateDueDateProps {
    task: ProjectTask | ProjectTaskDashboard
    parameters: Parameter[];
    project: Project;
}

export const useCalculateDueDate = () => {
    const { GenericGetResource } = GenericPromises();
    const { calculateDate, replaceVariables } = useFormulaEvaluator();


    const calculateDueDate = useCallback(async (
        task: ProjectTask | ProjectTaskDashboard,
        parameters: Parameter[],
        projectExtraFieldsData?: ProjectExtraField[],
        project?: Project
    ) => {
        if (!task.project_task_due_date) {
            return null;
        }

        try {
            if (task.field_type_id === 4 && typeof task.project_task_due_date === "string") {
                let extraFieldsData = projectExtraFieldsData;

                if (!extraFieldsData && project) {
                    const extraFieldsResponse = await GenericGetResource(
                        `/projectextrafields/byprojectid/${project.project_id}`
                    );
                    extraFieldsData = extraFieldsResponse.data.data;
                }

                if (!extraFieldsData) {
                    return null; 
                }

                const formulaVariables = replaceVariables(
                    task.project_task_due_date,
                    extraFieldsData,
                    parameters
                );

                const calculatedDate = calculateDate(formulaVariables);
                return typeof calculatedDate === "number"
                    ? new Date(calculatedDate).toString()
                    : new Date(calculatedDate).toString();
            } else {
                return new Date(task.project_task_due_date).toString();
            }
        } catch (err) {
            return null;
        }
    }, []);


    return {
        calculateDueDate,
    };
};
