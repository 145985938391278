import * as React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import "../AccordionMenu/index.css"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from 'react-router-dom';
import { AccordionMenu } from '../AccordionMenu';
import { AuthContext } from '../../../context/AuthContext';
import PopMenu from './PopMenu';
import { AccordionItemMenu } from '../AccordionMenu/AccordionItem';
import { alpha, createTheme, FormControl, MenuItem, Select, Typography } from '@mui/material';
import GenericPromises from '../../../api/GenericPromises';
import { UserCompanies } from '../../../interfaces/Security/userCompanies';
import { useTranslation } from 'react-i18next';
import { FileSQL } from '../../../interfaces/Commons/files';
import { defaultTheme } from '../../../theme/theme';
import { Spinner } from '../../Commons/Spinner/Spinner';
import Swal from 'sweetalert2';
import { DialogChangeCompany } from './helpers/dialogChangeCompany';
import { useTheme } from '@mui/material/styles';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function SideMenuMaterialUI({ ...props }) {
    const [open, setOpen] = React.useState(false);
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [companyValue] = React.useState();
    const [CompanyLogo, setCompanyLogo] = React.useState<FileSQL>({});
    const [companyLocal, setCompanyLocal] = React.useState<string | number>("");
    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);
    const [widthWindow, setWidthWindow] = React.useState(window.innerWidth);
    const { GenericGetResource, GenericGetResourceGeneric } = GenericPromises();
    const [comboUserCompanies, setComboUserCompanies] = React.useState<UserCompanies[]>([]);
    const [t] = useTranslation("global");
    const navigate = useNavigate();
    const theme = useTheme();
    const [isLoading, setIsLoading] = React.useState(false);

    const { user, changeCompany } = React.useContext(AuthContext);

    const onChangeCompany = async () => {
        setIsLoading(true);
        let schema = comboUserCompanies.find((item) => item.schema_name === companyLocal)?.schema_name;
        await changeCompany({ email: user?.user_email ?? "", password: user?.password ?? "", SchemaName: schema }, () => { }, false)
            .then(() => {
                localStorage.setItem("schemaName", schema ?? "");
                navigate('/home', { replace: true });
                setOpenDialog(false);
                setIsLoading(false);
                window.location.reload();
            }).catch((error) => {
                var message = "login.errors.ERR_NETWORK";
                if (error.response.data.errorMessage)
                    message = error.response.data.errorMessage
                Swal.fire({
                    customClass: { container: "swalfire" },
                    title: `${t(message)}`,
                    icon: "warning",
                    confirmButtonColor: theme.palette.primary.main,
                    allowOutsideClick: false
                });
                setIsLoading(false);
            });
    }

    const getImageDimensions = (dataURL: string) => {
        const img = new Image();
        img.onload = () => {
            (img.width > 150) ? setWidth(150) : setWidth(img.width);
            (img.height > 40) ? setHeight(40) : setHeight(img.height);
        };
        img.src = dataURL;
    };

    function changeCompanyTheme(responseCompany: any) {
        let primary = responseCompany.data.primary_color;
        let secondary = responseCompany.data.secondary_color;
        let newTheme = createTheme({
            palette: {
                primary: {
                    main: primary,
                },
                secondary: {
                    main: secondary,
                },
            },
            components: {
                MuiPopper: {
                    styleOverrides: {
                        root: {
                            ...{
                                '& .MuiPickersDay-root.Mui-selected': {
                                    backgroundColor: 'primary.light',
                                    '&:hover': {
                                        backgroundColor: 'primary.dark',
                                        color: 'white',
                                    },
                                    '&:focus': {
                                        backgroundColor: 'primary.dark',
                                        color: 'white',
                                    },
                                },
                            }
                        },
                    }
                }
            }
        })
        defaultTheme.palette.primary.main = newTheme.palette.primary.main;
        defaultTheme.palette.secondary.main = newTheme.palette.secondary.main;
        defaultTheme.palette.primary.dark = newTheme.palette.primary.dark;
        defaultTheme.palette.primary.light = alpha(newTheme.palette.primary.light, 0.5);
    }

    // GET COMPANY INFO
    React.useEffect(() => {
        GenericGetResourceGeneric(`/companies/1`, "/gcompanies")
            .then(async (responseCompany) => {
                changeCompanyTheme(responseCompany);
                await GenericGetResourceGeneric(`/usercompanies/byuserid/${user?.user_id}`, "/gusercompanies")
                    .then((response) => {
                        setComboUserCompanies((prev) => (response.data.data));;
                    })
                await GenericGetResourceGeneric(`/companyconfigurations/bycompanyid/1`, "/gcompanyconfigurations")
                    .then(async (reponseConfig) => {
                        localStorage.setItem("time_zone", reponseConfig.data.data[0].time_zone);
                    });
                if (responseCompany.data.icon_image) {
                    await GenericGetResource(`/filessql/${responseCompany.data.icon_image}`)
                        .then(async (responseIconImage) => {
                            const favicon = document.getElementById('favIconImage') as HTMLLinkElement;
                            if (favicon) {
                                if (responseIconImage && responseIconImage.data.data_file) {
                                    favicon.href = responseIconImage.data.data_file;
                                }
                            }
                        });
                }
                if (responseCompany.data.top_bar_image) {
                    await GenericGetResource(`/filessql/${responseCompany.data.top_bar_image}`)
                        .then(async (responseTopBarImage) => {
                            getImageDimensions(responseTopBarImage.data.data_file ?? "")
                            await setCompanyLogo((prev) => responseTopBarImage.data);
                        });
                }
                setDataLoaded(true)
            }).catch((error) => {
                console.log(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onCancelChangeCompany = () => {
        setOpenDialog(false);
        setIsLoading(false);
    }

    React.useEffect(() => {
        const handleResize = () => {
            setWidthWindow(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

    }, []);


    return (
        <>
            {!dataLoaded && <Spinner />}
            {dataLoaded &&
                <Box sx={{
                    display: 'flex',
                    overflowX: 'hidden',
                    '& > *': {
                        minWidth: 0,
                    }
                }}>
                    <CssBaseline />
                    <AppBar position="fixed" elevation={0}>
                        <Toolbar className='d-flex justify-content-between'>
                            <div className={`w-${widthWindow > 460 ? '50' : '25'} d-flex`}>
                                <IconButton
                                    sx={{ color: 'white' }}
                                    aria-label="open drawer"
                                    onClick={() => setOpen(!open)}
                                    edge="start"
                                >
                                    <MenuIcon />
                                </IconButton>
                                {widthWindow > 460 &&
                                    <Link to={"/home"}>
                                        <div className="d-inline-block align-text-top mx-3" >
                                            <img alt="" style={{ width: `${width}px`, height: `${height}px` }} src={CompanyLogo.data_file ?? ""} />
                                        </div>
                                    </Link>
                                }
                            </div>
                            <Box className='d-flex justify-content-end align-items-center w-75'>
                                {widthWindow > 750 &&
                                    <Typography style={{ color: 'white', textAlign: 'center' }}>{user?.user_email}</Typography>
                                }
                                <FormControl className={`m-1 w-${widthWindow < 750 ? '100' : '50'}`}>
                                    <Select
                                        style={{ color: 'white', textAlign: 'center' }}
                                        sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 1, color: 'white' }, '.MuiSelect-icon': { color: 'white' } }}
                                        value={companyValue}
                                        onChange={(e) => {
                                            setCompanyLocal(e.target.value);
                                            setOpenDialog(true);
                                        }}
                                        defaultValue={comboUserCompanies.find((item) => item.schema_name!.toLocaleLowerCase() === localStorage.getItem("schemaName")?.toLocaleLowerCase())?.schema_name ?? ""}
                                    >
                                        {comboUserCompanies.map((item: UserCompanies, index) => {
                                            return <MenuItem key={index} value={item.schema_name}>{item.business_name}</MenuItem>
                                        }
                                        )}
                                    </Select>
                                </FormControl>
                                <PopMenu />
                            </Box>
                        </Toolbar>
                    </AppBar>
                    <Drawer PaperProps={{ sx: { backgroundColor: "secondary.main" } }} variant="permanent" open={open}>
                        <DrawerHeader />
                        <AccordionItemMenu title='Inicio' resource_name={"Inicio"} open={open} level={1} />
                        <AccordionMenu open={open} />
                    </Drawer>
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <DrawerHeader />
                        {props.router}
                    </Box>
                    <DialogChangeCompany
                        open={openDialog}
                        setOpen={setOpenDialog}
                        title={t("generic.dialogschemas.title")}
                        content={t("generic.dialogschemas.content")}
                        onAcept={onChangeCompany}
                        onCancelChangeCompany={onCancelChangeCompany}
                        isLoading={isLoading}
                    />
                </Box>
            }
        </>
    );
}
