import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Project } from "../../../../interfaces/Projects/projects";
import { ProjectExtraField } from "../../../../interfaces/Projects/Catalogs/projectExtraFields";
import { Menuitem } from "../../../../interfaces/Security/menu";
import {
  AlertColor,
  Box,
  Button,
  Grid,
  Grow,
  TextField,
  Typography,
} from "@mui/material";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors";
import { PrimaryButton } from "../../../../theme/buttons";
import { ProjectExtraFieldsValues } from "../../Projects/projectExtraFieldsValues";
import GenericPromises from "../../../../api/GenericPromises";
import { useParamsFilter } from "../../../../hooks/useParamsFilter";

type UpdateProjectDetails = {
  projectPayload: Project;
  isEditProps: boolean;
  projectExtraFieldsData: ProjectExtraField[];
  setProjectExtraFieldsData: React.Dispatch<
    React.SetStateAction<ProjectExtraField[]>
  >;
  resourceProjectExtraFields: Menuitem | undefined;
  showSnackBar: (message: string, severity: AlertColor) => void;
  isUpdateProjectExtraFields: boolean;
  setIsUpdateProjectExtraFields: Dispatch<SetStateAction<boolean>>;
  setIsClosedProject: Dispatch<SetStateAction<boolean>>;
  isClosedProject: boolean;
};

export const UpdateProjectDetails = ({
  projectPayload,
  isEditProps,
  projectExtraFieldsData,
  setProjectExtraFieldsData,
  resourceProjectExtraFields,
  showSnackBar,
  isUpdateProjectExtraFields,
  setIsUpdateProjectExtraFields,
  setIsClosedProject,
  isClosedProject,
}: UpdateProjectDetails) => {
  const [t] = useTranslation("global");
  const { GetError } = FormsErrors();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [hideDetail, setHideDetail] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const { ConvertParamsToBase64 } = useParamsFilter();
  const isEdit = !isEditProps;
  const {
    GenericPutResource,
  } = GenericPromises();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Project>({
    defaultValues: {
      project_type_id: undefined,
      customer_id: undefined,
      project_manager_id: undefined,
      project_name: undefined,
      project_description: undefined,
      project_comments: undefined,
      project_start_date: undefined,
      project_end_date: undefined,
      MS_project_id: undefined,
      MS_etag: undefined,
      project_is_closed: undefined,
      project_user_closed: undefined,
      project_user_date_closed: undefined,
    },
  });

  const onSubmit: SubmitHandler<Project> = (data) => onPut(data);

  const onPut = (data: Project) => {
    let myData = {
      project_type_id: data.project_type_id,
      customer_id: data.customer_id,
      project_manager_id: data.project_manager_id,
      project_name: data.project_name,
      project_description: data.project_description ?? null,
      project_comments: data.project_comments ?? null,
      project_start_date: data.project_start_date,
      project_end_date: data.project_end_date,
      MS_project_id: data.MS_project_id ?? null,
      MS_etag: data.MS_etag ?? null,
      project_is_closed: data.project_is_closed ?? null,
      project_user_closed: data.project_user_closed ?? null,
      project_user_date_closed: data.project_user_date_closed ?? null,
    };

    setLoadingPost(true);
    GenericPutResource(`/projects/${projectPayload.project_id}`, myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
      })
      .catch((error) => {
        setLoadingPost(false);
        showSnackBar(t(error.response.data), "error");
        setLoadingPost(false);
      });
  };

  const handleOpenCustomer = (event: any) => {
    event.stopPropagation();
    const stringConverted = ConvertParamsToBase64(`customer_id=${projectPayload.customer_id}`);
    window.open(`/customers/view?${stringConverted}`, '_blank');
  };

  useEffect(() => {
    
        if (projectPayload.project_is_closed) {
          setIsClosedProject((prev) => true);
        }

        reset({
          project_type_id: projectPayload.project_type_id,
          customer_id: projectPayload.customer_id,
          project_manager_id: projectPayload.project_manager_id,
          project_name: projectPayload.project_name,
          project_description: projectPayload.project_description ?? undefined,
          project_comments: projectPayload.project_comments ?? undefined,
          project_start_date: projectPayload.project_start_date,
          project_end_date: projectPayload.project_end_date,
          MS_project_id: projectPayload.MS_project_id ?? undefined,
          MS_etag: projectPayload.MS_etag ?? undefined,
          project_is_closed: projectPayload.project_is_closed,
          project_user_closed: projectPayload.project_user_closed ?? undefined,
          project_user_date_closed:
            projectPayload.project_user_date_closed ?? undefined,
        });

        setDataLoaded(true);

  }, []);

  return (
    <>
      {!dataLoaded && <Spinner />}
      {dataLoaded && (
        <div className="d-flex flex-column justify-content-center">
          <div className="custom-card-view w-100 mt-3">
            <Button
              variant="outlined"
              className="d-flex justify-content-between w-100"
              onClick={() => setHideDetail(!hideDetail)}
            >
              <Typography fontWeight={"Bold"} fontSize={22}>
                {projectPayload.project_name} -{' '}
                <span
                  onClick={handleOpenCustomer}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onMouseEnter={(e) => ((e.target as HTMLSpanElement).style.opacity = '0.6')}
                  onMouseLeave={(e) => ((e.target as HTMLSpanElement).style.opacity = '1')}
                >
                  {projectPayload.business_name}
                </span>
              </Typography>
              <>
                {!hideDetail && <KeyboardArrowLeftIcon />}
                {hideDetail && <ExpandMoreIcon />}
              </>
            </Button>

            {hideDetail && (
              <Grow in={hideDetail}>
                <div>
                  <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
                    <div className="custom-card-row d-flex justify-content-between">
                      <div className="w-50">
                        <div className="d-flex w-100">
                          <div className="w-100">
                            <Controller
                              name="project_comments"
                              control={control}
                              rules={{ maxLength: 1000 }}
                              render={({ field }) => (
                                <TextField
                                  variant="filled"
                                  label={`${t(
                                    "projectsdetails.fields.project_comments"
                                  )}`}
                                  ref={field.ref}
                                  value={field.value}
                                  multiline
                                  minRows={10}
                                  onChange={(event) => {
                                    field.onChange(event.target.value);
                                  }}
                                  error={!!errors.project_comments}
                                  helperText={GetError(
                                    errors.project_comments?.type
                                  )}
                                  size="small"
                                  style={{ width: "100%" }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <Box
                        sx={{ marginLeft: 2 }}
                        className="custom-card-view is-child-card w-50"
                        overflow={"scroll"}
                        maxHeight={250}
                      >
                        {!isUpdateProjectExtraFields && (
                          <Spinner isBox={false} />
                        )}
                        {isUpdateProjectExtraFields && (
                          <ProjectExtraFieldsValues
                            projectExtraFieldsData={projectExtraFieldsData}
                            setProjectExtraFieldsData={
                              setProjectExtraFieldsData
                            }
                            resourceScreen={resourceProjectExtraFields}
                            isClosedProject={isClosedProject}
                            setIsUpdateProjectExtraFields={
                              setIsUpdateProjectExtraFields
                            }
                            showSnackBar={showSnackBar}
                          />
                        )}
                      </Box>
                    </div>
                    {!isEdit && (
                      <div className="d-flex flex-row-reverse justify-content-between mt-4">
                        <div className="d-flex flex-row-reverse">
                          <PrimaryButton
                            type="submit"
                            variant="outlined"
                            className="m-1"
                            disabled={
                              isClosedProject ? isClosedProject : loadingPost
                            }
                          >
                            {t("generic.buttons.update")}
                          </PrimaryButton>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </Grow>
            )}
          </div>
        </div>
      )}
    </>
  );
};
