import React, { useEffect, useState } from "react";
import { Spinner } from "../../../../components/Commons/Spinner/Spinner";
import { Header } from "../../../../components/Header";
import { Button } from "@mui/material";
import { TabProjectDocuments } from "../ProjectDocuments/table";
import { useTranslation } from "react-i18next";
import { Menuitem } from "../../../../interfaces/Security/menu";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { useLocation, useNavigate } from "react-router-dom";
import GenericPromises from "../../../../api/GenericPromises";
import { Project } from "../../../../interfaces/Projects/projects";
import { ProjectExtraField } from "../../../../interfaces/Projects/Catalogs/projectExtraFields";
import { usePermissions } from "../../../../hooks/usePermissions";
import { UpdateProjectDetails } from "./update";
import TabsProjectsDetails from "./tabsDetails";
import { TableProjectStages } from "./Details/ProjectStages/table";
import { useParamsFilter } from "../../../../hooks/useParamsFilter";
import { ProjectRelatedInvoices } from "../ProjectRelatedInvoices";
import { Parameter } from "../../../../interfaces/Commons/parameters";

export const ViewProjectDetails = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [resourceProjectExtraFields, setResourceProjectExtraFields] = useState<Menuitem>();
  const [projectLocal, setProjectLocal] = useState<Project>();
  const { GetResourceByUrl } = usePermissions();
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const [projectExtraFieldsData, setProjectExtraFieldsData] = useState<ProjectExtraField[]>([]);
  const [isClosedProject, setIsClosedProject] = useState(false);
  const [isUpdateProjectExtraFields, setIsUpdateProjectExtraFields] = useState(true);
  const [parameters, setParameters] = useState<Parameter[]>([]);
  const location = useLocation();
  const { GetParamsFromBase64 } = useParamsFilter();
  const [resourceSalesInvoices, setResourceSalesInvoices] = useState<Menuitem>();
  const [resourcePurchaseInvoices, setResourcePurchaseInvoices] = useState<Menuitem>();
  const [resourceScreenDocuments, setResourceScreenDocuments] = useState<Menuitem>();

  useEffect(() => {

    GetResourceByUrl(`/projects`)
      .then(async (responsePermissions) => {
        responsePermissions &&
          setResourceScreen((prev) => responsePermissions);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      })
  }, [])

  useEffect(() => {
    if (resourceScreen?.read) {
      let projectId: any;
      if (location.search.length > 0 && location.state === null) {
        let myParams = GetParamsFromBase64();
        projectId = myParams.get("project_id") ?? '';
      }
      if (location.state === null && (!projectId)) return navigate("/dashboard", { state: {}, replace: true });

      GenericGetResource(
        `/projects/${(location.state && location.state.row.project_id ? location.state.row.project_id : projectId)
        }`
      )
        .then(async (responseProject) => {
          setProjectLocal(responseProject.data);
          setIsClosedProject(responseProject.data.project_is_closed);
        })
        .catch((error) => {
          showSnackBar(error.message, "error");
        })
    }
  }, [resourceScreen])


  useEffect(() => {
    GetResourceByUrl(`/projectextrafields`)
      .then(async (responsePermissions) => {
        responsePermissions &&
          setResourceProjectExtraFields((prev) => responsePermissions);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      })
  }, [])

  useEffect(() => {
    if (resourceProjectExtraFields?.read && projectLocal) {
      GenericGetResource(
        `/projectextrafields/byprojectid/${projectLocal.project_id}`
      )
        .then(async (response) => {
          setProjectExtraFieldsData(response.data.data);
        })
        .catch((error) => {
          showSnackBar(error.message, "error");
        })
    }
  }, [resourceProjectExtraFields, projectLocal])


  useEffect(() => {
    GetResourceByUrl(`/purchaseinvoices`)
      .then(async (responsePermissions) => {
        responsePermissions &&
          setResourcePurchaseInvoices((prev) => responsePermissions);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      })

      GetResourceByUrl(`/salesinvoices`)
      .then(async (responsePermissions) => {
        responsePermissions &&
          setResourceSalesInvoices((prev) => responsePermissions);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      })

      GetResourceByUrl(`/projectdocuments`)
      .then(async (responsePermissions) => {
        responsePermissions &&
          setResourceScreenDocuments((prev) => responsePermissions);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      })

      GenericGetResource("/parameters")
      .then(async (response) => {
        setParameters(response.data.data)
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      })
  }, [])


  return (
    <>
      <>
        <Header
          title={t("projectsdetails.title-view")}
          child={
            <div className="d-flex justify-content-end">
              <div className="d-flex flex-row-reverse m-1">
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  {t("generic.buttons.goback")}
                </Button>
              </div>
            </div>
          }
        />
        {resourceScreen?.read && projectLocal &&(
          <>
            <UpdateProjectDetails
              projectPayload={projectLocal ?? {}}
              isEditProps={resourceScreen?.update}
              projectExtraFieldsData={projectExtraFieldsData}
              setProjectExtraFieldsData={setProjectExtraFieldsData}
              resourceProjectExtraFields={resourceProjectExtraFields}
              showSnackBar={showSnackBar}
              isUpdateProjectExtraFields={isUpdateProjectExtraFields}
              setIsUpdateProjectExtraFields={setIsUpdateProjectExtraFields}
              setIsClosedProject={setIsClosedProject}
              isClosedProject={isClosedProject}
            />
            <div className="my-4" />
            <TabsProjectsDetails
              haveDetails={resourceSalesInvoices?.read || resourcePurchaseInvoices?.read}
              isReadDocument={resourceScreenDocuments?.read}
              Details={
                <TableProjectStages
                  projectId={projectLocal?.project_id}
                  projectExtraFieldsData={projectExtraFieldsData}
                  parameters={parameters}
                />
              }
              RelatedInvoices={
                <ProjectRelatedInvoices
                  projectPayload={projectLocal ?? {}}
                  resourceSalesInvoices={resourceSalesInvoices}
                  resourcePurchaseInvoices={resourcePurchaseInvoices}
                  isClosedProject={isClosedProject}
                  purchaseInvoicesData={[]}
                  salesInvoicesData={[]}
                  defaultFilter={[]}
                />
              }
              Documents={
                <TabProjectDocuments
                  showSnackBar={showSnackBar}
                  project_id={projectLocal?.project_id ?? 0}
                  isClosedProject={isClosedProject}
                  resourceScreenDocuments={resourceScreenDocuments}
                />
              }
            />
          </>
        )}
      </>
      <SnackbarComponent />
    </>
  );
};
