import { Box, Button, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { UseFormGetValues } from 'react-hook-form'
import InfoIcon from '@mui/icons-material/Info';
import { ButtonLoading } from '../../../../../../../theme/buttons';
import { ProjectStage } from '../../../../../../../interfaces/Projects/Catalogs/projectStages';

type ConfirmChangeProjectStageStatusDialogProps = {
    isEdit: boolean;
    loadingPut: boolean;
    setDialogConfirmChangeStageState: Dispatch<SetStateAction<boolean>>;
    handleStageCompletion:() => void;
    stageName: string | undefined;
}

export const ConfirmChangeProjectStageStatusDialog = ({
    isEdit,
    loadingPut,
    setDialogConfirmChangeStageState,
    handleStageCompletion,
    stageName,
}: ConfirmChangeProjectStageStatusDialogProps) => {
    const [t] = useTranslation("global");
    const [projectStageName, setProjectStageName] = useState<string>();
    const [isEqualName, setEqualName] = useState(false);

    const handleChange = (e: any) => {
        const value = e.target.value;
        setProjectStageName(value);
        if (stageName === value) {
            setEqualName((prev) => true);
        }
        else {
            setEqualName(false);
        }
    }

    return (
        <Box display={"flex"} flexDirection={"column"} justifyContent={"start"} width={"100%"}>
            <Box width={"100%"} marginBottom={2}>
                {/* <Typography margin={1}>{t("projectstages.info.confirm-stage")}</Typography> */}
                <Typography marginBottom={1}><InfoIcon /> {t("projectstages.info.change-project-state")}</Typography>
                <TextField
                    value={projectStageName}
                    variant='outlined'
                    size='small'
                    onChange={handleChange}
                    label={`${t("projectstages.fields.project_stage_name")} (${stageName})`}
                    required
                    fullWidth
                />
            </Box>
            <Box display={"flex"} flexDirection={"row-reverse"} width={"100%"} gap={1}>
                <ButtonLoading disabled={isEdit || !isEqualName} isLoading={loadingPut} onClick={handleStageCompletion} className="d-flex align-self-end" variant="outlined">{t("generic.buttons.update")}</ButtonLoading>
                <Button variant='outlined' onClick={() => { setDialogConfirmChangeStageState(false); }} disabled={loadingPut}>{t("generic.buttons.goback")}</Button>
            </Box>
        </Box>
    )
}
