import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FormsErrors } from '../../../hooks/Forms/FormsErrors';
import GenericPromises from '../../../api/GenericPromises';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Autocomplete, Box, Switch, TextField, Typography } from '@mui/material';
import { PrimaryButton } from '../../../theme/buttons';
import useSnackBar from '../../../components/Commons/SnackBar/useSnackBar';
import { Parameter } from '../../../interfaces/Commons/parameters';
import { FieldType } from '../../../interfaces/Projects/Catalogs/fieldTypes';

export const UpdateParameter = ({ ...props }) => {
    const [t] = useTranslation("global");
    const { GenericPutResource, GenericGetResource } = GenericPromises();
    const { showSnackBar, SnackbarComponent } = useSnackBar();
    const { GetError } = FormsErrors();
    const [loadingPost, setLoadingPost] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [defaultFieldType, setDefaultFieldType] = useState<FieldType>()
    const [comboFieldTypes, setComboFieldTypes] = useState<FieldType[]>([]);
    const [isBoolean, setIsBoolean] = useState(false);
    const [valueBoolean, setValueBoolean] = useState(false);
    const { parameterPayload } = props;
    const isEdit = !props.isEdit;

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        formState: { errors },
    } = useForm<Parameter>({
        defaultValues: {
            parameter_name: undefined,
            parameter_value: "",
            parameter_id: 0,
        }
    });
    const onSubmit: SubmitHandler<Parameter> = (data) => onPut(data);

    const onPut = (data: Parameter) => {
        let myData = {
            field_type_id: data.field_type_id,
            parameter_name: data.parameter_name ?? null,
            parameter_value: (isBoolean) ? valueBoolean.valueOf().toString() : data.parameter_value,
        }
        setLoadingPost(true);
        GenericPutResource(`/parameters/${parameterPayload.parameter_id}`, myData)
            .then((response) => {
                showSnackBar(t("generic.snackbar.update"), "success");
                setLoadingPost(false);
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, 'error');
                setLoadingPost(false);
            });
    }

    useEffect(() => {
        GenericGetResource("/fieldtypes").then((responseFieldTypes) => {
            if (parameterPayload.field_type_name === 'Boolean') {
                setIsBoolean(true);
                setValueBoolean((parameterPayload.parameter_value === 'true'));
            }
            setComboFieldTypes(responseFieldTypes.data.data);
            let myComboFieldType: FieldType[] = responseFieldTypes.data.data;
            setDefaultFieldType(myComboFieldType.find((fieldType) => parameterPayload.field_type_id === fieldType.field_type_id));

            setDataLoaded(true);
        })

        reset({
            parameter_id: parameterPayload.parameter_id,
            parameter_name: parameterPayload.parameter_name ?? undefined,
            parameter_value: parameterPayload.parameter_value,
            field_type_id: parameterPayload.field_type_id
        });



    }, []);

    return (
        <>
            {dataLoaded &&
                <div className='d-flex justify-content-center'>
                    <div className='custom-card-view w-75'>

                        <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                            <div className="d-flex justify-content-between">
                                <div className='d-flex w-100'>
                                    <div className='w-50'>
                                        <Controller
                                            name="parameter_name"
                                            control={control}
                                            rules={{ maxLength: 200 }}
                                            render={({ field }) =>
                                                <TextField
                                                    variant="filled"
                                                    label={`${t("parameters.fields.parameter_name")}`}
                                                    ref={field.ref}
                                                    value={field.value}
                                                    onChange={(event) => { field.onChange(event.target.value) }}
                                                    error={!!errors.parameter_name}
                                                    helperText={GetError(errors.parameter_name?.type)}
                                                    size="small"
                                                    style={{ width: "100%" }}
                                                    sx={{ paddingRight: 2 }}
                                                    disabled={isEdit}
                                                />
                                            }
                                        />
                                    </div>

                                    <div className='w-50'>
                                        {(isBoolean)
                                            ?
                                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: "space-between", paddingX: 12 }}>
                                                <Typography sx={{ ml: 2 }}>{t("parameters.fields.parameter_value")}</Typography>
                                                <Switch
                                                
                                                    onChange={(event) => {
                                                        if (isBoolean) {
                                                            setValue("parameter_value", event.target.checked.toString())
                                                            setValueBoolean(event.target.checked);
                                                        }
                                                    }}
                                                    checked={valueBoolean}
                                                    disabled={isEdit}
                                                />
                                            </Box>
                                            :
                                            <Controller
                                                name="parameter_value"
                                                control={control}
                                                rules={{ required: true, maxLength: 500 }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant="filled"
                                                        label={`${t("parameters.fields.parameter_value")}`}
                                                        ref={field.ref}
                                                        sx={{
                                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                                                            "& input[type=number]": { MozAppearance: "textfield", },
                                                        }}
                                                        value={field.value}
                                                        onChange={(event) => { field.onChange(event.target.value) }}
                                                        error={!!errors.parameter_value}
                                                        helperText={GetError(errors.parameter_value?.type)}
                                                        size="small"
                                                        style={{ width: "100%" }}
                                                        disabled={isEdit}
                                                    />
                                                }
                                            />
                                        }
                                    </div>

                                </div>
                            </div>

                            <div className='d-flex w-100 mt-2'>
                                <div className="d-flex w-100">
                                    <div className="w-50">
                                        <Controller
                                            name="field_type_id"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    ref={field.ref}
                                                    size="small"
                                                    sx={{
                                                        width: "100%", paddingRight: 2,
                                                    }}
                                                    defaultValue={defaultFieldType}
                                                    options={comboFieldTypes}
                                                    getOptionLabel={(option) => option.field_type_name}
                                                    renderOption={(props, option: FieldType) => (
                                                        <div key={option.field_type_id}>
                                                            <Box
                                                                component="li"
                                                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                                                {...props}
                                                            >
                                                                {option.field_type_name}
                                                            </Box>
                                                        </div>
                                                    )}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.field_type_id === value.field_type_id
                                                    }
                                                    onChange={(_, values) => {
                                                        setIsBoolean((values?.field_type_name === 'Boolean'))
                                                        field.onChange(values?.field_type_id || null);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={`${t("parameters.fields.field_type")}`}
                                                            variant="filled"
                                                            value={field.value}
                                                            error={!!errors.field_type_id}
                                                            helperText={GetError(errors.field_type_id?.type)}
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>

                            {!isEdit &&
                                <div className='d-flex flex-row-reverse mt-4'>
                                    <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                                </div>
                            }

                        </form>

                    </div>

                </div>
            }
            <SnackbarComponent />
        </>
    )
}
