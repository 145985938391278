import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../api/GenericPromises";
import useSnackBar from "../../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../../hooks/Forms/FormsErrors";
import { useEffect, useState } from "react";
import { Resource } from "../../../../interfaces/Security/resources";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Autocomplete, Box, TextField } from "@mui/material";
import { PrimaryButton } from "../../../../theme/buttons";
import { ResourceType } from "../../../../interfaces/Security/resourcesTypes";


export const UpdateResources = ({ ...props }) => {
    const [t] = useTranslation("global");
    const { GenericPutResource, GenericGetResource } = GenericPromises();
    const { showSnackBar, SnackbarComponent } = useSnackBar();
    const { GetError } = FormsErrors();
    const [loadingPost, setLoadingPost] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [comboResourceType, setComboResourceType] = useState<ResourceType[]>([]);
    const [defautlResourceType, setDefautlResourceType] = useState<ResourceType>();
    const { resourcePayload } = props;
    const [isDisabledName, setIsDisabledName] = useState(true)
    const isEdit = !props.isEdit;

    const {
        handleSubmit,
        control,
        getValues,
        setValue,
        reset,
        formState: { errors },
    } = useForm<Resource>({
        defaultValues: {
            resource_name: '',
            table_name: '',
            view_name: '',
            resource_route: '',
            resource_type_id: undefined
        }
    });
    const onSubmit: SubmitHandler<Resource> = (data) => onPut(data);

    const onPut = (data: Resource) => {
        let myData = {
            resource_name: data.resource_name,
            resource_route: data.resource_route,
            table_name: data.table_name,
            view_name: data.view_name,
            resource_type_id: data.resource_type_id
        }
        setLoadingPost(true);
        GenericPutResource(`/resources/${resourcePayload.resource_id}`, myData)
            .then((response) => {
                showSnackBar(t("generic.snackbar.update"), "success");
                setLoadingPost(false);
            })
            .catch((error) => {
                setLoadingPost(false);
                showSnackBar(error.message, 'error');
            });
    }

    const addPrefix = (str: string | undefined, prefix: string) => {
        if (str) {
            return str.startsWith(prefix) ? str : `${prefix}${str}`;
        }
        else {
            return "";
        }
    };

    const changeNameResource = () => {
        var tableName = getValues("table_name")?.toString();
        var resource_id = getValues("resource_type_id");
        var resourceName = comboResourceType.find((element) => element.resource_type_id === resource_id);
        var strName = (resourceName?.prefix ?? "") + (tableName ?? "");
        if (resourceName?.prefix === 'Act' && !isEdit) {
            setIsDisabledName(false)
        }
        else{
            setIsDisabledName(true)
        }
        setValue("resource_name", strName ?? "");
    }

    useEffect(() => {
        GenericGetResource("/resourcestypes")
            .then((response) => {
                reset({
                    resource_name: resourcePayload.resource_name,
                    resource_route: resourcePayload.resource_route,
                    table_name: resourcePayload.table_name,
                    view_name: resourcePayload.view_name,
                    resource_type_id: resourcePayload.resource_type_id ?? undefined,
                });
                if (resourcePayload?.prefix === 'Act' && !isEdit) {
                    setIsDisabledName(false)
                }
                else{
                    setIsDisabledName(true)
                }
                setDefautlResourceType(response.data.data.find((element: ResourceType) => element.resource_type_id === resourcePayload.resource_type_id))
                setDataLoaded(true);
                setComboResourceType(response.data.data);
            }).catch((error) => {
                showSnackBar(error.message, "error");
            })

    }, []);

    return (
        <>
            {dataLoaded &&
                <div className='d-flex justify-content-center'>
                    <div className='custom-card-view w-75'>

                        <form onSubmit={handleSubmit(onSubmit)} className='mt-4'>

                            <div className="custom-card-row d-flex justify-content-between">

                                <div className="w-100">
                                    <div className='d-flex w-100'>
                                        <div className='w-50'>
                                            <Controller
                                                name="table_name"
                                                control={control}
                                                rules={{ required: true, maxLength: 100 }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant="filled"
                                                        label={`${t("resources.fields.table_name")}`}
                                                        ref={field.ref}
                                                        value={field.value}
                                                        disabled={isEdit}
                                                        onChange={(event) => {
                                                            field.onChange(event.target.value)
                                                            changeNameResource();
                                                        }}
                                                        error={!!errors.table_name}
                                                        helperText={GetError(errors.table_name?.type)}
                                                        size="small"
                                                        style={{ width: "100%" }}
                                                        sx={{ paddingRight: 2 }}
                                                    />
                                                }
                                            />
                                        </div>
                                        <div className='w-50'>
                                            <Controller
                                                name="resource_route"
                                                control={control}
                                                rules={{ maxLength: 100, required: true }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant="filled"
                                                        label={`${t("resources.fields.resource_route")}`}
                                                        ref={field.ref}
                                                        value={field.value}
                                                        disabled={isEdit}
                                                        onChange={(event) => { field.onChange(addPrefix(event.target.value.toLowerCase(), "/")) }}
                                                        error={!!errors.resource_route}
                                                        helperText={GetError(errors.resource_route?.type)}
                                                        size="small"
                                                        style={{ width: "100%" }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex w-100 mt-3'>
                                        <div className='w-50'>
                                            <Controller
                                                name="resource_type_id"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <Autocomplete
                                                        ref={field.ref}
                                                        size="small"
                                                        sx={{ width: "100%", paddingRight: 2 }}
                                                        options={comboResourceType}
                                                        disabled={isEdit}
                                                        defaultValue={defautlResourceType}
                                                        getOptionLabel={(option) => `${option.prefix} - ${option.resource_type_name}`}
                                                        renderOption={(props, option: ResourceType) => (
                                                            <div key={option.resource_type_id}>
                                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                    {`${option.prefix} - ${option.resource_type_name}`}
                                                                </Box>
                                                            </div>
                                                        )}
                                                        isOptionEqualToValue={(option, value) => option.resource_type_id === value.resource_type_id}
                                                        onChange={(_, values) => {
                                                            field.onChange(values?.resource_type_id || null);
                                                            changeNameResource();
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={`${t("resources.fields.resource_type_name")}`}
                                                                variant="filled"
                                                                value={field.value}
                                                                error={!!errors.resource_type_id || field.value === null}
                                                                helperText={(errors.resource_type_id?.type || field.value === null) ? t("generic.forms-errors.required") : null}
                                                            />
                                                        )}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className='w-50'>
                                            <Controller
                                                name="view_name"
                                                control={control}
                                                rules={{ maxLength: 100, required: true }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant="filled"
                                                        label={`${t("resources.fields.view_name")}`}
                                                        ref={field.ref}
                                                        value={field.value}
                                                        onChange={(event) => { field.onChange(addPrefix(event.target.value, "v")) }}
                                                        error={!!errors.view_name}
                                                        helperText={GetError(errors.view_name?.type)}
                                                        size="small"
                                                        disabled={isEdit}
                                                        style={{ width: "100%" }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex w-100 mt-3'>
                                        <div className='w-50'>
                                            <Controller
                                                name="resource_name"
                                                control={control}
                                                rules={{ required: true, maxLength: 100 }}
                                                render={({ field }) =>
                                                    <TextField
                                                        variant="filled"
                                                        label={`${t("resources.fields.resource_name")}`}
                                                        ref={field.ref}
                                                        value={field.value}
                                                        onChange={(event) => { field.onChange(event.target.value) }}
                                                        error={!!errors.resource_name}
                                                        helperText={GetError(errors.resource_name?.type)}
                                                        size="small"
                                                        disabled={isDisabledName}
                                                        style={{ width: "100%" }}
                                                        sx={{ paddingRight: 2 }}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {!isEdit &&
                                <div className='d-flex flex-row-reverse mt-4'>
                                    <PrimaryButton type='submit' variant='outlined' className='m-1' disabled={loadingPost}>{t("generic.buttons.update")}</PrimaryButton>
                                </div>
                            }

                        </form>

                    </div>
                </div>
            }
            <SnackbarComponent />
        </>
    )
}
