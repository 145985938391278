import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridColumnMenu, GridColumnMenuColumnsItem, GridColumnMenuProps, GridEventListener, GridPagination, GridRenderCellParams, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowsProp, GridToolbarContainer, GridValidRowModel, gridPageCountSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Box, Button, IconButton, TablePaginationProps, TextField } from '@mui/material';
import MuiPagination from '@mui/material/Pagination';
import AddIcon from '@mui/icons-material/Add';
import { MerchandiseDetail } from '../../../interfaces/Sales/Catalogs/TransportationDocuments/transportationDocument';
import GenericPromises from '../../../api/GenericPromises';
import { CustomerItem } from '../../../interfaces/Sales/Catalogs/Customers/customerItems';
import { Item } from '../../../interfaces/Commons/items';
import { PrimaryButton } from '../../../theme/buttons';
import { SnackbarComponent } from '../../Commons/SnackBar';
import { useLocation } from 'react-router-dom';
import { Unit } from '../../../interfaces/Commons/units';
import { HazardousMaterialKey } from '../../../interfaces/Sales/Catalogs/TransportationDocuments/hazardousMaterialsKeys';
import { PackagingType } from '../../../interfaces/Sales/Catalogs/TransportationDocuments/packagingTypes';
import { CustomColumnOrder } from '../GridTableMaterialUI/CustomColumnOrder';
import { CustomColumnOrderSalesInvoice } from '../GridTableMaterialUI/CustomColumnOrderSalesInvoice';
import useSnackBar from '../../Commons/SnackBar/useSnackBar';
import { useParamsFilter } from '../../../hooks/useParamsFilter';
import { TaxObject } from '../../../interfaces/Sales/Catalogs/taxObjects';
import { Retention } from '../../../interfaces/Sales/Catalogs/retentions';
import { Tax } from '../../../interfaces/Sales/Catalogs/taxes';
import { Menuitem } from '../../../interfaces/Security/menu';

type EditableTableProps = {
  rows: readonly GridValidRowModel[],
  transportationDocument: number,
  columns: GridColDef[],
  nameOrderColumns: string;
  setColumns: React.Dispatch<React.SetStateAction<GridColDef<any>[]>>;
  rowModesModel: GridRowModesModel,
  handleRowModesModelChange: (newRowModesModel: GridRowModesModel) => void,
  handleRowEditStop: GridEventListener<"rowEditStop">,
  processRowUpdate?: (newRow: GridRowModel, oldRow: GridRowModel) => {},
  setRows: React.Dispatch<React.SetStateAction<MerchandiseDetail[]>>,
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>,
  isEdit?: boolean,
  ChangeTotalMerchandise: () => void,
  resourceScreenMerchandise: Menuitem | undefined
}

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
  changeTotalValue: (addValue: number, minusValue: number, prevValue: number) => void,
  transportationDocument: number,
  addPermission: boolean,
  ChangeTotalMerchandise: () => void,
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel, transportationDocument } = props;
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [t] = useTranslation("global");
  const location = useLocation();
  const { GenericGetResource } = GenericPromises();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadedNewItems, setIsloadedNewItems] = useState(false);
  const [comboItems, setComboItems] = React.useState<CustomerItem[]>([]);
  const [comboUnits, setComboUnits] = React.useState<Unit[]>([]);
  const [myItem, setMyitem] = React.useState<CustomerItem | null>(null);
  const [myQuantity, setMyQuantity] = React.useState<number>();
  const [myWeight, setMyWeight] = React.useState<number>();
  const [myUnit, setMyUnit] = React.useState<Unit | null>();
  const myQuantityRef = React.useRef<any>(null);
  const [myHazardousMaterialKey, setMyHazardousMaterialKey] = React.useState<HazardousMaterialKey>();
  const [comboHazardousMaterialKeys, setComboHazardousMaterialKeys] = React.useState<HazardousMaterialKey[]>([]);
  const [myPackagingType, setMyPackagingType] = React.useState<PackagingType>();
  const [comboPackagingTypes, setComboPackagingTypes] = React.useState<PackagingType[]>([]);
  const [isDangerousMaterial, setIsDangerousMaterial] = React.useState(false);
  const [isCouldDangerousMaterial, setIsCouldDangerousMaterial] = React.useState(false);
  const { GenericPostResource } = GenericPromises();
  const { GetParamsFromBase64 } = useParamsFilter();

  const getMyCustomerIdFromParams = (): string | undefined => {
    let salesInvoiceId;
    let myCustomerId;
    if (location.search.length > 0 && location.state === null) {
      let myParams = GetParamsFromBase64();
      salesInvoiceId = myParams.get("sales_invoice_id");
      myCustomerId = myParams.get("customer_id") ?? '';
    }
    return myCustomerId ?? undefined;
  }

  const onAddItemsInput = async () => {
    setIsloadedNewItems(true);
    setIsLoading(true);
    let responseComboRetentions = await GenericGetResource(`/retentions`);
    let responseComboTaxes = await GenericGetResource(`/taxes`);
    let comboRetentions: Retention[] = responseComboRetentions.data.data;
    let comboTaxes: Tax[] = responseComboTaxes.data.data;

    await GenericGetResource(`/items`)
      .then(async (responseItems) => {
        // load items con info de customer items para que lo tome el arreglo
        let CustomerId;
        if (location.search.length > 0 && location.state === null) {
          const params = new URLSearchParams(location.search);
          CustomerId = params.get('customer_id');
        }
        let myNewItems = responseItems.data.data;
        let myNewOptions: CustomerItem[] = [];

        // get units
        let myUnitsResponse = await GenericGetResource(`/units`);
        let myUnits: Unit[] = myUnitsResponse.data.data;
        // get taxobjects
        let myTaxObjectsResponse = await GenericGetResource(`/taxobjects`);
        let myTaxObjects: TaxObject[] = myTaxObjectsResponse.data.data;

        // bucle
        for (let i = 0; i < myNewItems.length; i++) {
          const element: Item = myNewItems[i];
          if (comboItems.find((item) => item.item_id === element.item_id) === undefined) {
            if (element.product_service_key_includes_transportation_document === true) {
              let myUnit = myUnits.find((item) => item.unit_id === element.unit_id);
              let myTaxObject = myTaxObjects.find((item) => item.tax_object_id === element.tax_object_id);
              let myTaxLocal = comboTaxes.find((item) => item.tax_id === element.tax_id);
              let myRetentionLocal = comboRetentions.find((item) => item.retention_id === element.retention_id);
              let myNewCustomerItem: CustomerItem = {
                customer_items_id: -1 * (i + 1),
                customer_id: ((location.state && location.state.row.customer_id) ?? CustomerId) ?? 0,
                item_id: element.item_id,
                item_price: element.default_item_price ?? 0,
                customer_reference: '',
                customer_reference_description: '',
                item_code: element.item_code ?? '',
                item_description: element.item_description ?? '',
                product_service_key_id: element.product_service_key_id,
                product_service_key: element.product_service_key,
                product_service_key_description: element.product_service_key_description,
                product_service_key_is_dangerous_material: element.product_service_key_is_dangerous_material,
                product_service_key_could_be_hazardous_material: element.product_service_key_could_be_hazardous_material,
                tax_object_id: myTaxObject?.tax_object_id,
                tax_object: myTaxObject?.tax_object,
                unit_id: myUnit?.unit_id,
                unit_code: myUnit?.unit_code,
                unit_description: myUnit?.unit_description,
                unit_symbol: myUnit?.unit_symbol,
                international_unit_symbol: myUnit?.international_unit_symbol,
                tax_id: myTaxLocal?.tax_id,
                tax_name: myTaxLocal?.tax_name,
                tax_code: myTaxLocal?.tax_code,
                tax_percent: myTaxLocal?.tax_percent,
                retention_id: myRetentionLocal?.retention_id,
                retention_name: myRetentionLocal?.retention_name,
                retention_code: myRetentionLocal?.retention_code,
                retention_percent: myRetentionLocal?.retention_percent,
                tariff_fraction_id: element.tariff_fraction_id,
              }
              myNewOptions.push(myNewCustomerItem);
            }
          }
        }
        // asignación
        setComboItems((prevState) => [...prevState, ...myNewOptions]);
        setIsLoading(false);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
        setIsloadedNewItems(false);
        setIsLoading(false);
      });
  }

  React.useEffect(() => {
    let myPromises = [
      GenericGetResource(`/customeritems/bycustomerid/${location.state !== null ? location.state.row.customer_id : getMyCustomerIdFromParams()}`),
      GenericGetResource(`/units`),
      GenericGetResource(`/hazardousmaterialskeys`),
      GenericGetResource('/packagingtypes'),
    ];
    Promise.all(myPromises)
      .then((responses) => {
        setComboItems(responses[0].data.data.filter((item: Item) => item.product_service_key !== '78101800'));
        setComboUnits(responses[1].data.data);
        setComboHazardousMaterialKeys(responses[2].data.data);
        setComboPackagingTypes(responses[3].data.data);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: any, value: any) => {
    if (value === null) {
      setMyQuantity((prev) => 0);
      setMyWeight((prev) => 0);
    }
    else {
      myItem && myQuantityRef.current && myQuantityRef.current.focus()
      setMyUnit(comboUnits.find((item: Unit) => item.unit_code?.toLowerCase() === ("kgm")) ?? null);
    }
    setMyitem((prev) => value);
    (value.product_service_key_is_dangerous_material == true) ? setIsDangerousMaterial(true) : setIsDangerousMaterial(false);
    (value.product_service_key_could_be_hazardous_material == true) ? setIsCouldDangerousMaterial(true) : setIsCouldDangerousMaterial(false);
  };

  const handleChangeTextfields = (e: any) => {
    let { name, value } = e.target;
    if (name === 'weight') {
      setMyWeight((prev) => value)
    }
    else if (name === 'quantity') {
      setMyQuantity((prev) => value);
    }
  }

  const handleChangeUnit = (e: any, value: any) => {
    setMyUnit((prev) => value);
  }

  const handleChangeHazardousMaterialKey = (e: any, value: any) => {
    setMyHazardousMaterialKey((prev) => value);
  }

  const handleChangePackagingType = (e: any, value: any) => {
    setMyPackagingType((prev) => value);
  }

  const AddRecord = () => {
    if ((myQuantity === undefined || myQuantity <= 0) ||
      (myWeight === undefined || myWeight <= 0)
      || myUnit === undefined || myUnit === null) {
      showSnackBar(t("merchandisedetails.snackbar.warning"), 'warning');
    }
    else if (isDangerousMaterial &&
      (myHazardousMaterialKey?.hazardous_material_key_id === undefined || myPackagingType?.packaging_type_id === undefined
        || (myHazardousMaterialKey?.hazardous_material_key_id === null || myPackagingType?.packaging_type_id === null)
      )) {
      showSnackBar(t("merchandisedetails.snackbar.warning"), 'warning');
    }
    else {
      let myMerchandiseDetail = {
        transportation_document_id: transportationDocument,
        description: myItem?.item_description,
        product_service_key_id: myItem?.product_service_key_id,
        product_service_key: myItem?.product_service_key,
        unit_id: myItem?.unit_id,
        unit_code: myItem?.unit_code,
        quantity: myQuantity,
        weight_in_kg: myWeight,
        is_dangerous_material: myItem?.product_service_key_is_dangerous_material,
        hazardous_material_key_id: myHazardousMaterialKey?.hazardous_material_key_id ?? null,
        hazardous_material_key: myHazardousMaterialKey?.hazardous_material_key ?? null,
        hazardous_material_description: myHazardousMaterialKey?.hazardous_material_description ?? null,
        could_be_hazardous_material: myItem?.product_service_key_could_be_hazardous_material,
        packaging_type_id: myPackagingType?.packaging_type_id ?? null,
        packaging_type_code: myPackagingType?.packaging_type_code ?? null,
        packaging_type_description: myPackagingType?.packaging_type_description ?? null,
      }
      setIsLoading(true);
      GenericPostResource("/merchandisedetails", myMerchandiseDetail)
        .then((response) => {
          setRows((oldRows) => {
            setRowModesModel((oldModel) => ({
              ...oldModel,
              [response.data.merchandise_detail_id]: { mode: GridRowModes.View },
            }));
            return ([...oldRows, {
              merchandise_detail_id: response.data.merchandise_detail_id,
              description: myItem?.item_description,
              product_service_key: myItem?.product_service_key,
              unit_code: myUnit?.unit_code,
              quantity: myQuantity,
              could_be_hazardous_material: myItem?.product_service_key_could_be_hazardous_material,
              weight_in_kg: myWeight,
              is_dangerous_material: myItem?.product_service_key_is_dangerous_material,
              hazardous_material_key: myHazardousMaterialKey?.hazardous_material_key,
              packaging_type_code: myPackagingType?.packaging_type_code
            }]);
            // let myRow = oldRows.find((item) => item.product_service_key === myMerchandiseDetail.product_service_key);
            // if (myRow) {
            //   setRowModesModel((oldModel) => ({
            //     ...oldModel,
            //     [response.data.merchandise_detail_id]: { mode: GridRowModes.View },
            //   }));
            //   return oldRows.map((row) => (row.merchandise_detail_id === response.data.merchandise_detail_id ? response.data : row));;
            // }
            // else {

            // }
          });
          props.ChangeTotalMerchandise();
          setMyitem((prev) => null);
          setMyQuantity((prev) => undefined);
          setMyWeight((prev) => undefined);
          setMyUnit((prev) => undefined);
          setMyHazardousMaterialKey((prev) => undefined);
          setMyPackagingType((prev) => undefined);
          setIsLoading(false);
        }).catch((error) => {
          setIsLoading(false);
          showSnackBar(error.message, 'error');
        });
    }
  }

  return (
    <GridToolbarContainer className='d-flex p-1 w-100'>
      {props.addPermission &&
        <>
          <Autocomplete
            value={myItem || null}
            size="small"
            sx={{ flex: 8 }}
            options={comboItems}
            getOptionLabel={(option) => `${option.product_service_key} - ${option.product_service_key_description} - ${option.item_description}`}
            renderOption={(props, option: CustomerItem) => (
              <div key={option.customer_items_id}>
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.product_service_key} - {option.product_service_key_description} - {option.item_description}
                </Box>
              </div>
            )}
            isOptionEqualToValue={(option, value) => option.customer_items_id === value.customer_items_id}
            onChange={handleChange}
            noOptionsText={
              <>
                {!isLoadedNewItems &&
                  <Button onClick={onAddItemsInput} disabled={isLoadedNewItems}>
                    {t("invoices.actions.add-items")}
                  </Button>}
              </>
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={`${t("merchandisedetails.title-view")}`}
                variant="filled"
              />
            )}
            disabled={isLoading}
          />
          {myItem &&
            <>
              <TextField
                inputRef={myQuantityRef}
                variant="filled"
                name="quantity"
                label={`${t("merchandisedetails.fields.quantity")}`}
                value={myQuantity || undefined}
                onChange={handleChangeTextfields}
                size="small"
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                  "& input[type=number]": { MozAppearance: "textfield", }, flex: 3
                }}
                type="number"
                disabled={isLoading}
              />
              <Autocomplete
                value={myUnit || null}
                size="small"
                sx={{ flex: 3 }}
                options={comboUnits}
                getOptionLabel={(option) => `${option.unit_description} - ${option.unit_code}`}
                renderOption={(props, option: Unit) => (
                  <div key={option.unit_id}>
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.unit_description} - {option.unit_code}
                    </Box>
                  </div>
                )}
                isOptionEqualToValue={(option, value) => option.unit_id === value.unit_id}
                onChange={handleChangeUnit}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${t("merchandisedetails.fields.unit_code")}`}
                    variant="filled"
                  />
                )}
                disabled={isLoading}
              />
              <TextField
                variant="filled"
                name="weight"
                label={`${t("merchandisedetails.fields.weight_in_kg")}`}
                value={myWeight || undefined}
                onChange={handleChangeTextfields}
                size="small"
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none", },
                  "& input[type=number]": { MozAppearance: "textfield", }, flex: 3
                }}
                type="number"
                disabled={isLoading}
              />
              {(isDangerousMaterial || isCouldDangerousMaterial) &&
                <>
                  <Autocomplete
                    value={myHazardousMaterialKey || null}
                    size="small"
                    sx={{ flex: 3 }}
                    options={comboHazardousMaterialKeys}
                    getOptionLabel={(option) => `${option.hazardous_material_key} - ${option.hazardous_material_description}`}
                    renderOption={(props, option: HazardousMaterialKey) => (
                      <div key={option.hazardous_material_key_id}>
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.hazardous_material_key} - {option.hazardous_material_description}
                        </Box>
                      </div>
                    )}
                    isOptionEqualToValue={(option, value) => option.hazardous_material_key_id === value.hazardous_material_key_id}
                    onChange={handleChangeHazardousMaterialKey}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${t("merchandisedetails.fields.hazardous_material_key")}`}
                        variant="filled"
                      />
                    )}
                    disabled={isLoading}
                  />
                  <Autocomplete
                    value={myPackagingType || null}
                    size="small"
                    sx={{ flex: 3 }}
                    options={comboPackagingTypes}
                    getOptionLabel={(option) => `${option.packaging_type_code} - ${option.packaging_type_description}`}
                    renderOption={(props, option: PackagingType) => (
                      <div key={option.packaging_type_id}>
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.packaging_type_code} - {option.packaging_type_description}
                        </Box>
                      </div>
                    )}
                    isOptionEqualToValue={(option, value) => option.packaging_type_id === value.packaging_type_id}
                    onChange={handleChangePackagingType}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${t("merchandisedetails.fields.packaging_type_code")}`}
                        variant="filled"
                      />
                    )}
                    disabled={isLoading}
                  />
                </>
              }
              <PrimaryButton disabled={isLoading} variant='outlined' sx={{ flex: 1 }} onClick={AddRecord}><AddIcon /></PrimaryButton>
            </>
          }
        </>
      }
      <SnackbarComponent />
    </GridToolbarContainer>
  );
}

function Pagination({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      variant='outlined'
      sx={{
        '& .MuiPaginationItem-root:hover': {
          color: 'secondary',
          backgroundColor: 'primary',
          border: 'none',
        }
      }}
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
    />
  );
}

function CustomPagination(props: any) {
  const [t] = useTranslation("global");
  return <div className='d-flex'>
    <GridPagination labelRowsPerPage={t("generic.grid-table.rows-per-page")} ActionsComponent={Pagination} {...props} />
  </div>
}

export default function TableMerchandiseDetail({
  rows,
  columns,
  setColumns,
  nameOrderColumns,
  rowModesModel,
  handleRowModesModelChange,
  handleRowEditStop,
  processRowUpdate,
  transportationDocument,
  setRows,
  setRowModesModel,
  isEdit,
  ChangeTotalMerchandise,
  resourceScreenMerchandise
}: EditableTableProps) {
  const [addPermission, setAddPermission] = useState(false);
  const [newColumns, setNewColumns] = useState<GridColDef[]>([])
  const [t] = useTranslation("global");

  function CustomColumnsItems({ ...props }) {
    return <div>
      <CustomColumnOrderSalesInvoice columns={columns} setColumns={setColumns} nameOrderColumns={nameOrderColumns} />
      <GridColumnMenuColumnsItem colDef={props.colDef} onClick={props.onClick} />
    </div>
  }

  function CustomColumnMenuComponent(props: GridColumnMenuProps) {
    return (
      <GridColumnMenu
        {...props}
        slots={{
          columnMenuColumnsItem: CustomColumnsItems,
        }}
      />
    );
  }

  useEffect(() => {
    let updateColumns: GridColDef[] = columns
    if (!rows.some((row: MerchandiseDetail) => row.is_dangerous_material === true || row.could_be_hazardous_material === true)) {
      updateColumns = updateColumns.filter(
        (column) => column.field !== "hazardous_material_key_id" && column.field !== "packaging_type_id"
      );
    }
    if (resourceScreenMerchandise?.update === false) {
      const quiantityColumnIndex = updateColumns.findIndex(column => column.field === "quantity");
      const weightColumnIndex = updateColumns.findIndex(column => column.field === "weight_in_kg");
      updateColumns[quiantityColumnIndex].editable = false
      updateColumns[weightColumnIndex].editable = false
    }
    if (isEdit) {
      updateColumns = updateColumns.filter(
        (column) => column.field !== "actions"
      );
    }
    if (!isEdit && resourceScreenMerchandise?.create) {
      setAddPermission(true);
    }
    else{
      setAddPermission(false);
    }
    setNewColumns(updateColumns)
  }, [rows, isEdit, columns])

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        sx={{
          '& .MuiDataGrid-row:hover': {
            color: 'primary.main',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            color: 'ActiveCaption',
            outline: 'none',
            border: 'none'
          },
          '& .MuiDataGrid-columnHeader--showColumnBorder': {
            outline: 'none',
            bordere: 'none'
          },
          ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
            marginTop: ".5em",
            marginBottom: ".5em",
          }
        }}
        localeText={{
          toolbarExport: t("generic.grid-table.toolbar-export"),
          toolbarExportCSV: t("generic.grid-table.toolbar-export-csv"),
          columnMenuUnsort: t("generic.grid-table.column-menu-unsort"),
          columnMenuSortAsc: t("generic.grid-table.column-menu-sort-asc"),
          columnMenuSortDesc: t("generic.grid-table.column-menu-sort-desc"),
          columnMenuFilter: t("generic.grid-table.column-menu-filter"),
          columnMenuHideColumn: t("generic.grid-table.column-menu-hide-column"),
          columnMenuShowColumns: t("generic.grid-table.column-menu-show-columns"),
          columnMenuManageColumns: t("generic.grid-table.column-menu-manage-columns"),
          filterOperatorContains: t("generic.grid-table.filter-operator-contains"),
          filterOperatorEquals: t("generic.grid-table.filter-operator-equals"),
          filterOperatorStartsWith: t("generic.grid-table.filter-operator-starts-with"),
          filterOperatorEndsWith: t("generic.grid-table.filter-operator-ends-with"),
          filterOperatorIsEmpty: t("generic.grid-table.filter-operator-is-empty"),
          filterOperatorIsNotEmpty: t("generic.grid-table.filter-operator-is-not-empty"),
          filterOperatorIsAnyOf: t("generic.grid-table.filter-operator-is-any-of"),
        }}
        getRowId={(row) => row["merchandise_detail_id"]}
        rows={rows}
        columns={newColumns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
        slots={{
          columnMenu: CustomColumnMenuComponent,
          toolbar: EditToolbar,
          pagination: CustomPagination,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, addPermission, transportationDocument, ChangeTotalMerchandise },
        }}
        pageSizeOptions={[5, 10]}
        autoHeight
      />
    </Box>
  )
}
